import React from "react";
import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbList,
    BreadcrumbPage,
    BreadcrumbSeparator,
} from "../ui/breadcrumb.tsx";
import { Button } from "../ui/button.tsx";
import { Link } from "react-router-dom";
import { ArrowUpRightIcon, BoxIcon } from "lucide-react";
import { useParams } from "react-router-dom";

const Navbar = ({ formId, formTitle }) => {
    const { workspaceId } = useParams();
    return (
        <nav className="flex flex-row items-center justify-between py-3 ">
            <div className="flex flex-row items-center gap-5">
                <Breadcrumb>
                    <BreadcrumbList>
                        <BreadcrumbItem>
                            <BreadcrumbLink
                                href="/"
                                className="flex flex-row items-center text-xs font-medium text-gray-800 transition duration-150 hover:text-blue-600"
                            >
                                <BoxIcon className="w-3 h-3 mr-1" />
                                Workspace
                            </BreadcrumbLink>
                        </BreadcrumbItem>
                        <BreadcrumbSeparator />
                        <BreadcrumbItem>
                            <BreadcrumbLink
                                href={`/workspaces/${workspaceId}/form-editor/${formId}`}
                                className="flex flex-row items-center text-xs font-medium text-gray-800 transition duration-150 hover:text-blue-600"
                            >
                                <BreadcrumbPage>{formTitle}</BreadcrumbPage>
                            </BreadcrumbLink>
                        </BreadcrumbItem>
                        <BreadcrumbSeparator />
                        <BreadcrumbItem>
                            <BreadcrumbPage className="text-xs text-gray-800">
                                Analytics
                            </BreadcrumbPage>
                        </BreadcrumbItem>
                    </BreadcrumbList>
                </Breadcrumb>
            </div>

            <div className="flex flex-row items-center gap-2">
                <Link to={`/workspaces/${workspaceId}/form-editor/${formId}`}>
                    <Button variant="outline" className="text-sm h-[36px]">
                        Editor <ArrowUpRightIcon className="w-4 h-4 ml-2" />
                    </Button>
                </Link>
            </div>
        </nav>
    );
};

export default Navbar;
