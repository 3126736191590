import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Separator } from "../Components/ui/separator.tsx";
import Navbar from "../Components/Analytics/Navbar.tsx";
import Settings from "../Components/Analytics/Settings.tsx";
import { v4 as uuidv4 } from "uuid";
import { useQuery } from "@tanstack/react-query";
import { useApi } from "../Api/useApi.ts";
import { useFormInsightsContext } from "../Contexts/FormInsightsContext.tsx";

const FixedFields = [
    {
        id: uuidv4(),
        title: "Date",
        type: "date",
        checked: true,
    },
    {
        id: uuidv4(),
        title: "State",
        type: "status",
        checked: true,
        selectedChoices: [],
        multiChecked: false,
        choices: [
            { id: uuidv4(), label: "started" },
            { id: uuidv4(), label: "completed" },
            { id: uuidv4(), label: "partial" },
        ],
    },
];

const FormAnalyticsLayout = ({ children }) => {
    const api = useApi();
    const { urlId } = useParams();
    const { workspaceId } = useParams();
    const { setForm, setFields } = useFormInsightsContext();
    const { data: formResponse } = useQuery({
        queryKey: ["form", urlId],
        queryFn: () =>
            api.revisions.getOneRevision(
                urlId as string,
                workspaceId as string,
            ),
        staleTime: Infinity,
        retry: 1,
    });

    /* useEffect(() => {
        // Extract the form from the form response & assign it to the context
        const form = formResponse?.data?.form || {};
        setForm(form);

        // Extract the fields from the form response & assign them to the context
        const formFields: any[] = formResponse?.data?.form?.fields || [];

        if (!Array.isArray(formFields)) {
            return; // or handle the case where formFields is not an array
        }
        //if nested fields are present, flatten them
        const formattedFields = formFields.flatMap((field) => {
            if (field?.properties?.fields) {
                return field?.properties?.fields.map((nestedField: any) => ({
                    id: nestedField.id,
                    title: nestedField.title,
                    type: nestedField.type,
                    checked: true,
                    selectedChoices: [],
                    multiChecked: false,
                }));
            } else {
                return [
                    {
                        id: field.id,
                        title: field.title,
                        type: field.type,
                        choices: field?.properties?.choices,
                        checked: true,
                        selectedChoices: [],
                        multiChecked: false,
                    },
                ];
            }
        });

        // Add the fixed fields to the beginning of the fields array
        formattedFields.unshift(...FixedFields);
        setFields(formattedFields);
    }, [formResponse?.data]); */

    useEffect(() => {
        const form = formResponse?.data?.form ?? {};
        setForm(form);

        const rawFields = form?.fields;

        if (!Array.isArray(rawFields)) {
            setFields([]); // fallback
            return;
        }

        const formattedFields = rawFields.flatMap((field) => {
            if (field?.properties?.fields) {
                return field?.properties?.fields.map((nestedField: any) => ({
                    id: nestedField.id,
                    title: nestedField.title,
                    type: nestedField.type,
                    checked: true,
                    selectedChoices: [],
                    multiChecked: false,
                }));
            } else {
                return [
                    {
                        id: field.id,
                        title: field.title,
                        type: field.type,
                        choices: field?.properties?.choices,
                        checked: true,
                        selectedChoices: [],
                        multiChecked: false,
                    },
                ];
            }
        });

        formattedFields.unshift(...FixedFields);
        setFields(formattedFields);
    }, [formResponse?.data]);

    return (
        <div className="flex overflow-hidden relative flex-col px-4 h-screen text-left no-scrollbar">
            <Navbar
                formId={formResponse?.data?.form?._id}
                formTitle={formResponse?.data?.form?.title}
            />
            <Separator className="absolute left-0 top-14 px-0 w-full" />
            <Settings />

            {children}
        </div>
    );
};

export default FormAnalyticsLayout;
