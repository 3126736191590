import React from "react";
import { useFormEditorContext } from "../../Contexts/FormEditorContext";
import { randomString } from "../../Utils/string";
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "../ui/dialog.tsx";
import { Separator } from "../ui/separator.tsx";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "../ui/tooltip.tsx";
import { Plus, Sparkles } from "lucide-react";
import { Button } from "../ui/button.tsx";
import {
    FIELD_TYPE_OPTIONS,
    QUESTION_OBJECTS,
} from "../../Constants/questionTypes";
import clsx from "clsx";

const buttonStyles =
    "flex items-center justify-start py-[7px] w-full  pl-2 bg-white rounded-md gap-2";

export default function AddFieldModal() {
    const {
        addField,
        setSelectedField,
        setWelcomeScreen,
        isWelcomeIsEnabled,
        setIsWelcomeIsEnabled,
        isEndIsEnabled,
        setIsEndIsEnabled,
        setEndScreen,
        fields,
    } = useFormEditorContext();

    const recommendedFields = Object.values(QUESTION_OBJECTS).filter((f) =>
        f?.group.includes("Recommended"),
    );
    const contactInfoFields = Object.values(QUESTION_OBJECTS).filter((f) =>
        f?.group.includes("Contact Info"),
    );
    const choicesFields = Object.values(QUESTION_OBJECTS).filter((f) =>
        f?.group.includes("Choices"),
    );
    const aiFeatureFields = Object.values(QUESTION_OBJECTS).filter((f) =>
        f?.group.includes("AI Features"),
    );
    const textAndVideoFields = Object.values(QUESTION_OBJECTS).filter((f) =>
        f?.group.includes("Text and Video"),
    );
    const screenFields = Object.values(QUESTION_OBJECTS).filter((f) =>
        f?.group.includes("Welcome and End Screens"),
    );
    const embeddingsUploadsFields = Object.values(QUESTION_OBJECTS).filter(
        (f) => f?.group.includes("Embeddings and Uploads"),
    );

    return (
        <Dialog>
            <DialogTrigger asChild>
                <button className="inline-flex items-center justify-center whitespace-nowrap font-medium ring-offset-white transition focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-zinc-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-[#DCDFE4] bg-white  hover:text-zinc-900 w-[90px]  rounded-md text-xs h-7 px-2.5 gap-[3px]">
                    <Plus size={14} />
                    Add Step
                </button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[870px] p-3 top-[50%]">
                <DialogHeader>
                    <DialogTitle className="flex gap-1 items-center text-sm font-medium">
                        <svg
                            width="15"
                            height="15"
                            viewBox="0 0 15 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M6.05566 1.84766H3.139C2.81683 1.84766 2.55566 2.10882 2.55566 2.43099V6.51432C2.55566 6.83649 2.81683 7.09766 3.139 7.09766H6.05566C6.37783 7.09766 6.639 6.83649 6.639 6.51432V2.43099C6.639 2.10882 6.37783 1.84766 6.05566 1.84766Z"
                                stroke="#09090B"
                                stroke-width="0.875"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M12.4723 1.84766H9.55566C9.2335 1.84766 8.97233 2.10882 8.97233 2.43099V4.18099C8.97233 4.50316 9.2335 4.76432 9.55566 4.76432H12.4723C12.7945 4.76432 13.0557 4.50316 13.0557 4.18099V2.43099C13.0557 2.10882 12.7945 1.84766 12.4723 1.84766Z"
                                stroke="#09090B"
                                stroke-width="0.875"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M12.4723 7.09766H9.55566C9.2335 7.09766 8.97233 7.35882 8.97233 7.68099V11.7643C8.97233 12.0865 9.2335 12.3477 9.55566 12.3477H12.4723C12.7945 12.3477 13.0557 12.0865 13.0557 11.7643V7.68099C13.0557 7.35882 12.7945 7.09766 12.4723 7.09766Z"
                                stroke="#09090B"
                                stroke-width="0.875"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M6.05566 9.43099H3.139C2.81683 9.43099 2.55566 9.69216 2.55566 10.0143V11.7643C2.55566 12.0865 2.81683 12.3477 3.139 12.3477H6.05566C6.37783 12.3477 6.639 12.0865 6.639 11.7643V10.0143C6.639 9.69216 6.37783 9.43099 6.05566 9.43099Z"
                                stroke="#09090B"
                                stroke-width="0.875"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>

                        <span>Add New Elements</span>
                    </DialogTitle>
                    <DialogDescription>
                        Customize your form with fields, features, and
                        integrations.
                    </DialogDescription>
                </DialogHeader>
                <div className="flex flex-col gap-2 w-full">
                    <Separator className="absolute left-0 w-full" />
                    <div className="gap-2 mt-4">
                        <div className="  bg-[#F7F8F9] p-3 rounded-lg">
                            <span className="flex gap-1 items-center text-xs font-medium">
                                Recommended{" "}
                            </span>
                            <div className="grid grid-cols-4 gap-1 mt-2">
                                {recommendedFields.map((f, index) => (
                                    <FieldCard field={f} index={index} />
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-4 gap-2 w-full">
                        <div className="  bg-[#F7F8F9] p-3 rounded-lg">
                            <span className="flex gap-1 items-center mb-2 text-xs font-medium">
                                Contact Info{" "}
                            </span>
                            {contactInfoFields?.map((f, index) => (
                                <FieldCard
                                    field={f}
                                    index={index}
                                    className="mb-1"
                                />
                            ))}
                        </div>
                        <div className="  bg-[#F7F8F9] p-3 rounded-lg">
                            <span className="flex gap-1 items-center mb-2 text-xs font-medium">
                                Choices{" "}
                            </span>
                            {choicesFields?.map((f, index) => (
                                <FieldCard
                                    field={f}
                                    index={index}
                                    className="mb-1"
                                />
                            ))}
                        </div>
                        <div className="  bg-[#F7F8F9] p-3 rounded-lg">
                            <span className="flex gap-1 items-center mb-2 text-xs font-medium">
                                AI Features{" "}
                                <Sparkles
                                    size={14}
                                    className="text-[#191B33]"
                                />
                            </span>
                            {aiFeatureFields?.map((f, index) => (
                                <FieldCard
                                    field={f}
                                    index={index}
                                    className="mb-1"
                                />
                            ))}
                        </div>
                        <div className="  bg-[#F7F8F9] p-3 rounded-lg">
                            <span className="flex gap-1 items-center mb-2 text-xs font-medium">
                                Text and Video{" "}
                            </span>
                            {textAndVideoFields?.map((f, index) => (
                                <FieldCard
                                    field={f}
                                    index={index}
                                    className="mb-1"
                                />
                            ))}
                        </div>
                    </div>
                    <div className="grid grid-cols-2 gap-2 w-full">
                        <div className="  bg-[#F7F8F9] p-3 rounded-lg">
                            <span className="flex gap-1 items-center text-xs font-medium">
                                Welcome and End Screens{" "}
                            </span>
                            <div className="grid grid-cols-2 gap-1 mt-2">
                                {screenFields?.map((f, index) => (
                                    <FieldCard field={f} index={index} />
                                ))}
                            </div>
                        </div>
                        <div className="  bg-[#F7F8F9] p-3 rounded-lg">
                            <span className="flex gap-1 items-center text-xs font-medium">
                                Embeddings and Uploads{" "}
                            </span>
                            <div className="grid grid-cols-2 gap-1 mt-2">
                                {embeddingsUploadsFields?.map((f, index) => (
                                    <FieldCard field={f} index={index} />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}

function BetaBadge() {
    return (
        <div className="bg-[#D4FD04] px-[8px] py-[1px] rounded-full">
            <p className="text-[10px]">beta</p>
        </div>
    );
}
function ProBadge() {
    return (
        <div className="bg-[#DBEAFE] px-[8px] py-[1px] rounded-full">
            <p className="text-[10px] font-bold text-[#2563EB]">PRO</p>
        </div>
    );
}

type FieldCardProps = {
    field: any;
    index: number;
    className?: string;
};

function FieldCard({ field, index, className }: FieldCardProps) {
    const {
        addField,
        setSelectedField,
        setWelcomeScreen,
        isWelcomeIsEnabled,
        setIsWelcomeIsEnabled,
        isEndIsEnabled,
        setIsEndIsEnabled,
        setEndScreen,
        fields,
    } = useFormEditorContext();

    const addWelcomeScreen = () => {
        setWelcomeScreen({
            title: "Welcome to the form",
            description: "This is a description",
            button_text: "Start",
            layout: {
                type: "float",
                attachment: {
                    type: "image",
                    href: "",
                },
                placement: "right",
            },
        });
        setIsWelcomeIsEnabled(true);
        setSelectedField("welcome-screen");
    };

    const addEndScreen = () => {
        setEndScreen({
            title: "Thank you",
            type: "url_redirect",
            description: "This is a description of the form end",
        });
        setIsEndIsEnabled(true);
        setSelectedField("end-screen");
    };

    const hasUsedOnce = () => {
        return fields.some(
            (f) => f.type === field.type && field.enableMultiple === false,
        );
    };

    const isDisabled =
        field?.isDisabled ||
        (field.type === "welcome_screen" && isWelcomeIsEnabled) ||
        (field.type === "end_screen" && isEndIsEnabled) ||
        hasUsedOnce();

    return (
        <TooltipProvider>
            <Tooltip>
                <TooltipTrigger asChild>
                    <div className={clsx("flex flex-col", className)}>
                        <DialogClose
                            className={buttonStyles}
                            key={index}
                            onClick={() => {
                                if (
                                    field.type === "welcome_screen" &&
                                    !isWelcomeIsEnabled
                                ) {
                                    addWelcomeScreen();
                                } else if (
                                    field.type === "end_screen" &&
                                    !isEndIsEnabled
                                ) {
                                    addEndScreen();
                                } else {
                                    const newField = {
                                        id: randomString(10),
                                        ...FIELD_TYPE_OPTIONS[field.type],
                                    };
                                    addField(newField);
                                    setSelectedField(newField);
                                }
                            }}
                            disabled={isDisabled}
                        >
                            <span
                                className={`text-[14px] flex items-center justify-center w-[22px] h-[22px] rounded-[0.4rem]    ${
                                    field?.isDisabled
                                        ? "bg-gray-50  text-gray-50"
                                        : "bg-gray-100"
                                }`}
                            >
                                {field.icon}
                            </span>
                            <span
                                className={clsx(
                                    "text-xs capitalize",
                                    field?.isDisabled && "text-[#bdbdbd]",
                                )}
                            >
                                {field.label}
                            </span>
                            {field.isBeta && <BetaBadge />}
                            {field.isPro && <ProBadge />}
                        </DialogClose>
                    </div>
                </TooltipTrigger>
                {field?.isDisabled && (
                    <TooltipContent className={clsx("text-xs max-w-[200px]")}>
                        Coming soon! You’ll be the first to know (probably)
                    </TooltipContent>
                )}
                {isWelcomeIsEnabled && field.type === "welcome_screen" && (
                    <TooltipContent className={clsx("text-xs max-w-[200px]")}>
                        Welcome screen is already added
                    </TooltipContent>
                )}
                {isEndIsEnabled && field.type === "end_screen" && (
                    <TooltipContent className={clsx("text-xs max-w-[200px]")}>
                        End screen is already added
                    </TooltipContent>
                )}
                {hasUsedOnce() && (
                    <TooltipContent className={clsx("text-xs max-w-[200px]")}>
                        This field is already added
                    </TooltipContent>
                )}
            </Tooltip>
        </TooltipProvider>
    );
}
