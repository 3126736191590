import React, { useState, useEffect } from "react";
import { message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { UserButton } from "@clerk/clerk-react";
import { SignOutButton } from "@clerk/clerk-react";
import { useQuery } from "@tanstack/react-query";
import { useApi } from "../Api/useApi.ts";
import { newFormProps } from "../Constants/newForm.tsx";
import { useNavigate } from "react-router-dom";
import { PopupButton } from "react-calendly";
import {
    QuestionCircleOutlined,
    PhoneOutlined,
    SettingOutlined,
    TagOutlined,
} from "@ant-design/icons";
import FeatureCard from "../Components/DashboardComponents/FeatureCard.jsx";
import FormRepliesCard from "../Components/DashboardComponents/FormRepliesCard.jsx";
import FormCard from "../Components/DashboardComponents/FormCard.jsx";
import Workspaces from "../Components/DashboardComponents/Workspaces.jsx";
import { useFormEditorContext } from "../Contexts/FormEditorContext.jsx";
import { useUser } from "@clerk/clerk-react";
import { useMutation } from "@tanstack/react-query";
import {
    convertBackToOldFormat,
    convertFormObject,
} from "../Components/MyEditor/FormObjectConfig.jsx";
import CustomBilling from "../Components/DashboardComponents/CustomBilling.jsx";
import useKeyboardShortcut from "../Components/DashboardComponents/useKeyboardShortcut.jsx";
import CommonLayout from "./CommonLayout.jsx";
import {
    CommandDialog,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
} from "../Components/ui/command.tsx";
import { Input as ShadInput } from "../Components/ui/input.tsx";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
} from "../Components/ui/dialog.tsx";
import { FilePenLine, Sparkles, LoaderCircle } from "lucide-react";
import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
} from "../Components/ui/tabs.tsx";
import { Separator } from "../Components/ui/separator.tsx";
import { Textarea } from "../Components/ui/textarea.tsx";
import { Button } from "../Components/ui/button.tsx";
import clsx from "clsx";

const DashboardLayout = () => {
    /* Data Fetchings  */
    const { user } = useUser();
    const { selectedWorkSpace, setSelectedWorkSpace } = useFormEditorContext();
    const [workSpaces, setWorkSpaces] = useState([]); //use to map for search
    const [formList, setFormList] = useState([]);
    const api = useApi();

    const { isFetching, data, isSuccess } = useQuery({
        queryKey: ["formList", selectedWorkSpace],
        queryFn: () => {
            if (!selectedWorkSpace) {
                return;
            }
            return api.revisions.getAllRevisions(selectedWorkSpace);
        },
        staleTime: Infinity,
        onError: (error) => {
            console.error("Error details:", error);
        },
        enabled: !!selectedWorkSpace,
    });

    useEffect(() => {
        if (isSuccess) {
            setFormList(data?.data);
        }
    }, [isSuccess, data, setFormList]);

    /* modal states */
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [createModalOpen, setCreateModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };
    useKeyboardShortcut(["cmd+k", "ctrl+k"], (key, e) => {
        e.preventDefault();

        showModal();
    });

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    /* change logs */
    useEffect(() => {
        const win = window;

        // Initialize Featurebase widget
        const initializeFeaturebase = () => {
            if (typeof win.Featurebase !== "function") {
                win.Featurebase = function () {
                    (win.Featurebase.q = win.Featurebase.q || []).push(
                        arguments,
                    );
                };
            }

            win.Featurebase("initialize_changelog_widget", {
                organization: "Buildform", // Replace this with your Featurebase organization name
                placement: "right", // Choose between right, left, top, bottom placement
                theme: "light", // Choose between dark or light theme
                fullscreenPopup: true, // Optional - Open a fullscreen announcement
                locale: "en",
            });
        };

        // Inject the script dynamically
        const scriptId = "featurebase-sdk";
        if (!document.getElementById(scriptId)) {
            const script = document.createElement("script");
            script.id = scriptId;
            script.src = "https://do.featurebase.app/js/sdk.js";
            document.body.appendChild(script);

            script.onload = () => {
                initializeFeaturebase();
            };
        } else {
            initializeFeaturebase();
        }
    }, []);

    // Function to manually trigger the changelog popup
    const openChangelogPopup = () => {
        if (window.Featurebase) {
            window.Featurebase("manually_open_changelog_popup"); // Manually trigger the changelog popup
        } else {
            console.error("Featurebase is not initialized yet.");
        }
    };
    const [isCollapsed, setIsCollapsed] = useState(true);

    const handleSignOut = () => {
        sessionStorage.removeItem("selectedWorkSpace");
    };

    const dashboardSidebar = (
        <div className="w-[320px] h-full bg-white px-5 pt-4 flex flex-col relative scrollbar-none ">
            {/* user nav sec */}
            <UserButton showName="true" afterSignOutUrl="/login">
                <UserButton.SignOutButton>
                    <SignOutButton
                        className="flex items-center justify-center w-full h-10 px-4 py-2 text-xs font-medium transition rounded-lg whitespace-nowrap ring-offset-white focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-zinc-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-zinc-900 text-zinc-50 hover:bg-zinc-700"
                        onClick={handleSignOut}
                    >
                        Sign Oudfvdvst
                    </SignOutButton>
                </UserButton.SignOutButton>
                <UserButton.UserProfilePage
                    label="Billing"
                    url="custom"
                    labelIcon={<TagOutlined />}
                >
                    <CustomBilling />
                </UserButton.UserProfilePage>
            </UserButton>

            <div onClick={showModal} className="w-full mt-5">
                <button className="inline-flex items-center gap-2 whitespace-nowrap transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 [&amp;_svg]:pointer-events-none [&amp;_svg]:size-4 [&amp;_svg]:shrink-0 border border-input  hover:text-accent-foreground px-4 py-2 relative h-8 w-full justify-start rounded-[0.4rem] bg-[#F4F4F5] text-sm font-normal text-muted-foreground shadow-none sm:pr-12 ">
                    <span className="hidden lg:inline-flex">Search ...</span>
                    <span className="inline-flex lg:hidden">Search...</span>
                    <kbd className="pointer-events-none absolute right-[0.3rem] top-[0.3rem] hidden h-5 select-none items-center gap-1 rounded border bg-[#F4F4F5] px-1.5 text-[10px] font-medium opacity-100 sm:flex">
                        <span className="text-xs">⌘</span>K
                    </kbd>
                </button>
            </div>

            {/* add workspace sec */}
            <Workspaces
                setFormList={setFormList}
                workSpaces={workSpaces}
                setWorkSpaces={setWorkSpaces}
                selectedWorkSpace={selectedWorkSpace}
                setSelectedWorkSpace={setSelectedWorkSpace}
                isCollapsed={isCollapsed}
                setIsCollapsed={setIsCollapsed}
            />

            {/* Helps */}

            <div className="flex flex-col items-start justify-center pb-4 mt-auto">
                <p className="mb-2 text-xs font-medium opacity-30">Helps</p>
                <div className="flex flex-col gap-4 text-xs font-semibold">
                    <button className="flex items-center gap-2 text-start">
                        <a
                            href="https://buildform.featurebase.app/help"
                            target="_blank"
                            className="flex items-center gap-2"
                        >
                            <QuestionCircleOutlined />
                            <span>Help Docs</span>
                        </a>
                    </button>

                    <PopupButton
                        url="https://calendly.com/ronath-buildform/30min?month=2024-09"
                        rootElement={document.getElementById("root")}
                        text={
                            <button className="flex items-center gap-2 text-start">
                                <PhoneOutlined /> Talk to us
                            </button>
                        }
                    />
                    <button
                        onClick={openChangelogPopup}
                        className="flex items-center gap-2 text-start"
                    >
                        <SettingOutlined />
                        Change logs <span id="fb-update-badge"></span>
                    </button>
                </div>
            </div>

            {/* unlimited Replies sec */}
            <div className="pt-2 pb-4 border-t border-zinc-200">
                <div className="text-xs transition duration-150 text-zinc-500">
                    <p className="text-zinc-950 text-[0.65rem] font-medium uppercase tracking-widest">
                        UNLIMITED REPLIES
                    </p>
                    <div className="w-full h-1.5 rounded-full bg-zinc-100 my-3 overflow-hidden">
                        <div className="w-full h-full bg-blue-600 rounded-full"></div>
                    </div>
                    <div className="text-left">
                        If you require specific features, such as brand removal,
                        analytics, integrations or anything else, Share it with
                        us{"\t"}
                        <a
                            target="_blank"
                            className="inline-flex items-center font-medium text-blue-600 underline transition duration-150 hover:cursor-pointer hover:opacity-60"
                            href="https://buildform.featurebase.app/"
                        >
                            here
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <>
            <CommonLayout sidebar={dashboardSidebar}>
                {/* main section */}

                <div className="relative w-full h-full px-8 overflow-y-auto transition duration-200 border rounded-[12px] pb-4 no-scrollbar border-zinc-200">
                    <section className="flex flex-col justify-between gap-4 pt-3 lg:items-center lg:flex-row">
                        <div className="flex flex-col gap-4 mt-5">
                            <h1 className="flex flex-col items-center mb-1 text-xl">
                                <p className="text-[2rem] font-bold text-start">
                                    Hey {user?.firstName} 👋
                                </p>
                            </h1>
                            <p className="hidden text-sm text-zinc-500">
                                Find all the forms you have created
                            </p>
                            <p className="hidden text-lg font-medium text-start">
                                My Forms
                            </p>
                        </div>
                        <button
                            type="primary"
                            className="flex items-center justify-center mt-5 px-2 py-2 font-medium text-white bg-[#191B33] rounded-lg hover:bg-zinc-800"
                            //onClick={showCreateModal}
                            onClick={() => setCreateModalOpen(true)}
                        >
                            <PlusOutlined className=" w-3.5 h-3.5 text-base">
                                {" "}
                            </PlusOutlined>
                            <span className="ml-1 text-sm">New Form</span>
                        </button>
                    </section>
                    <div className="grid gap-4 my-6 lg:grid-cols-3">
                        {/* Replies card */}
                        <FormRepliesCard />
                        {/* Feature Card */}
                        <FeatureCard />
                    </div>
                    <p className="mt-6 mb-3 text-sm text-start text-zinc-500">
                        Recent forms
                    </p>
                    <section className="grid  grid-cols-[repeat(auto-fill,255px)] gap-2">
                        <button
                            type="button"
                            aria-haspopup="dialog"
                            aria-expanded="false"
                            aria-controls="radix-:rk:"
                            data-state="closed"
                            //onClick={showCreateModal}
                            onClick={() => setCreateModalOpen(true)}
                            className="h-[12rem] relative border border-zinc-200 border-dashed rounded-2xl hover:scale-[.98] transition duration-500 flex items-center gap-1 flex-col justify-center hover:bg-zinc-100 "
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                                data-slot="icon"
                                className="w-6 h-6"
                            >
                                <path d="M10.75 4.75a.75.75 0 0 0-1.5 0v4.5h-4.5a.75.75 0 0 0 0 1.5h4.5v4.5a.75.75 0 0 0 1.5 0v-4.5h4.5a.75.75 0 0 0 0-1.5h-4.5v-4.5Z"></path>
                            </svg>
                            <p className="text-sm text-gray-700">
                                Create a form
                            </p>
                        </button>
                        <FormCard formList={formList} isFetching={isFetching} />
                    </section>
                </div>
            </CommonLayout>
            <CommandDialog open={isModalOpen} onOpenChange={setIsModalOpen}>
                <CommandInput placeholder="Type a command or search..." />
                <CommandList>
                    <CommandEmpty>No results found.</CommandEmpty>
                    <CommandGroup heading="Suggestions">
                        {workSpaces?.map((item) => (
                            <CommandItem key={item?._id}>
                                <button
                                    onClick={() => {
                                        setSelectedWorkSpace(item?._id);
                                        handleCancel();
                                    }}
                                >
                                    {item?.name}
                                </button>
                            </CommandItem>
                        ))}
                    </CommandGroup>
                </CommandList>
            </CommandDialog>

            {/* Create Form modal */}
            <FormCreateModal
                createModalOpen={createModalOpen}
                setCreateModalOpen={setCreateModalOpen}
            />
        </>
    );
};

export default DashboardLayout;

const FormCreateModal = ({ createModalOpen, setCreateModalOpen }) => {
    const { selectedWorkSpace, setForm } = useFormEditorContext();
    const api = useApi();
    const navigate = useNavigate();
    const [dynamicName, setDynamicName] = useState("");
    const [guidedName, setGuidedName] = useState("");
    const [error, setError] = useState("");
    const [dynamicDescription, setDynamicDescription] = useState("");
    const [isCreatingGeneralForm, setIsCreatingGeneralForm] = useState(false);
    const [isCreatingDynamicForm, setIsCreatingDynamicForm] = useState(false);

    const formCreateMutation = useMutation({
        mutationFn: ({ data, workspaceId }) =>
            api.revisions.createRevision(data, workspaceId),
        onSuccess: (data) => {
            const newObj = convertBackToOldFormat(data?.data?.form);
            const formId = data?.data?.form?._id;
            setForm(newObj?.form);
            sessionStorage.setItem("selectedWorkSpace", selectedWorkSpace);
            sessionStorage.setItem("formStatus", "draft");
            setIsCreatingGeneralForm(false);
            navigate(`/workspaces/${selectedWorkSpace}/form-editor/${formId}`);
            setCreateModalOpen(false);
        },
        onError: (error) => {
            if (error.response) {
                console.log(error?.response);
                message.error(
                    error?.response?.data?.detail || "Something went wrong",
                );
            } else {
            }
            console.error("Error details:", error);
            setIsCreatingGeneralForm(false);
            setCreateModalOpen(false);
        },
    });

    const dynamicFormCreateMutation = useMutation({
        mutationFn: ({ name, description, workspaceId }) => {
            // Ensure createAiForms returns a promise
            return new Promise((resolve, reject) => {
                api.aiForms
                    .createAiForms(name, description, workspaceId)
                    .then((response) => {
                        resolve(response);
                        console.log(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        onSuccess: (data) => {
            const newObj = convertBackToOldFormat(data?.data?.form);
            setForm(newObj?.form);
            sessionStorage.setItem("selectedWorkSpace", selectedWorkSpace);
            sessionStorage.setItem("formStatus", "draft");
            navigate(
                `/workspaces/${selectedWorkSpace}/form-editor/${data?.data?.form?._id}`,
            );
            setIsCreatingDynamicForm(false);
            setCreateModalOpen(false);
        },
        onError: (error) => {
            console.error("Mutation error:", error);
            setIsCreatingDynamicForm(false);
        },
    });

    const handleCreateNewForm = () => {
        if (!guidedName) {
            setError("Fields can't be empty");
            return;
        }
        setIsCreatingGeneralForm(true);
        const newForm = newFormProps(guidedName);
        const newFormat = convertFormObject(newForm);
        formCreateMutation.mutate({
            data: newFormat,
            workspaceId: selectedWorkSpace,
        });
    };

    const handleDynamicFormCreation = () => {
        if (!dynamicName) {
            setError("Fields can't be empty");
            return;
        }

        setIsCreatingDynamicForm(true);
        dynamicFormCreateMutation.mutate({
            name: dynamicName,
            description: dynamicDescription,
            workspaceId: selectedWorkSpace,
        });
    };

    return (
        <Dialog
            className="z-50"
            open={createModalOpen}
            onOpenChange={setCreateModalOpen}
        >
            <DialogContent
                className={clsx(
                    "p-0 rounded-[8px] top-[40%] z-[9999] w-[460px]",
                    isCreatingDynamicForm && "dialog-border-animation",
                )}
            >
                <DialogHeader className=" px-3 pt-3 space-y-0.5">
                    <DialogTitle className="flex items-center gap-1">
                        <FilePenLine size={14} />
                        <span className="text-sm font-medium text-primary">
                            Create New Form
                        </span>
                    </DialogTitle>
                    <DialogDescription className="text-xs text-primary">
                        Get a head start with BuildForm AI or create your form
                        from scratch
                    </DialogDescription>
                </DialogHeader>
                <Separator />

                <Tabs defaultValue="dynamic" className="w-full px-3">
                    <TabsList className="w-full h-9">
                        <TabsTrigger
                            value="dynamic"
                            className="w-full text-xs flex items-center gap-1 text-[#44546F]"
                        >
                            BuildForm AI
                            <Sparkles size={14} className="font-normal" />
                        </TabsTrigger>
                        <TabsTrigger
                            value="guided"
                            className="w-full text-xs flex items-center gap-1 text-[#44546F]"
                        >
                            Start from Scratch
                        </TabsTrigger>
                    </TabsList>
                    <TabsContent value="dynamic">
                        <div className="mt-2 mb-4">
                            <div className="rounded-[8px] w-full bg-blue-50 flex p-2 gap-1.5 items-start">
                                <svg
                                    width="14"
                                    height="15"
                                    viewBox="0 0 14 15"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M7.00008 9.64616V7.31283M7.00008 4.97949H7.00591M12.8334 7.31283C12.8334 10.5345 10.2217 13.1462 7.00008 13.1462C3.77842 13.1462 1.16675 10.5345 1.16675 7.31283C1.16675 4.09116 3.77842 1.47949 7.00008 1.47949C10.2217 1.47949 12.8334 4.09116 12.8334 7.31283Z"
                                        stroke="#2563EB"
                                        stroke-width="0.875"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg>

                                <p className="text-xs text-blue-600">
                                    For better results provide clear details,
                                    audience, and context
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-col gap-4 mb-4">
                            <div className="flex flex-col">
                                <p className="text-xs font-medium text-primary">
                                    Form name
                                </p>
                                <p className="text-xs text-zinc-500">
                                    The form name will be displayed when sharing
                                </p>
                                <ShadInput
                                    className="flex w-full h-10 px-3 py-2 mt-1 text-xs transition duration-150 bg-white border rounded-lg border-zinc-200 file:border-0 file:bg-transparent file:text-sm file:font-medium focus-visible:outline-none focus-visible:ring-0 disabled:cursor-not-allowed disabled:opacity-50 placeholder:text-zinc-500"
                                    required
                                    placeholder="e.g. Feedback form"
                                    onChange={(e) => {
                                        setError("");
                                        setDynamicName(e.target.value);
                                    }}
                                    autoFocus
                                />
                            </div>

                            <div className="flex flex-col">
                                <p className="text-xs font-medium text-primary">
                                    Form description
                                </p>
                                <p className="text-xs text-zinc-500">
                                    Describe the goal of this form
                                </p>
                                <Textarea
                                    rows={1}
                                    className="flex w-full h-10 px-3 py-2 mt-1 text-xs transition duration-150 bg-white border rounded-lg resize-none border-zinc-200 file:border-0 file:bg-transparent file:text-sm file:font-medium focus-visible:outline-none focus-visible:ring-0 disabled:cursor-not-allowed disabled:opacity-50 placeholder:text-zinc-500 placeholder:text-xs"
                                    placeholder="e.g. A form to collect feedback from my customers"
                                    onChange={(e) => {
                                        setError("");
                                        setDynamicDescription(e.target.value);
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            handleDynamicFormCreation();
                                        }
                                    }}
                                />
                                {error && (
                                    <p className="pl-1 mt-1 text-xs text-red-500">
                                        {error}
                                    </p>
                                )}
                            </div>
                        </div>
                        {/* button section */}
                        <div className="flex flex-col gap-2 mb-4">
                            <Button
                                className="h-9 text-white rounded-[8px] py-1.5 bg-primary hover:bg-primary/90"
                                onClick={handleDynamicFormCreation}
                                type="submit"
                                disabled={isCreatingDynamicForm}
                            >
                                {isCreatingDynamicForm ? (
                                    <div className="flex gap-1.5 items-center">
                                        <span className="bg-white rounded-full size-3"></span>
                                        <span className="text-sm">
                                            Buildform AI is thinking...
                                        </span>
                                    </div>
                                ) : (
                                    "          Build a New Form"
                                )}
                            </Button>
                            <Button
                                className="bg-white h-9 rounded-[8px]  text-primary border-[#DCDFE4] border hover:bg-white"
                                onClick={() => {
                                    setCreateModalOpen(false);
                                }}
                            >
                                Cancel
                            </Button>
                        </div>
                    </TabsContent>
                    <TabsContent value="guided">
                        <div className="mt-2 mb-4">
                            <div className="rounded-[8px] w-full bg-blue-50 flex p-2 gap-1.5 items-start">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                    data-slot="icon"
                                    className="h-[0.8rem] min-w-[0.8rem] text-blue-600 mt-0.5"
                                >
                                    <path d="M10 1a6 6 0 0 0-3.815 10.631C7.237 12.5 8 13.443 8 14.456v.644a.75.75 0 0 0 .572.729 6.016 6.016 0 0 0 2.856 0A.75.75 0 0 0 12 15.1v-.644c0-1.013.762-1.957 1.815-2.825A6 6 0 0 0 10 1ZM8.863 17.414a.75.75 0 0 0-.226 1.483 9.066 9.066 0 0 0 2.726 0 .75.75 0 0 0-.226-1.483 7.553 7.553 0 0 1-2.274 0Z"></path>
                                </svg>
                                <p className="text-xs text-blue-600">
                                    AI statements are available for Start from
                                    Scratch Projects
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-col gap-4 mb-4">
                            <div className="flex flex-col">
                                <p className="text-xs font-medium text-primary">
                                    Form name
                                </p>
                                <p className="text-xs text-zinc-500">
                                    The form name will be displayed when sharing
                                </p>
                                <ShadInput
                                    className="flex w-full h-10 px-3 py-2 mt-1 text-xs transition duration-150 bg-white border rounded-lg border-zinc-200 file:border-0 file:bg-transparent file:text-sm file:font-medium focus-visible:outline-none focus-visible:ring-0 disabled:cursor-not-allowed disabled:opacity-50 placeholder:text-zinc-500"
                                    required
                                    placeholder="e.g. Feedback form"
                                    onChange={(e) => {
                                        setError("");
                                        setGuidedName(e.target.value);
                                    }}
                                    autoFocus
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            handleCreateNewForm();
                                        }
                                    }}
                                />
                                {error && (
                                    <p className="pl-1 mt-1 text-xs text-red-500">
                                        {error}
                                    </p>
                                )}
                            </div>
                        </div>
                        {/* button section */}
                        <div className="flex flex-col gap-2 mb-4">
                            <Button
                                className="h-9 text-white rounded-[8px] py-1.5 bg-primary hover:bg-primary/90"
                                onClick={handleCreateNewForm}
                                disabled={isCreatingGeneralForm}
                            >
                                {isCreatingGeneralForm ? (
                                    <LoaderCircle className="animate-spin" />
                                ) : (
                                    <span>Build a New Form</span>
                                )}
                            </Button>
                            <Button
                                className="bg-white h-9 rounded-[8px]  text-primary border-[#DCDFE4] border hover:bg-white"
                                onClick={() => {
                                    setCreateModalOpen(false);
                                }}
                            >
                                Cancel
                            </Button>
                        </div>
                    </TabsContent>
                </Tabs>
            </DialogContent>
        </Dialog>
    );
};
