import emoji from "react-easy-emoji";
import {
    getCountryCallingCode as _getCountryCallingCode,
    getCountries,
} from "react-phone-number-input";
import countryToEmojiMap from "../Constants/flags.json";
import { useFormContext } from "../Contexts/FormContext";
import tinycolor from "tinycolor2";

const emojis = getCountries().reduce(
    (o, key) => Object.assign(o, { [key]: emoji(countryToEmojiMap[key]) }),
    {},
);

export const CountryEmoji = ({ code }) => {
    const { styles } = useFormContext();

    if (!code) {
        return null;
    }

    return countryToEmojiMap.hasOwnProperty(code) ? (
        emojis[code]
    ) : (
        <div
            style={{
                height: "1em",
                width: "1em",
                margin: "0px 0.05em 0px 0.1em",
                verticalAlign: "-0.1em",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
            }}
        >
            <svg
                id="Layer_2"
                xmlns="http://www.w3.org/2000/svg"
                width="70.86"
                height="51.05"
                viewBox="0 0 70.86 51.05"
            >
                <defs>
                    <style>
                        {`.cls-1 {
                fill: ${styles.secondary_color};
            }

            .cls-2 {
                fill: ${tinycolor(styles.secondary_color)
                    .setAlpha(0.1)
                    .toRgbString()};
            }`}
                    </style>
                </defs>
                <g id="Layer_1-2">
                    <g>
                        <rect
                            className="cls-2"
                            x="1"
                            y="1"
                            width="68.86"
                            height="49.05"
                            rx="6.26"
                            ry="6.26"
                        />
                        <path
                            className="cls-1"
                            d="M63.6,2c2.9,0,5.26,2.36,5.26,5.26V43.79c0,2.9-2.36,5.26-5.26,5.26H7.26c-2.9,0-5.26-2.36-5.26-5.26V7.26c0-2.9,2.36-5.26,5.26-5.26H63.6m0-2H7.26C3.25,0,0,3.25,0,7.26V43.79c0,4.01,3.25,7.26,7.26,7.26H63.6c4.01,0,7.26-3.25,7.26-7.26V7.26c0-4.01-3.25-7.26-7.26-7.26h0Z"
                        />
                    </g>
                    <path
                        className="cls-1"
                        d="M35.43,12.4c7.24,0,13.12,5.89,13.12,13.12s-5.89,13.12-13.12,13.12-13.12-5.89-13.12-13.12,5.89-13.12,13.12-13.12m0-2h0c-8.35,0-15.12,6.77-15.12,15.12h0c0,8.35,6.77,15.12,15.12,15.12h0c8.35,0,15.12-6.77,15.12-15.12h0c0-8.35-6.77-15.12-15.12-15.12h0Z"
                    />
                </g>
            </svg>
        </div>
    );
};

export const getCountryCallingCode = (country) => {
    try {
        if (!country) {
            return null;
        }
        return _getCountryCallingCode(country);
    } catch (e) {
        return e;
    }
};
