import React, { useState, useEffect, useRef } from "react";
import { io } from "socket.io-client";
import { useFormContext } from "../../Contexts/FormContext";
import { motion } from "framer-motion";
const SOCKET_SERVER_URL = process.env.REACT_APP_SERVICE_URL;
const SOCKET_PATH = "/statements";
import {
    getDynamicTextDescription,
    getDynamicTextTitle,
} from "../../Constants/styles";
import Markdown from "react-markdown";
import { AttachmentLayout } from "./AttachmentLayout";
import { cn } from "../../lib/utils";

const serviceType = "production";

const AIStatement = ({ field }) => {
    const {
        signature,
        setShowNextButton,
        data,
        responseId,
        formTheme,
        updateAnswers,
    } = useFormContext();
    setShowNextButton(false);
    const [textArray, setTextArray] = useState([]);
    const [isConnected, setIsConnected] = useState(false);
    const [error, setError] = useState(null);
    const socketRef = useRef(null);
    const [isCompleted, setIsCompleted] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [getneratedStatement, setGeneratedStatement] = useState("");

    isCompleted ? setShowNextButton(true) : setShowNextButton(false);

    useEffect(() => {
        socketRef.current = io(SOCKET_SERVER_URL, {
            transports: ["websocket"],
            auth: {
                service: serviceType,
                signature: signature,
            },
            path: SOCKET_PATH,
        });

        const socket = socketRef.current;

        socket.on("connect", () => {
            setIsConnected(true);
            setIsCompleted(false);
            setError(null);
        });

        // Emit the generate_statement event to the server
        socket.emit("statement", {
            response_id: responseId,
            form_id: data?.id,
            ai_statement_field_id: field?.id,
        });

        socket.on("response", (data) => {
            setTextArray((prevArray) => [...prevArray, data?.chunk]);
            setIsLoading(false);
            if (data?.status === "completed") {
                setIsCompleted(true);
            }
            setGeneratedStatement(data?.generated_content);
        });

        socket.on("disconnect", () => {
            setIsConnected(false);
            setShowNextButton(false);
            setIsCompleted(false);
        });

        socket.on("connect_error", (err) => {
            setError("Connection error");
            console.error("Connection error: ", err);
        });

        socket.on("connect_timeout", () => {
            setError("Connection timeout");
            console.error("Connection timeout");
        });

        socket.on("error", (err) => {
            setError("An error occurred");
            console.error("Error: ", err);
        });

        return () => {
            socket.disconnect();
        };
    }, [signature, field?.id]);

    useEffect(() => {
        if (isCompleted && textArray?.length > 0) {
            const answer = getneratedStatement;
            updateAnswers(field?.id, null, answer);
        }
    }, [isCompleted, textArray, updateAnswers, field?.id]);

    if (isLoading)
        return (
            <motion.div style={{ textAlign: "left" }} layout="position">
                <motion.span
                    initial={{ opacity: 0 }}
                    animate={{ opacity: [0, 1, 0] }}
                    transition={{
                        repeat: Infinity,
                        duration: 2.5,
                        ease: "easeInOut",
                    }}
                    className="inline-block text-transparent bg-gradient-to-r from-blue-600 via-green-500 to-indigo-400 bg-clip-text"
                    style={{
                        fontSize: getDynamicTextTitle(formTheme?.text_size),
                        marginLeft: "5px",
                    }}
                >
                    BuildForm AI is thinking...
                </motion.span>
            </motion.div>
        );

    const hasAttachment =
        field?.layout?.attachment?.href || field?.layout?.attachment?.link;

    const mockImage = {
        href: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSxtc53St65P2qVkn9Qr54sXhNz9d0UUV54nw&s",
    };

    return (
        <div
            className={cn(
                `grid grid-cols-1 lg:grid-cols-${hasAttachment ? 5 : 1}`,
            )}
            style={{
                color: formTheme?.text_color,
                textAlign: "left",
                fontSize: "22px",
                letterSpacing: "0.01rem",
            }}
        >
            <Markdown
                className={`break-words col-start-1 col-span-3 flex flex-col justify-center markdown`}
            >
                {textArray.join("")}
            </Markdown>
            {/* {hasAttachment && !isLoading && (
                <div className="hidden col-span-2 col-start-4 lg:flex lg:justify-center">
                    <AttachmentLayout
                        attachment={field?.layout?.attachment}
                        mobile={false}
                    />
                </div>
            )} */}
        </div>
    );
};

export default AIStatement;
