"use strict";
import { Switch } from "../../../Components/ui/switch.tsx";
import React, { useState, useEffect, useRef } from "react";
import { useFormEditorContext } from "../../../Contexts/FormEditorContext.jsx";
import { useMutation } from "@tanstack/react-query";
import { useApi } from "../../../Api/useApi.ts";
import { Popover, PopoverContent, PopoverTrigger } from "../../ui/popover.tsx";
import { useParams } from "react-router-dom";
import { Button } from "../../ui/button.tsx";
import { Brain, Upload } from "lucide-react";
import { ImageUploadPopover } from "../../MyEditor/WelcomeScreenSettings.jsx";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "../../../Components/ui/select.tsx";

export const FieldSettingEditor = ({
    label,
    type,
    editorField,
    setEditorField,
}) => {
    const api = useApi();
    const { id } = useParams();
    const [preview, setPreview] = useState(
        editorField?.attachment?.link || null,
    );
    const { fields, selectedField, setFields } = useFormEditorContext();
    const [localChanges, setLocalChanges] = useState(false);
    const prevEditorFieldRef = useRef(null);

    const uploadMutation = useMutation({
        mutationFn: (form) => api.files.uploadFile(form, id),
        onSuccess: (data) => {
            const url = data?.data?.url;

            // Update the attachment link for other selected fields
            const newFields = [...fields];
            const index = newFields.findIndex(
                (f) => f?.id === selectedField?.id,
            );

            if (index !== -1) {
                const updatedField = {
                    ...newFields[index],
                    attachment: {
                        ...newFields[index].attachment,
                        link: url,
                        //type: "image",
                    },
                };
                newFields[index] = updatedField;
                setFields(newFields);
            }
        },
        onError: (error) => {
            console.error("Could not upload image", error);
        },
    });

    useEffect(() => {
        // Only run the save logic if the editorField has actually changed
        if (
            localChanges &&
            prevEditorFieldRef.current !== null &&
            JSON.stringify(prevEditorFieldRef.current) !==
                JSON.stringify(editorField)
        ) {
            const saveEditorField = () => {
                const newFields = [...fields];
                const index = newFields.findIndex(
                    (f) => f?.id === selectedField?.id,
                );

                const updatedEditorField = {
                    ...editorField,
                    title: editorField?.title || "",
                    properties: {
                        ...editorField.properties,
                        description: editorField.properties?.description || "",
                    },
                };

                const cleanEditorField = Object.fromEntries(
                    Object.entries(updatedEditorField).filter(
                        ([key, value]) => value != null,
                    ),
                );

                newFields[index] = cleanEditorField;
                setFields(newFields);

                setLocalChanges(false);
            };

            // Debounce the save to prevent excessive updates
            const timeoutId = setTimeout(saveEditorField, 300);

            // Clean up the timeout to prevent memory leaks
            return () => clearTimeout(timeoutId);
        }

        // Update the ref with the current editorField
        prevEditorFieldRef.current = editorField;
    }, [editorField, fields, selectedField, uploadMutation]);

    useEffect(() => {
        setPreview(editorField?.attachment?.link || null);
    }, [editorField?.attachment?.link]);

    const getValueFromNestedKey = (obj, key) => {
        return key.split(".").reduce((o, i) => (o ? o[i] : ""), obj);
    };

    const setValueToNestedKey = (obj, key, value) => {
        const keys = key.split(".");
        const lastKey = keys.pop();
        const deepClone = (obj) => {
            if (obj === null || typeof obj !== "object") return obj;
            if (Array.isArray(obj)) return obj.map(deepClone);
            const clonedObj = {};
            for (let k in obj) {
                clonedObj[k] = deepClone(obj[k]);
            }
            return clonedObj;
        };
        const newObj = deepClone(obj);
        const deep = keys.reduce((o, i) => {
            if (!o[i]) o[i] = {};
            return o[i];
        }, newObj);
        deep[lastKey] = value;
        return newObj;
    };

    return (
        <div>
            <FieldRenderer
                type={type}
                label={label}
                setEditorField={setEditorField}
                editorField={editorField}
                getValueFromNestedKey={getValueFromNestedKey}
                setValueToNestedKey={setValueToNestedKey}
                preview={preview}
                setPreview={setPreview}
                setLocalChanges={setLocalChanges}
            />
        </div>
    );
};

const FieldRenderer = ({
    type,
    label,
    editorField,
    setEditorField,
    getValueFromNestedKey,
    setValueToNestedKey,
    setLocalChanges,
}) => {
    const handleSwitchChange = (checked) => {
        setEditorField((prev) => {
            const newEditorField = setValueToNestedKey(prev, label, checked);
            return newEditorField;
        });
        setLocalChanges(true);
    };

    const handleInputChange = (e) => {
        setEditorField((prev) => {
            const newEditorField = setValueToNestedKey(
                prev,
                label,
                e.target.value,
            );
            return newEditorField;
        });
    };

    /* attachment handling  */
    //const [image, setImage] = useState(null);

    const formatLabel = (label) => {
        if (label === "link") {
            return "Image";
        }
        if (label === "type") {
            return "";
        }
        return label.replace(/_/g, " ");
    };

    switch (type) {
        case "Switch":
            return (
                <div className="bg-[#F1F2F4] flex gap-2 items-center h-9 py-[10px] px-3 justify-between rounded-lg min-w-[119px]">
                    <span className="font-normal capitalize text-[#191B33] text-[0.75rem]">
                        {(() => {
                            const extractedLabel = label.split(".").pop();
                            const formattedLabel = formatLabel(extractedLabel);
                            return formattedLabel;
                        })()}
                    </span>
                    <Switch
                        key={label}
                        checked={
                            getValueFromNestedKey(editorField, label) || false
                        }
                        onCheckedChange={handleSwitchChange}
                    />
                </div>
            );
        case "ShortText":
            return (
                <input
                    key={label}
                    className="p-2 w-full rounded-md border border-gray-300 focus:border-black focus:outline-none"
                    value={getValueFromNestedKey(editorField, label) || ""}
                    onChange={handleInputChange}
                    placeholder={label === "title" ? "Title" : "Description"}
                />
            );

        case "Image":
            return <ImageUploadPopover />;

        case "KnowledgeBase":
            return <KnowledgeBase />;

        case "StartAt":
            return <StartAt />;

        default:
            return <div></div>;
    }
};

export default FieldRenderer;

const KnowledgeBase = () => {
    const fileRef = useRef(null);
    const [file, setFile] = useState(null);
    const [uploadStatus, setUploadStatus] = useState("idle");

    const handleClick = () => {
        if (fileRef.current) {
            fileRef.current.click();
        }
    };

    return (
        <div
            className={`absolute right-0 top-2.5 flex gap-2 min-w-[113px] text-sm flex-col items-start`}
        >
            <Popover>
                <PopoverTrigger>
                    <div className=" flex justify-center gap-2 items-center bg-[#F1F2F4] data-[state=open]:bg-[#191B33] px-3 py-2.5 rounded-[8px]">
                        <p className=" text-xs font-normal text-[#191B33]">
                            Train the AI
                        </p>
                        <Brain
                            size={16}
                            className=" text-[#191B33] "
                            strokeWidth={1.5}
                        />
                    </div>
                </PopoverTrigger>
                <PopoverContent
                    align="end"
                    className=" relative flex justify-start w-[17.5rem] p-3   bg-[#191B33] mt-1.5 rounded-xl"
                >
                    <div className="absolute -top-1.5 right-10 ">
                        <svg
                            width="18"
                            height="12"
                            viewBox="0 0 18 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M7.06241 0.781893C8.18972 -0.194391 9.86301 -0.194389 10.9903 0.781895L16.8001 5.81327C18.8998 7.63167 17.6138 11.0811 14.8361 11.0811H3.21663C0.438977 11.0811 -0.847034 7.63167 1.25268 5.81327L7.06241 0.781893Z"
                                fill="#191B33"
                            />
                        </svg>
                    </div>
                    <div className="flex flex-col justify-start w-full gap-4">
                        <div className="flex flex-col justify-center gap-1">
                            <div className="flex items-center justify-start gap-2">
                                <Brain
                                    size={12}
                                    className="text-white"
                                    strokeWidth={2}
                                />
                                <p className="text-sm font-medium text-white">
                                    Train the AI
                                </p>
                            </div>
                            <p className="text-xs font-normal text-white">
                                Train the AI to answer questions based on
                                content from a document you import into this
                                conversation.
                            </p>
                        </div>
                        <div className="flex flex-col w-full gap-1">
                            <p className="text-xs font-normal text-white">
                                Upload a PDF File
                            </p>
                            <div className="flex items-center justify-center w-full gap-1">
                                <div
                                    className="border-[#44546F] hover:cursor-pointer border px-3 py-2 bg-[#172B4D] flex justify-start items-start h-9 text-[#8590A2] text-xs rounded-[8px] w-full"
                                    onClick={handleClick}
                                >
                                    <p className="">brand.pdf</p>
                                </div>
                                <input
                                    type="file"
                                    accept=".pdf"
                                    style={{ display: "none" }}
                                    ref={fileRef}
                                />
                                <Button
                                    variant={"outline"}
                                    className="hover:bg-[#172B4D] bg-[#172B4D] border-[#44546F] h-9   text-[#8590A2] text-xs rounded-[6px]"
                                    size={"sm"}
                                >
                                    <Upload
                                        size={12}
                                        className="text-[#8590A2] "
                                        strokeWidth={1.5}
                                    />
                                </Button>
                            </div>
                        </div>
                    </div>
                </PopoverContent>
            </Popover>
        </div>
    );
};

const StartAt = () => {
    const { editorField, setFields, setEditorField } = useFormEditorContext();

    const { start_at, end_at } = editorField?.properties;

    const updateField = (fieldId, key, subkey, value) => {
        const numValue = parseInt(value);
        setFields((prevFields) => {
            const updatedFields = prevFields.map((field) => {
                if (field.id === fieldId) {
                    if (subkey) {
                        return {
                            ...field,
                            [key]: {
                                ...field[key],
                                [subkey]: numValue,
                            },
                        };
                    } else {
                        return {
                            ...field,
                            [key]: numValue,
                        };
                    }
                }
                return field;
            });
            return updatedFields;
        });

        setEditorField((prev) => {
            if (subkey) {
                return {
                    ...prev,
                    [key]: {
                        ...prev[key],
                        [subkey]: numValue,
                    },
                };
            } else {
                return {
                    ...prev,
                    [key]: numValue,
                };
            }
        });
    };

    const defaultStartString = start_at?.toString() || "0";
    const defaultEndString = end_at?.toString() || "5";

    return (
        <div className="flex items-center gap-2">
            <Select
                onValueChange={(value) =>
                    updateField(
                        editorField?.id,
                        "properties",
                        "start_at",
                        value,
                    )
                }
                defaultValue={defaultStartString}
            >
                <SelectTrigger className=" w-[55px] focus:ring-0 h-9 bg-[#F1F2F4] active:bg-[#F1F2F4] active:border-none active:ring-0 border-none">
                    <SelectValue className="text-sm" />
                </SelectTrigger>
                <SelectContent>
                    <SelectItem value="0">0</SelectItem>
                    <SelectItem value="1">1</SelectItem>
                </SelectContent>
            </Select>

            <p className="text-sm"> to</p>
            <Select
                onValueChange={(value) =>
                    updateField(editorField?.id, "properties", "end_at", value)
                }
                defaultValue={defaultEndString}
            >
                <SelectTrigger className=" bg-[#F1F2F4] focus:ring-0 w-[55px] h-9 active:bg-[#F1F2F4] active:border-none active:ring-0 border-none">
                    <SelectValue className="text-sm" />
                </SelectTrigger>
                <SelectContent>
                    <SelectItem value="5">5</SelectItem>
                    <SelectItem value="6">6</SelectItem>
                    <SelectItem value="7">7</SelectItem>
                    <SelectItem value="8">8</SelectItem>
                    <SelectItem value="9">9</SelectItem>
                    <SelectItem value="10">10</SelectItem>
                </SelectContent>
            </Select>
        </div>
    );
};

