import React, { useRef, useState, useEffect } from "react";
import { Button } from "../ui/button.tsx";
import { Link, useParams } from "react-router-dom";
import { useFormEditorContext } from "../../Contexts/FormEditorContext";
import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbList,
    BreadcrumbPage,
    BreadcrumbSeparator,
} from "../ui/breadcrumb.tsx";
import { ToggleGroup, ToggleGroupItem } from "../ui/toggle-group.tsx";
import {
    TooltipProvider,
    Tooltip,
    TooltipTrigger,
    TooltipContent,
} from "../ui/tooltip.tsx";
import { Link2, CheckCheck } from "lucide-react";

import { ROUTES } from "../../Constants/routes.js";
import { motion } from "framer-motion";

import { Dialog, DialogContent } from "../ui/dialog.tsx";
import { Input } from "../ui/input.tsx";
import { QRCode } from "react-qrcode-logo";
import * as htmlToImage from "html-to-image";
import confetti from "canvas-confetti";

const svgButtons = [
    {
        name: "desktop View",
        value: "desktop",
        svg: (
            <svg
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M5.8335 14.3496H11.1668M8.50016 11.6829V14.3496M3.16683 2.34961H13.8335C14.5699 2.34961 15.1668 2.94656 15.1668 3.68294V10.3496C15.1668 11.086 14.5699 11.6829 13.8335 11.6829H3.16683C2.43045 11.6829 1.8335 11.086 1.8335 10.3496V3.68294C1.8335 2.94656 2.43045 2.34961 3.16683 2.34961Z"
                    stroke="#191B33"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        ),
    },
    {
        name: "mobile View",
        value: "mobile",
        svg: (
            <svg
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M5.3002 1.98268C4.7295 1.98268 4.26686 2.44532 4.26686 3.01602V13.6827C4.26686 14.2533 4.72948 14.716 5.3002 14.716H11.7002C12.2708 14.716 12.7335 14.2533 12.7335 13.6827V3.01602C12.7335 2.4453 12.2708 1.98268 11.7002 1.98268H5.3002ZM4.2002 3.01602C4.2002 2.4085 4.69268 1.91602 5.3002 1.91602H11.7002C12.3077 1.91602 12.8002 2.40851 12.8002 3.01602V13.6827C12.8002 14.2902 12.3077 14.7827 11.7002 14.7827H5.3002C4.69269 14.7827 4.2002 14.2902 4.2002 13.6827V3.01602Z"
                    fill="#191B33"
                    stroke="#191B33"
                />
            </svg>
        ),
    },
    {
        name: "undo",
        value: "undo",
        svg: (
            <svg
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M6.49984 9.68327L3.1665 6.34993M3.1665 6.34993L6.49984 3.0166M3.1665 6.34993H10.1665C10.648 6.34993 11.1248 6.44478 11.5697 6.62904C12.0145 6.81331 12.4187 7.0834 12.7592 7.42388C13.0997 7.76436 13.3698 8.16857 13.5541 8.61343C13.7383 9.05829 13.8332 9.53509 13.8332 10.0166C13.8332 10.4981 13.7383 10.9749 13.5541 11.4198C13.3698 11.8646 13.0997 12.2688 12.7592 12.6093C12.4187 12.9498 12.0145 13.2199 11.5697 13.4042C11.1248 13.5884 10.648 13.6833 10.1665 13.6833H7.83317"
                    stroke="#191B33"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        ),
    },
    {
        name: "redo",
        value: "redo",
        svg: (
            <svg
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M10.4998 9.68327L13.8332 6.34993M13.8332 6.34993L10.4998 3.0166M13.8332 6.34993H6.83317C5.86071 6.34993 4.92808 6.73624 4.24045 7.42388C3.55281 8.11151 3.1665 9.04414 3.1665 10.0166C3.1665 10.4981 3.26135 10.9749 3.44561 11.4198C3.62988 11.8646 3.89996 12.2688 4.24045 12.6093C4.92808 13.297 5.86071 13.6833 6.83317 13.6833H9.1665"
                    stroke="#191B33"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        ),
    },
];

const SaveStatus = {
    UNSAVED: "UNSAVED",
    SAVING: "SAVING",
    SAVED: "SAVED",
};

const EdiotorNav = () => {
    const { name, saveChanges, form, device, setDevice, formStatus } =
        useFormEditorContext();
    const { id } = useParams();
    const { workspaceId } = useParams();

    const handleOnDeviceChange = (value) => {
        setDevice((prev) => value || prev);
    };

    return (
        <>
            <PublishedModal />
            <div className="flex items-center justify-between w-full h-14">
                {/* Breadcrumb - Start */}
                <Breadcrumb className="min-w-[250px]">
                    <BreadcrumbList className="flex gap-2 ">
                        <BreadcrumbItem>
                            <BreadcrumbLink href={ROUTES.DASHBOARD}>
                                <div className="flex items-center gap-1.5">
                                    <svg
                                        width="16"
                                        height="17"
                                        viewBox="0 0 16 17"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M2.19333 5.01654L8 8.34987M8 8.34987L13.8067 5.01654M8 8.34987V15.0165M14 11.0165V5.6832C13.9998 5.44939 13.938 5.21974 13.821 5.01731C13.704 4.81488 13.5358 4.64678 13.3333 4.52987L8.66667 1.8632C8.46397 1.74618 8.23405 1.68457 8 1.68457C7.76595 1.68457 7.53603 1.74618 7.33333 1.8632L2.66667 4.52987C2.46418 4.64678 2.29599 4.81488 2.17897 5.01731C2.06196 5.21974 2.00024 5.44939 2 5.6832V11.0165C2.00024 11.2504 2.06196 11.48 2.17897 11.6824C2.29599 11.8849 2.46418 12.053 2.66667 12.1699L7.33333 14.8365C7.53603 14.9536 7.76595 15.0152 8 15.0152C8.23405 15.0152 8.46397 14.9536 8.66667 14.8365L13.3333 12.1699C13.5358 12.053 13.704 11.8849 13.821 11.6824C13.938 11.48 13.9998 11.2504 14 11.0165Z"
                                            stroke="#2C3E5D"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                    <span className="text-xs font-normal text-gray-800">
                                        Workspace
                                    </span>
                                </div>
                            </BreadcrumbLink>
                        </BreadcrumbItem>
                        <BreadcrumbSeparator />
                        <BreadcrumbItem>
                            <BreadcrumbPage className="text-xs font-normal text-gray-800 capitalize">
                                {name}
                            </BreadcrumbPage>
                        </BreadcrumbItem>
                    </BreadcrumbList>
                </Breadcrumb>

                {/* Buttons - Center */}
                <div className="absolute flex items-center justify-center gap-1 transform -translate-x-1/2 left-1/2">
                    <ToggleGroup
                        type="single"
                        value={device}
                        defaultValue={device}
                        onValueChange={handleOnDeviceChange}
                    >
                        <ToggleGroupItem
                            className={`w-7 h-7`}
                            key={svgButtons[0].name}
                            value={svgButtons[0].value}
                        >
                            {svgButtons[0].svg}
                        </ToggleGroupItem>
                        <ToggleGroupItem
                            className={`w-7 h-7`}
                            key={svgButtons[1].name}
                            value={svgButtons[1].value}
                        >
                            {svgButtons[1].svg}
                        </ToggleGroupItem>
                        <div className="border-r border-[#F1F2F4] h-6"></div>
                        <Button
                            variant="ghost"
                            size="icon"
                            className="w-7 h-7"
                            disabled
                        >
                            {svgButtons[2].svg}
                        </Button>
                        <Button
                            variant="ghost"
                            size="icon"
                            className="w-7 h-7"
                            disabled
                        >
                            {svgButtons[3].svg}
                        </Button>
                    </ToggleGroup>
                    <div></div>
                </div>

                {/* End Section - End */}
                <div className="flex justify-end gap-2">
                    <StatusIndicator />
                    <Link
                        to={`/workspaces/${workspaceId}/forms/${id}/results/analytics`}
                    >
                        <Button
                            variant={"outline"}
                            className="flex items-center py-1.5 px-3 h-9 gap-1.5"
                        >
                            <span className="text-[#191B33] rounded-lg  font-medium">
                                Submissions & Analytics
                            </span>
                            <svg
                                width="16"
                                height="17"
                                viewBox="0 0 16 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="text-black"
                            >
                                <path
                                    d="M4.6665 5.0166H11.3332M11.3332 5.0166V11.6833M11.3332 5.0166L4.6665 11.6833"
                                    stroke="#191B33"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </Button>
                    </Link>

                    <PreviewButton
                        formStatus={formStatus}
                        formUrlId={form?.url_id}
                    />
                    <Button
                        className="bg-[#191B33] px-[15.5px] rounded-lg h-9 py-[6px]"
                        onClick={() => {
                            saveChanges({ isPublished: true });
                        }}
                    >
                        <span className="text-sm font-medium">Publish</span>
                    </Button>
                </div>
            </div>
        </>
    );
};

export default EdiotorNav;

export const StatusIndicator = () => {
    const { saveStatus } = useFormEditorContext();
    const statusConfig = {
        [SaveStatus.UNSAVED]: {
            text: "Unsaved",
            color: "bg-red-500",
        },
        [SaveStatus.SAVING]: {
            text: "Saving",
            color: "bg-[#FFC620]",
            animation: {
                scale: [1, 1.5, 1],
                opacity: [1, 0.5, 1],
            },
        },
        [SaveStatus.SAVED]: {
            text: "Saved",
            color: "bg-green-600",
        },
    };
    const { text, color, animation } = statusConfig[saveStatus];
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
            className="flex items-center gap-2 mr-3"
        >
            <span className="text-[#191B33] text-[14px] font-medium">
                {text}
            </span>
            <motion.span
                className={`w-2 h-2 rounded-full ${color}`}
                animate={animation || { scale: 1, opacity: 1 }}
                transition={{ duration: 0.6, repeat: animation ? Infinity : 0 }}
            />
        </motion.div>
    );
};

const EyeIcon = () => (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="text-black"
    >
        <path
            d="M1.3335 8.34928C1.3335 8.34928 3.3335 3.68262 8.00016 3.68262C12.6668 3.68262 14.6668 8.34928 14.6668 8.34928C14.6668 8.34928 12.6668 13.0159 8.00016 13.0159C3.3335 13.0159 1.3335 8.34928 1.3335 8.34928Z"
            stroke="#191B33"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M8.00016 10.3493C9.10473 10.3493 10.0002 9.45385 10.0002 8.34928C10.0002 7.24471 9.10473 6.34928 8.00016 6.34928C6.89559 6.34928 6.00016 7.24471 6.00016 8.34928C6.00016 9.45385 6.89559 10.3493 8.00016 10.3493Z"
            stroke="#191B33"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

interface PreviewButtonProps {
    formStatus: string;
    formUrlId: string;
}

const PreviewButton: React.FC<PreviewButtonProps> = ({
    formStatus,
    formUrlId,
}) => {
    const baseButtonProps = {
        variant: "outline" as "outline", // Ensure the type is one of the expected literals
        className: "p-0 rounded-lg w-9 h-9",
    };

    if (formStatus === "draft") {
        return (
            <TooltipProvider delayDuration={100}>
                <Tooltip>
                    <TooltipTrigger>
                        <Button
                            {...baseButtonProps}
                            className={`${baseButtonProps.className} opacity-50 cursor-not-allowed`}
                            disabled
                        >
                            <EyeIcon />
                        </Button>
                    </TooltipTrigger>
                    <TooltipContent side="top">
                        <p>Preview not available for draft forms</p>
                    </TooltipContent>
                </Tooltip>
            </TooltipProvider>
        );
    }

    return (
        <a
            target="_blank"
            href={`${process.env.REACT_APP_PUBLISHED_URL}forms/${formUrlId}`}
        >
            <Button {...baseButtonProps}>
                <EyeIcon />
            </Button>
        </a>
    );
};

const PublishedModal = () => {
    const { showPublishedModal, setShowPublishedModal, form } =
        useFormEditorContext();
    const [isCopied, setIsCopied] = useState(false);

    const publicUrl = process.env.REACT_APP_PUBLISHED_URL + "forms/";

    const url = `${publicUrl}${form?.url_id}`;

    const qrCodeRef = useRef(null);

    const downloadQRCode = () => {
        if (!qrCodeRef.current) {
            console.error("QR Code element is not available.");
            return;
        }

        htmlToImage
            .toPng(qrCodeRef.current)
            .then((dataUrl) => {
                const link = document.createElement("a");
                link.href = dataUrl;
                link.download = "qr-code.png";
                link.click();
            })
            .catch((error) => {
                console.error("Error generating QR code:", error);
            });
    };

    // Run confetti effect when modal opens
    useEffect(() => {
        if (showPublishedModal) {
            confetti({
                particleCount: 250,
                spread: 120,
                origin: { y: 0.7 }, // Confetti falls from the middle-top
            });
        }
    }, [showPublishedModal]); // Runs only when `showPublishedModal` changes

    return (
        <Dialog
            open={showPublishedModal}
            onOpenChange={() => {
                setShowPublishedModal(false);
                setIsCopied(false);
            }}
        >
            <DialogContent
                hideClose
                className=" min-w-[814px]  sm:rounded-2xl p-0 top-[50%]"
            >
                <div className="flex flex-col gap-6 py-10 shadow-lg ">
                    <div className="flex flex-col justify-center w-full gap-3 ">
                        <h2 className=" font-semibold text-center text-[#191B33] text-[1.5rem]">
                            Your form has been published! 🎉
                        </h2>
                        <p className=" text-[0.875rem] text-center font-normal text-[#44546F]">
                            Built, published, and ready to go! <br />
                            Share the link and start collecting responses.
                        </p>
                    </div>
                    <div className="flex items-center justify-center gap-1 ">
                        <Input
                            className="w-[360px] h-9 focus:border-[#E4E4E7] border-[#E4E4E7] focus-visible:ring-offset-0 focus-visible:outline-none focus-visible:ring-0"
                            value={url}
                            readOnly
                            autoFocus
                        />
                        <Button
                            className="h-9 w-[109px] flex bg-[#191B33] gap-1 px-3 py-1.5  items-center text-white"
                            onClick={() => {
                                navigator.clipboard.writeText(url);
                                setIsCopied(true);
                                //coppied to clipboard toast message
                            }}
                        >
                            {isCopied ? (
                                <CheckCheck className="w-[13.33px]" />
                            ) : (
                                <Link2 className="w-[13.33px]" />
                            )}

                            <span className="text-sm font-medium">
                                {isCopied ? "Copied!" : "Copy Link"}
                            </span>
                        </Button>
                    </div>
                </div>
                {/* QR Section */}
                <div className="flex flex-col items-center justify-center w-full gap-6 py-10 ">
                    <div className=" border border-[#DCDFE4]   rounded-[16px] p-2 relative">
                        {/* QR Image */}{" "}
                        <div
                            onClick={() => {
                                downloadQRCode();
                            }}
                            ref={qrCodeRef}
                            className=" hover:cursor-pointer"
                        >
                            <QRCode
                                logoImage={"/main-logo.png"}
                                logoWidth={60}
                                value={url}
                                size={216}
                                qrStyle="dots"
                                removeQrCodeBehindLogo={true}
                                eyeRadius={2}
                                logoPadding={2}
                                ecLevel="H"
                            />
                        </div>
                        {/* Click me to download */}
                        <div className="absolute flex items-end justify-center gap-2 left-64 top-20">
                            <svg
                                width="35"
                                height="30"
                                viewBox="0 0 35 30"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M0.932752 27.212C0.00736419 26.1994 -0.048516 26.0027 0.298189 25.2027C0.588562 24.5038 1.0582 24.1365 2.49728 23.5151C5.83927 22.0343 7.66414 22.6213 4.57764 24.1784C3.66408 24.6292 2.96957 25.1029 3.0031 25.2209C3.12603 25.6536 6.64167 26.3556 8.86621 26.4039C15.3966 26.5683 21.8988 23.0418 23.0767 18.732C23.4243 17.3365 23.4227 16.5079 23.0438 16.2966C22.9175 16.2262 22.2546 16.287 21.5605 16.4629C18.4108 17.1238 15.4182 16.0182 15.2694 14.1473C15.1478 12.8213 17.5324 11.0385 19.6112 10.8731C20.909 10.8021 22.4949 11.5207 23.5549 12.7077L24.4914 13.7596L26.3642 12.4198C29.9071 9.92529 32.125 6.95688 32.9615 3.6156C33.1819 2.74518 33.5403 1.68679 33.7797 1.25739L34.2274 0.513723L34.3834 1.36224C34.5812 2.43271 34.12 4.62574 33.2489 6.72264C32.3637 8.8448 29.9608 11.6107 27.1599 13.7243L24.9356 15.3978L24.8993 17.0663C24.6951 23.9055 15.1536 29.3796 6.53301 27.6194C5.45472 27.4155 4.37642 27.2116 4.14619 27.1495C3.85136 27.0844 3.86499 27.3569 4.24878 28.0344C4.54852 28.5658 4.67972 29.1025 4.50537 29.237C4.08625 29.6324 2.0544 28.4665 0.932752 27.212ZM20.6414 15.0234C22.3326 14.8406 22.8279 14.1896 21.9473 13.3344C20.9796 12.3976 19.1203 12.288 17.8916 13.126C16.6235 13.9752 16.6235 13.9752 17.1228 14.386C17.7203 14.9178 19.1187 15.2008 20.6414 15.0234Z"
                                    fill="#8B22C3"
                                />
                            </svg>

                            <button
                                onClick={() => {
                                    downloadQRCode();
                                }}
                                className=" w-[140px] -ml-4 mb-6 hover:bg-[#FAF0FF] text-[#8B22C3] hover:text-[#8B22C3]  flex-nowrap flex font-medium bg-[#FAF0FF] py-1 px-2 rounded-[100px] text-xs "
                            >
                                Click me to download
                            </button>
                        </div>
                    </div>

                    <p className=" text-[0.875rem] text-center font-normal text-[#44546F]">
                        Or just scan the code to launch your Buildform. <br />
                        Works online and offline (though you’ll need a printer,
                        obviously).
                    </p>
                </div>
            </DialogContent>
        </Dialog>
    );
};
