import { useFormEditorContext } from "../../Contexts/FormEditorContext";
import React, { useState, useRef, useEffect } from "react";
import { Popover, PopoverTrigger, PopoverContent } from "../ui/popover.tsx";
import { useMutation } from "@tanstack/react-query";
import { useApi } from "../../Api/useApi.ts";
import { useParams } from "react-router-dom";
import { ImagePlus } from "lucide-react";
import clsx from "clsx";

const ScreenSettings = () => {
    return (
        <div
            className={`flex absolute right-4 top-7 flex-col gap-2 items-start text-sm`}
        >
            <ImageUploadPopover />
        </div>
    );
};

export default ScreenSettings;

export const ImageUploadPopover = () => {
    const api = useApi();
    const { id } = useParams();
    const [error, setError] = useState("");
    const {
        welcomeScreen,
        setWelcomeScreen,
        selectedField,
        fields,
        setFields,
        setSelectedField,
    } = useFormEditorContext();
    const [placement, setPlacement] = useState(
        selectedField === "welcome-screen"
            ? welcomeScreen?.layout?.placement
            : selectedField?.attachment?.placement || "right",
    );
    const [file, setFile] = useState(
        selectedField === "welcome-screen"
            ? welcomeScreen?.layout?.attachment?.link
            : selectedField?.attachment?.link,
    );

    useEffect(() => {
        setFile(
            selectedField === "welcome-screen"
                ? welcomeScreen?.layout?.attachment?.link
                : selectedField?.attachment?.link,
        );
        setPlacement(
            selectedField === "welcome-screen"
                ? welcomeScreen?.layout?.placement
                : selectedField?.attachment?.placement || "right",
        );
    }, [selectedField, welcomeScreen]);

    const uploadMutation = useMutation({
        mutationFn: async (form) => {
            const response = await api.files.uploadFile(form, id);
            setFile(response?.data?.url);
            if (selectedField === "welcome-screen") {
                setWelcomeScreen({
                    ...welcomeScreen,
                    layout: {
                        ...welcomeScreen?.layout,
                        attachment: {
                            ...welcomeScreen?.layout?.attachment,
                            link: response?.data?.url,
                        },
                    },
                });
            } else {
                const newFields = [...fields];
                const index = newFields.findIndex(
                    (f) => f?.id === selectedField?.id,
                );

                setSelectedField({
                    ...selectedField,
                    attachment: {
                        ...selectedField?.attachment,
                        link: response?.data?.url,
                    },
                });

                if (index !== -1) {
                    const updatedField = {
                        ...newFields[index],
                        attachment: {
                            ...newFields[index].attachment,
                            link: response?.data?.url,
                        },
                    };
                    newFields[index] = updatedField;
                    setFields(newFields);
                }
            }
        },
        onError: (error) => {
            console.error("Could not upload image", error);
        },
    });

    const fileInputRef = useRef(null);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        /* check file max size  */
        if (file?.size > 7 * 1024 * 1024) {
            setError("File size should be less than 7MB");
            return;
        }
        setError("");
        const formData = new FormData();
        formData.append("file", file);
        uploadMutation.mutate(formData);
        setFile(URL.createObjectURL(e.target.files[0]));
    };

    const handleRemoveImage = () => {
        setFile(null);
        if (selectedField === "welcome-screen") {
            setWelcomeScreen({
                ...welcomeScreen,
                layout: {
                    ...welcomeScreen?.layout,
                    attachment: {
                        ...welcomeScreen?.layout?.attachment,
                        link: null,
                    },
                },
            });
        } else {
            const newFields = [...fields];
            const index = newFields.findIndex(
                (f) => f?.id === selectedField?.id,
            );

            setSelectedField({
                ...selectedField,
                attachment: {
                    ...selectedField?.attachment,
                    link: null,
                },
            });

            if (index !== -1) {
                const updatedField = {
                    ...newFields[index],
                    attachment: {
                        ...newFields[index].attachment,
                        link: null,
                    },
                };
                newFields[index] = updatedField;
                setFields(newFields);
            }
        }
    };

    const updatePlacement = (value) => {
        if (selectedField === "welcome-screen") {
            setWelcomeScreen({
                ...welcomeScreen,
                layout: {
                    ...welcomeScreen.layout,
                    placement: value,
                },
            });
            setPlacement(value);
        } else {
            const newFields = [...fields];
            const index = newFields.findIndex(
                (f) => f?.id === selectedField?.id,
            );

            setSelectedField({
                ...selectedField,
                attachment: {
                    ...selectedField?.attachment,
                    placement: value,
                },
            });

            if (index !== -1) {
                const updatedField = {
                    ...newFields[index],
                    attachment: {
                        ...newFields[index].attachment,
                        placement: value,
                    },
                };
                newFields[index] = updatedField;
                setFields(newFields);
                setPlacement(value);
            }
        }
    };

    return (
        <Popover>
            <PopoverTrigger>
                <div className="h-9 w-9 flex items-center justify-center  bg-[#F1F2F4] rounded-lg">
                    <ImagePlus
                        size={16}
                        className=" text-[#191B33] font-normal"
                        strokeWidth={1.25}
                    />
                </div>
            </PopoverTrigger>
            <PopoverContent
                align="end"
                className=" relative flex justify-center w-60 p-3   bg-[#191B33] mt-1.5 rounded-xl"
            >
                <div className="absolute -top-1.5 right-2 ">
                    <svg
                        width="18"
                        height="12"
                        viewBox="0 0 18 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M7.06241 0.781893C8.18972 -0.194391 9.86301 -0.194389 10.9903 0.781895L16.8001 5.81327C18.8998 7.63167 17.6138 11.0811 14.8361 11.0811H3.21663C0.438977 11.0811 -0.847034 7.63167 1.25268 5.81327L7.06241 0.781893Z"
                            fill="#191B33"
                        />
                    </svg>
                </div>
                <div className="flex flex-col gap-4">
                    <div className="flex flex-col gap-1">
                        <div className="flex items-center gap-1">
                            <ImagePlus size={12} className="text-white" />
                            <span className="font-medium text-white text-[0.875rem]">
                                Add Image
                            </span>
                        </div>
                        <span className="font-normal hidden text-[12px] text-white ">
                            Dummy DescriptionText
                        </span>
                    </div>
                    <div className="flex flex-col gap-2">
                        <div className="flex justify-between">
                            <span className="text-white text-[12px] ">
                                Upload Image
                            </span>
                            {file && (
                                <button
                                    className="text-[#F44336] text-xs font-medium "
                                    onClick={handleRemoveImage} // Optional: To remove the image preview
                                >
                                    Delete
                                </button>
                            )}
                        </div>
                        {error && (
                            <span className="text-[#F44336] text-[12px]">
                                {error}
                            </span>
                        )}
                        {file && (
                            <div className="w-full">
                                <img
                                    src={file}
                                    alt="Image Preview"
                                    className="object-contain border border-[#44546F]  h-[116px] w-[216px] rounded-lg "
                                />
                            </div>
                        )}
                        {!file && (
                            <button
                                onClick={() => fileInputRef.current.click()}
                                className=" w-full h-[116px]"
                            >
                                <svg
                                    width="216"
                                    height="116"
                                    viewBox="0 0 216 117"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <rect
                                        x="0.5"
                                        y="0.581055"
                                        width="215"
                                        height="115"
                                        rx="5.5"
                                        fill="#172B4D"
                                    />
                                    <rect
                                        x="0.5"
                                        y="0.581055"
                                        width="215"
                                        height="115"
                                        rx="5.5"
                                        stroke="#44546F"
                                    />
                                    <path
                                        d="M114 60.0811V62.7477C114 63.1013 113.86 63.4405 113.609 63.6905C113.359 63.9406 113.02 64.0811 112.667 64.0811H103.333C102.98 64.0811 102.641 63.9406 102.391 63.6905C102.14 63.4405 102 63.1013 102 62.7477V60.0811M111.333 55.4144L108 52.0811M108 52.0811L104.667 55.4144M108 52.0811V60.0811"
                                        stroke="white"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg>
                            </button>
                        )}
                        <div className="flex items-center justify-between h-full mt-1">
                            <span className=" text-white text-start  text-[12px] capitalize">
                                image placement
                            </span>
                            <div className="flex items-center gap-2">
                                <button
                                    className={clsx(
                                        `p-1 border h-9 w-9 rounded-md focus:outline-none ${
                                            placement === "left"
                                                ? "bg-white"
                                                : "bg-[#172B4D] border border-[#44546F]"
                                        }`,
                                    )}
                                    onClick={() => updatePlacement("left")}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="28"
                                        height="20"
                                        viewBox="0 0 28 20"
                                        fill="none"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M0 2C0 0.895431 0.895431 0 2 0H13C13.5523 0 14 0.447716 14 1V19C14 19.5523 13.5523 20 13 20H2C0.895431 20 0 19.1046 0 18V2ZM17 11.75C17 11.3358 17.3358 11 17.75 11H22.25C22.6642 11 23 11.3358 23 11.75C23 12.1642 22.6642 12.5 22.25 12.5H17.75C17.3358 12.5 17 12.1642 17 11.75ZM17.75 8C17.3358 8 17 8.33579 17 8.75C17 9.16421 17.3358 9.5 17.75 9.5H24.25C24.6642 9.5 25 9.16421 25 8.75C25 8.33579 24.6642 8 24.25 8H17.75Z"
                                            fill="currentColor"
                                        ></path>
                                    </svg>
                                </button>
                                <button
                                    className={clsx(
                                        `p-1 border h-9 w-9 rounded-md focus:outline-none ${
                                            placement === "right"
                                                ? "bg-white"
                                                : "bg-[#172B4D] border border-[#44546F]"
                                        }`,
                                    )}
                                    onClick={() => updatePlacement("right")}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="28"
                                        height="20"
                                        viewBox="0 0 28 20"
                                        fill="none"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M14 1C14 0.447715 14.4477 0 15 0H26C27.1046 0 28 0.895431 28 2V18C28 19.1046 27.1046 20 26 20H15C14.4477 20 14 19.5523 14 19V1ZM3 11.75C3 11.3358 3.33579 11 3.75 11H8.25C8.66421 11 9 11.3358 9 11.75C9 12.1642 8.66421 12.5 8.25 12.5H3.75C3.33579 12.5 3 12.1642 3 11.75ZM3.75 8C3.33579 8 3 8.33579 3 8.75C3 9.16421 3.33579 9.5 3.75 9.5H10.25C10.6642 9.5 11 9.16421 11 8.75C11 8.33579 10.6642 8 10.25 8H3.75Z"
                                            fill="currentColor"
                                        ></path>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </PopoverContent>
            <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                ref={fileInputRef}
                className="hidden"
            />
        </Popover>
    );
};
