import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { useFormEditorContext } from "../../Contexts/FormEditorContext.jsx";
import { Separator } from "../ui/separator.tsx";
import { Input } from "../ui/input.tsx";
import { Label } from "../ui/label.tsx";
import { Switch } from "../ui/switch.tsx";
import { Button } from "../ui/button.tsx";
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "../ui/dialog.tsx";
import { Settings, Box, Puzzle } from "lucide-react";
import clsx from "clsx";
import { useMutation } from "@tanstack/react-query";
import { useApi } from "../../Api/useApi.ts";
import { useParams } from "react-router-dom";
import { Loader } from "lucide-react";

const tabTypes = [
    { label: "General", value: "general", icon: <Box size={10} /> },
    {
        label: "Integrations",
        value: "integrations",
        icon: <Puzzle size={10} />,
    },
];

// Zod schema for form validation
const formSettingsSchema = z.object({
    name: z.string().min(1, { message: "Form name cannot be empty" }),
    pauseForm: z.boolean(),
    showProgressBar: z.boolean(),
});

interface FormSettingsUpdateParams {
    id: string;
    workspace_id: string;
    body: {
        title: string;
        settings: {
            accept_responses: boolean;
            show_progress_bar: boolean;
        };
    };
}

const FormSettingsModal = () => {
    const api = useApi();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { id, workspaceId } = useParams();
    const { setFormSettings, setName, name, formSettings } =
        useFormEditorContext();

    const [tab, setTab] = useState<string>("general");
    const [openSettingDialog, setOpenSettingDialog] = useState<boolean>(false);

    // Default values from existing form settings
    const defaultValues = {
        name: name,
        pauseForm: formSettings?.accept_responses,
        showProgressBar: formSettings?.show_progress_bar,
    };

    const {
        control,
        handleSubmit,
        formState: { errors, isDirty },
        watch,
    } = useForm({
        resolver: zodResolver(formSettingsSchema),
        defaultValues,
        mode: "onChange",
        values: defaultValues,
    });

    const updateFormSettingsMutation = useMutation({
        mutationFn: (params: FormSettingsUpdateParams) =>
            api.forms.formSettingsUpdate(
                params.id,
                params.workspace_id,
                params.body.title,
                params.body,
            ),
        onSuccess: () => {
            setIsLoading(false);
            setOpenSettingDialog(false);
        },

        onError: (error) => {
            console.error("Error details:", error);
        },
    });

    const onSubmit = (data) => {
        setIsLoading(true);
        setName(data.name);
        setFormSettings((prev) => {
            return {
                ...prev,
                accept_responses: data.pauseForm,
                show_progress_bar: data.showProgressBar,
            };
        });
        updateFormSettingsMutation.mutate({
            id: id as string,
            workspace_id: workspaceId as string,
            body: {
                title: data.name,
                settings: {
                    accept_responses: data.pauseForm,
                    show_progress_bar: data.showProgressBar,
                },
            },
        });
        setOpenSettingDialog(false);
    };

    const handleSettingsPopupOpen = (e) => {
        e.preventDefault();
        setOpenSettingDialog(true);
    };

    return (
        <Dialog
            open={openSettingDialog}
            onOpenChange={() => setOpenSettingDialog(false)}
        >
            <Button
                variant={"ghost"}
                className=" bg-[#F1F2F4] rounded-md h-9 px-2.5 py-2.5 "
                onClick={handleSettingsPopupOpen}
            >
                <Settings size={16} />
            </Button>

            <DialogContent className="sm:w-[840px] max-w-[840px] max-h-[600px] w-full p-0 gap-0 top-[50%]">
                <DialogHeader className="p-3 h-[61px] ">
                    <DialogTitle className="flex gap-1 items-center">
                        <Settings size={13} />
                        <span className=" text-[14px]"> Form Settings</span>
                    </DialogTitle>
                    <DialogDescription className=" text-[12px]">
                        Customize the form's name, submissions, etc...
                    </DialogDescription>
                </DialogHeader>
                <div className="flex flex-col items-center h-full">
                    <div className="flex w-full border-b border-b-[#F1F2F4] h-[478px]">
                        <div className="w-[204px] flex flex-col gap-1 h-full p-3 border-r border-t border-t-[#F1F2F4] border-r-[#F1F2F4]">
                            {tabTypes?.map((tabType) => (
                                <div
                                    className={clsx(
                                        "flex w-full h-8 gap-1.5 items-center hover:cursor-pointer px-[9px] rounded-[8px]",
                                        tab === tabType.value
                                            ? "bg-[#F1F2F4] text-[#191B33]"
                                            : "text-[#626F86]",
                                    )}
                                    key={tabType.value}
                                    onClick={() => setTab(tabType.value)}
                                >
                                    {tabType.icon}

                                    <span className=" text-[12px] font-normal text-primary">
                                        {tabType.label}
                                    </span>
                                </div>
                            ))}
                        </div>
                        {tab === "general" && (
                            <form className="w-full space-y-4 border-t h-full border-t-[#F1F2F4] ">
                                <div className="flex flex-col gap-4 mb-4">
                                    {/* Form Name Input */}
                                    <div className="flex-col">
                                        <div className="flex flex-col gap-1 px-3 pt-3 mb-4">
                                            <Label>Form Name</Label>
                                            <Label className="text-xs font-normal text-[#44546F]">
                                                This name is for your internal
                                                use only
                                            </Label>
                                            <Controller
                                                name="name"
                                                control={control}
                                                render={({ field }) => (
                                                    <Input
                                                        {...field}
                                                        className="mt-1 focus:border-[#A1A1AA] border-[#E4E4E7] focus-visible:ring-offset-0 h-9 focus-visible:outline-none focus-visible:ring-0"
                                                        placeholder="Form Name"
                                                        autoFocus
                                                    />
                                                )}
                                            />
                                            {errors.name && (
                                                <p className="mt-1 text-xs text-red-500">
                                                    {errors.name.message}
                                                </p>
                                            )}
                                        </div>
                                        <Separator />
                                        {/* Pause Form Switch */}
                                        <div className="flex justify-between items-center px-3 mb-4">
                                            <div className="flex flex-col gap-1 mt-[16px]">
                                                <Label>Accept Responses</Label>
                                                <Label className="text-xs font-normal text-[#44546F]">
                                                    When turned off, people will
                                                    not be able to submit your
                                                    form
                                                </Label>
                                            </div>
                                            <Controller
                                                name="pauseForm"
                                                control={control}
                                                render={({
                                                    field: { value, onChange },
                                                }) => (
                                                    <Switch
                                                        checked={value}
                                                        onCheckedChange={
                                                            onChange
                                                        }
                                                    />
                                                )}
                                            />
                                        </div>
                                        <Separator />
                                        {/* Progress Bar Visibility Switch */}
                                        <div className="flex justify-between items-center px-3 mb-4">
                                            <div className="flex flex-col gap-1 mt-[16px]">
                                                <Label>
                                                    Progress Bar Visibility
                                                </Label>
                                                <Label className="text-xs font-normal text-[#44546F]">
                                                    Show or hide the form
                                                    progress bar
                                                </Label>
                                            </div>
                                            <Controller
                                                name="showProgressBar"
                                                control={control}
                                                render={({
                                                    field: { value, onChange },
                                                }) => (
                                                    <Switch
                                                        checked={value}
                                                        onCheckedChange={
                                                            onChange
                                                        }
                                                    />
                                                )}
                                            />
                                        </div>

                                        <Separator />
                                    </div>
                                </div>
                            </form>
                        )}
                        {tab === "integrations" && <FormIntegrations />}
                    </div>
                    {tab === "general" && (
                        <div className=" flex w-full justify-end items-center pr-2 h-[61px]">
                            <DialogFooter className="flex gap-3">
                                <DialogClose
                                    className={clsx(
                                        "text-[#F44336] text-xs font-normal hover:text-[#F44336]",
                                        !isDirty && " hidden",
                                    )}
                                >
                                    Discard
                                </DialogClose>

                                <Button
                                    className={clsx(
                                        " text-white hover:text-white hover:bg-[#191B33] bg-[#191B33] rounded-[8px] h-9 w-[102px] font-normal text-xs",
                                        !isDirty &&
                                            "bg-[#F1F2F4] text-[#626F86] hover:bg-[#E4E4E7]",
                                    )}
                                    onClick={handleSubmit(onSubmit)}
                                    disabled={!isDirty}
                                >
                                    {isLoading ? (
                                        <Loader className="animate-spin" />
                                    ) : (
                                        "Save Changes"
                                    )}
                                </Button>
                            </DialogFooter>
                        </div>
                    )}
                    {tab === "integrations" && (
                        <div className=" flex w-full justify-end items-center pr-2 h-[61px]"></div>
                    )}
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default FormSettingsModal;

const FormIntegrations = () => {
    return (
        <div className="w-full space-y-4 border-t h-full border-t-[#F1F2F4] ">
            <div className="flex flex-col gap-4 mb-4">
                <div className="flex-col">
                    <div className="flex flex-col gap-1 px-3 pt-3 mb-4">
                        <Label>Form Integrations</Label>
                        <Label className="text-xs font-normal text-[#44546F]">
                            Connect Buildform to your favorite apps
                        </Label>
                    </div>
                    <Separator />
                </div>
                {/* GoogleSheet */}
                <div className="flex-col">
                    <div className="flex justify-between items-center px-4">
                        <div className="flex items-center">
                            <img
                                src="/google_sheet.png"
                                alt="google_sheet"
                                className="w-[29.5px] -mt-3 "
                            />

                            <div className="flex flex-col gap-1 px-3 pt-3 mb-4">
                                <Label>Google Sheets</Label>
                                <Label className="text-xs font-normal text-[#44546F]">
                                    Send your data straight to Google Sheets.
                                    Automatically syncs as results <br /> come
                                    in.
                                </Label>
                            </div>
                        </div>
                        <Button
                            disabled
                            className="bg-[#191B33] text-white rounded-[8px] h-9 w-[102px] font-normal text-xs"
                        >
                            Coming Soon
                        </Button>
                    </div>
                    <Separator />
                </div>
                <div className="flex-col">
                    <div className="flex justify-between items-center px-4">
                        <div className="flex items-center">
                            <img
                                src="/zaiper.png"
                                alt="zaiper"
                                className="w-[29.5px] -mt-3 "
                            />

                            <div className="flex flex-col gap-1 px-3 pt-3 mb-4">
                                <Label>Zapier</Label>
                                <Label className="text-xs font-normal text-[#44546F]">
                                    Our integration with Zapier allows you to
                                    seamlessly transfer data collected on
                                    <br /> Buildform to over 2,000 services.
                                </Label>
                            </div>
                        </div>
                        <Button
                            disabled
                            className="bg-[#191B33] text-white rounded-[8px] h-9 w-[102px] font-normal text-xs"
                        >
                            Coming Soon
                        </Button>
                    </div>
                    <Separator />
                </div>
                <div className="flex-col">
                    <div className="flex justify-between items-center px-4">
                        <div className="flex items-center">
                            <img
                                src="/hubspot.png"
                                alt="hubspot"
                                className="w-[29.5px] -mt-3 "
                            />

                            <div className="flex flex-col gap-1 px-3 pt-3 mb-4">
                                <Label>HubSpot</Label>
                                <Label className="text-xs font-normal text-[#44546F]">
                                    Send contact, company, or deal info to
                                    HubSpot to quickly follow up on <br /> new
                                    leads or update existing details to your
                                    free HubSpot account.
                                </Label>
                            </div>
                        </div>
                        <Button
                            disabled
                            className="bg-[#191B33] text-white rounded-[8px] h-9 w-[102px] font-normal text-xs"
                        >
                            Coming Soon
                        </Button>
                    </div>
                    <Separator />
                </div>
            </div>
        </div>
    );
};
