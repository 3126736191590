import React from "react";

import clsx from "clsx";

import { motion } from "framer-motion";

import Email from "./Email.tsx";
import Legal from "./Legal.tsx";
import Country from "./Country.tsx";
import Dropdown from "./Dropdown.tsx";
import Statement from "./Statement.tsx";
import ShortText from "./ShortText.tsx";
import ContactInfo from "./ContactInfo.tsx";
import PhoneNumber from "./PhoneNumber.tsx";
import MultipleChoice from "./MultipleChoice.tsx";
import AiStatement from "./AiStatement.tsx";
import FileUpload from "./FileUpload.tsx";
import RedirectUrl from "./RedirectUrl.tsx";
import Rating from "./Rating.tsx";

import { useFormEditorContext } from "../../../Contexts/FormEditorContext.jsx";

import { QUESTION_TYPES } from "../../../Constants/questionTypes.js";
import { questionAnimation } from "../../../Constants/animations";

function QuestionScreen() {
    const { selectedField, editorField, device } = useFormEditorContext();

    const type = selectedField?.type;

    const attachment = editorField?.attachment;
    const hasAttachment = !!attachment?.link;
    const attachmentPlacement =
        (hasAttachment && attachment?.placement) || "right";

    let Component: any;

    if (type === QUESTION_TYPES.SHORT_TEXT) Component = <ShortText />;

    if (type === QUESTION_TYPES.EMAIL) Component = <Email />;

    if (type === QUESTION_TYPES.MULTIPLE_CHOICE) Component = <MultipleChoice />;

    if (type === QUESTION_TYPES.CONTACT_INFORMATION)
        Component = <ContactInfo />;

    if (type === QUESTION_TYPES.PHONE_NUMBER) Component = <PhoneNumber />;

    if (type === QUESTION_TYPES.DROPDOWN) Component = <Dropdown />;

    if (type === QUESTION_TYPES.COUNTRY) Component = <Country />;

    if (type === QUESTION_TYPES.LEGAL) Component = <Legal />;

    if (type === QUESTION_TYPES.SECTION) Component = <Statement />; // NOTE: Changed to QUESTION_TYPES.SECTION until the statement type is implemented in the backend.

    if (type === QUESTION_TYPES.AI_STATEMENT) Component = <AiStatement />;

    if (type === QUESTION_TYPES.FILE_UPLOAD) Component = <FileUpload />;

    if (type === QUESTION_TYPES.REDIRECT) Component = <RedirectUrl />;

    if (type === QUESTION_TYPES.RATING) Component = <Rating />;

    return (
        <div
            className={clsx(
                "flex h-full items-center justify-center overflow-y-auto",
                {
                    "w-full": device === "desktop",
                    "w-full p-5": device === "mobile",
                    "flex flex-col gap-6":
                        device === "mobile" &&
                        hasAttachment &&
                        attachmentPlacement === "right",
                    "flex flex-col-reverse gap-6":
                        device === "mobile" &&
                        hasAttachment &&
                        attachmentPlacement === "left",
                    "flex flex-row items-center gap-[60px]":
                        device === "desktop" &&
                        hasAttachment &&
                        attachmentPlacement === "right",
                    "flex flex-row-reverse items-center gap-[60px]":
                        device === "desktop" &&
                        hasAttachment &&
                        attachmentPlacement === "left",
                },
            )}
        >
            {Component}
            {hasAttachment && (
                <motion.img
                    {...questionAnimation}
                    src={attachment?.href || attachment?.link}
                    style={{
                        objectFit: "contain",
                        radius: "12px",
                        borderRadius: "12px",
                        width: device === "mobile" ? "322px" : "413px",
                        display:
                            device === "mobile" &&
                            type === QUESTION_TYPES.AI_STATEMENT
                                ? "none"
                                : undefined,
                    }}
                />
            )}
        </div>
    );
}

export default QuestionScreen;
