import React, { useState } from "react";

import { Input } from "../../ui/input.tsx";
import { ResizableTextarea } from "../../ui/resizable-textarea.tsx";
import FormButton from "../FormButton.tsx";

import useStyles from "../../../Hooks/useStyles.ts";

import { useFormEditorContext } from "../../../Contexts/FormEditorContext.jsx";

import { cn } from "../../../lib/utils.js";
import clsx from "clsx";

function Rating() {
    const { getStyles } = useStyles();
    const { editorField, setEditorField, theme, setFields, device } =
        useFormEditorContext();

    const updateField = (fieldId, key, subkey, value) => {
        setFields((prevFields) => {
            const updatedFields = prevFields.map((field) => {
                if (field.id === fieldId) {
                    if (subkey) {
                        return {
                            ...field,
                            [key]: {
                                ...field[key],
                                [subkey]: value,
                            },
                        };
                    } else {
                        return {
                            ...field,
                            [key]: value,
                        };
                    }
                }
                return field;
            });
            return updatedFields;
        });

        setEditorField((prev) => {
            if (subkey) {
                return {
                    ...prev,
                    [key]: {
                        ...prev[key],
                        [subkey]: value,
                    },
                };
            } else {
                return {
                    ...prev,
                    [key]: value,
                };
            }
        });
    };

    return (
        <div className="flex flex-col w-full max-w-[606px]">
            <ResizableTextarea
                placeholder="Add question title"
                value={editorField?.title || ""}
                onChange={(value) =>
                    updateField(editorField?.id, "title", null, value)
                }
                style={{
                    color: theme?.text_color,
                    fontSize: getStyles("h2", theme?.text_size, device)
                        .fontSize,
                    fontWeight: getStyles("h2", theme?.text_size, device)
                        .fontWeight,
                    fontFamily: `${theme?.font_family?.family}, ${theme?.font_family?.category}`,
                }}
            />
            <ResizableTextarea
                placeholder="Add question description (Optional)"
                value={editorField?.properties?.description || ""}
                onChange={(value) =>
                    updateField(
                        editorField?.id,
                        "properties",
                        "description",
                        value,
                    )
                }
                style={{
                    color: theme?.text_color,
                    fontSize: getStyles("h4", theme?.text_size, device)
                        .fontSize,
                    fontWeight: getStyles("h4", theme?.text_size, device)
                        .fontWeight,
                    fontFamily: `${theme?.font_family?.family}, ${theme?.font_family?.category}`,
                }}
                className="mt-2"
            />
            <RatingSection device={device} />

            <FormButton className="mt-3" />
        </div>
    );
}

export default Rating;

const RatingSection = ({ device }) => {
    const { editorField, setFields, setEditorField } = useFormEditorContext();

    const updateField = (fieldId, key, subkey, value) => {
        setFields((prevFields) => {
            const updatedFields = prevFields.map((field) => {
                if (field.id === fieldId) {
                    if (subkey) {
                        return {
                            ...field,
                            [key]: {
                                ...field[key],
                                [subkey]: value,
                            },
                        };
                    } else {
                        return {
                            ...field,
                            [key]: value,
                        };
                    }
                }
                return field;
            });
            return updatedFields;
        });

        setEditorField((prev) => {
            if (subkey) {
                return {
                    ...prev,
                    [key]: {
                        ...prev[key],
                        [subkey]: value,
                    },
                };
            } else {
                return {
                    ...prev,
                    [key]: value,
                };
            }
        });
    };

    const { labels, start_at, end_at } = editorField?.properties;

    const middleIndex = Math.floor((start_at + end_at) / 2);
    const startIndex = start_at;
    const endIndex = end_at;

    return (
        <div
            className={clsx(
                "flex flex-col gap-y-3 ",
                device === "mobile" ? "flex-col-reverse mt-2" : "mt-4",
            )}
        >
            <div
                className={clsx(
                    "gap-1.5 w-full",
                    device === "desktop"
                        ? ` flex flex-row items-center flex-nowrap `
                        : "flex flex-row flex-wrap ",
                )}
            >
                {Array.from({ length: end_at - start_at + 1 }, (_, index) => (
                    <Item key={index} number={start_at + index} />
                ))}
            </div>
            <div
                className={clsx(
                    "flex w-full",
                    device === "mobile"
                        ? " flex-col gap-y-2"
                        : "justify-between",
                )}
            >
                <div className="flex gap-x-2 items-center">
                    <p
                        className={clsx(
                            "text-sm text-gray-500 min-w-[33px]",
                            device === "desktop" && "hidden",
                        )}
                    >
                        {startIndex} {"->"}
                    </p>
                    <Input
                        value={labels?.left}
                        onChange={(e) =>
                            updateField(
                                editorField?.id,
                                "properties",
                                "labels",
                                {
                                    ...labels,
                                    left: e.target.value,
                                },
                            )
                        }
                        className={cn(
                            `p-0 border-none  border-x-0 border-t-0 rounded-none text-left bg-transparent focus-visible:ring-0 placeholder:text-[#626F86] placeholder:text-base focus-visible:ring-offset-0 outline-none overflow-hidden`,
                            device === "mobile" ? "w-[108px]" : "w-[125px]",
                        )}
                        placeholder="Enter your Label"
                    />
                </div>
                <div className="flex gap-x-2 items-center">
                    <p
                        className={clsx(
                            "text-sm text-gray-500 min-w-[33px]",
                            device === "desktop" && "hidden",
                        )}
                    >
                        {middleIndex} {"->"}
                    </p>
                    <Input
                        value={labels?.center}
                        onChange={(e) =>
                            updateField(
                                editorField?.id,
                                "properties",
                                "labels",
                                {
                                    ...labels,
                                    center: e.target.value,
                                },
                            )
                        }
                        className={cn(
                            `p-0 border-none  border-x-0 border-t-0 rounded-none text-left bg-transparent focus-visible:ring-0 placeholder:text-[#626F86] placeholder:text-base focus-visible:ring-offset-0 outline-none overflow-hidden`,
                            device === "mobile" ? "w-[108px]" : "w-[125px]",
                        )}
                        placeholder="Enter your Label"
                    />
                </div>
                <div className="flex gap-x-2 items-center">
                    <p
                        className={clsx(
                            "text-sm text-gray-500 min-w-[33px]",
                            device === "desktop" && "hidden",
                        )}
                    >
                        {endIndex} {"->"}
                    </p>
                    <Input
                        value={labels?.right}
                        onChange={(e) =>
                            updateField(
                                editorField?.id,
                                "properties",
                                "labels",
                                {
                                    ...labels,
                                    right: e.target.value,
                                },
                            )
                        }
                        className={cn(
                            `p-0 border-none  border-x-0 border-t-0 rounded-none text-left bg-transparent focus-visible:ring-0 placeholder:text-[#626F86] placeholder:text-base focus-visible:ring-offset-0 outline-none overflow-hidden`,
                            device === "mobile" ? "w-[108px]" : "w-[125px]",
                        )}
                        placeholder="Enter your Label"
                    />
                </div>
            </div>
        </div>
    );
};

const Item = ({ number }) => {
    const { getStyles } = useStyles();
    const { device, theme } = useFormEditorContext();
    return (
        <div
            className={clsx(
                " w-full flex py-[12px] justify-center items-center border  cursor-pointer border-[#DCDFE4] rounded-[8px]",
                device === "mobile" ? "max-w-[54px]" : "",
            )}
        >
            <p
                style={{
                    color: theme?.text_color,
                    fontSize: getStyles("h4", theme?.text_size, device)
                        .fontSize,
                    fontWeight: getStyles("h4", theme?.text_size, device)
                        .fontWeight,
                }}
            >
                {number}
            </p>
        </div>
    );
};
