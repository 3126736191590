import React from "react";

import clsx from "clsx";

import { motion } from "framer-motion";

import { ResizableTextarea } from "../ui/resizable-textarea.tsx";
import FormButton from "./FormButton.tsx";

import useStyles from "../../Hooks/useStyles.ts";

import { useFormEditorContext } from "../../Contexts/FormEditorContext.jsx";

import { questionAnimation } from "../../Constants/animations";

function WelcomeScreen() {
    const { getStyles } = useStyles();
    const { welcomeScreen, setWelcomeScreen, theme, setChanged, device } =
        useFormEditorContext();

    const attachment = welcomeScreen?.layout?.attachment?.link;
    const hasAttachment = attachment?.link;
    const attachmentPlacement =
        (hasAttachment && welcomeScreen?.layout?.placement) || "right";

    const updateWelcomeScreen = (key, value) => {
        setWelcomeScreen({
            ...welcomeScreen,
            [key]: value,
        });
        setChanged(true);
    };

    return (
        <div
            className={clsx(
                "flex h-full items-center justify-center overflow-y-auto",
                {
                    "w-full": device === "desktop",
                    "w-full p-5": device === "mobile",
                    "flex flex-col gap-6":
                        device === "mobile" &&
                        hasAttachment &&
                        attachmentPlacement === "right",
                    "flex flex-col-reverse gap-6":
                        device === "mobile" &&
                        hasAttachment &&
                        attachmentPlacement === "left",
                    "flex flex-row items-center gap-[60px]":
                        device === "desktop" &&
                        hasAttachment &&
                        attachmentPlacement === "right",
                    "flex flex-row-reverse items-center gap-[60px]":
                        device === "desktop" &&
                        hasAttachment &&
                        attachmentPlacement === "left",
                },
            )}
        >
            <motion.div
                initial={{ opacity: 0, translateY: 400 }}
                animate={{ opacity: 1, translateY: 0 }}
                exit={{ opacity: 0, translateY: 40 }}
                transition={{ delay: 0, duration: 0.5 }}
                className="w-full flex flex-col items-center justify-center max-w-[606px]"
            >
                <div className="flex flex-col w-full">
                    <ResizableTextarea
                        placeholder="Welcome Screen Title"
                        value={welcomeScreen?.title || ""}
                        onChange={(value) =>
                            updateWelcomeScreen("title", value)
                        }
                        style={{
                            color: theme?.text_color,
                            fontSize: getStyles("h1", theme?.text_size)
                                ?.fontSize,
                            fontWeight: getStyles("h1", theme?.text_size)
                                ?.fontWeight,
                            fontFamily: `${theme?.font_family?.family}, ${theme?.font_family?.category}`,
                        }}
                        className="py-2 leading-tight"
                    />
                    <ResizableTextarea
                        placeholder="Add Welcome Screen Description (optional)"
                        value={welcomeScreen.description || ""}
                        onChange={(value) =>
                            updateWelcomeScreen("description", value)
                        }
                        style={{
                            color: theme?.text_color,
                            fontSize: getStyles("h3", theme?.text_size, device)
                                .fontSize,
                            fontWeight: "400",
                            fontFamily: `${theme?.font_family?.family}, ${theme?.font_family?.category}`,
                        }}
                    />
                    {device === "desktop" && (
                        <FormButton className="mt-6">Start</FormButton>
                    )}
                </div>
            </motion.div>

            {attachment?.link && (
                <motion.img
                    {...questionAnimation}
                    src={welcomeScreen?.layout?.attachment?.link}
                    alt="attachment"
                    style={{
                        objectFit: "contain",
                        radius: "12px",
                        borderRadius: "12px",
                        width: device === "mobile" ? "322px" : "413px",
                        // height: device === "mobile" ? "200px" : "616px",
                    }}
                />
            )}
        </div>
    );
}

export default WelcomeScreen;
