import React from "react";

import { ResizableTextarea } from "../ui/resizable-textarea.tsx";

import useStyles from "../../Hooks/useStyles.ts";

import { useFormEditorContext } from "../../Contexts/FormEditorContext.jsx";
import { cn } from "../../lib/utils.js";
import { Button } from "../ui/button.tsx";

function EndScreen() {
    const { getStyles } = useStyles();
    const { endScreen, setEndScreen, theme, setChanged, device } =
        useFormEditorContext();

    const updateEndScreen = (key, value) => {
        setEndScreen({
            ...endScreen,
            [key]: value,
        });
        setChanged(true);
    };

    const getDeviceStyle = () => {
        if (device === "mobile") {
            return "p-5";
        }
        return "";
    };

    const deviceStyle = getDeviceStyle();

    return (
        <div
            className={cn(
                "flex flex-col gap-0 w-full max-w-[606px]",
                deviceStyle,
            )}
        >
            <ResizableTextarea
                placeholder="End Screen Title"
                value={endScreen?.title}
                onChange={(value) => updateEndScreen("title", value)}
                style={{
                    color: theme?.text_color,
                    fontSize: getStyles("h1", theme?.text_size).fontSize,
                    fontWeight: getStyles("h1", theme?.text_size).fontWeight,
                    fontFamily: `${theme?.font_family?.family}, ${theme?.font_family?.category}`,
                }}
                className="py-2 leading-tight"
            />
            <ResizableTextarea
                placeholder="Add End Screen Description (optional)"
                value={endScreen?.description}
                onChange={(value) => updateEndScreen("description", value)}
                style={{
                    color: theme?.text_color,
                    fontSize: getStyles("h3", theme?.text_size).fontSize,
                    fontWeight: "400",
                    fontFamily: `${theme?.font_family?.family}, ${theme?.font_family?.category}`,
                }}
            />
            <Button
                className="px-4 py-4 mt-6 h-9 w-fit"
                style={{
                    backgroundColor: theme?.button_color,
                    color: theme?.button_text_color,
                    borderRadius: theme?.rounded_corners,
                    fontFamily: `${theme?.font_family?.family}, ${theme?.font_family?.category}`,
                    fontWeight: theme?.is_bold ? "600" : "400",
                    fontSize: getStyles("button", theme?.button_size).fontSize,
                    height: getStyles("button", theme?.button_size).height,
                }}
            >
                Build your own Buildform
            </Button>
        </div>
    );
}

export default EndScreen;
