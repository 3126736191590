import React from "react";
import CountUp from "react-countup";

const DURATION = 2;

type ViewCardProps = {
    title: string;
    icon: JSX.Element;
    count?: number;
    percentage?: boolean;
    min?: number;
    sec?: number;
    isTime?: boolean;
};
const ViewCard = ({
    title,
    count = 0,
    icon,
    percentage,
    isTime,
    min = 0,
    sec = 0,
}: ViewCardProps) => {
    return (
        <div className=" border border-[#F1F2F4] h-[115px] flex flex-col rounded-[8px] px-6 py-2">
            <div className="flex items-center justify-between h-[52px] ">
                <span className="text-sm font-normal  text-[#44546F]">
                    {title}
                </span>
                <div className=" text-[#8590A2] size-4">{icon}</div>
            </div>
            <div className=" h-[63px]">
                <span className="text-[#191B33] text-[32px] font-semibold">
                    {!isTime && (
                        <>
                            <CountUp end={count} duration={DURATION} />
                            {percentage && "%"}
                        </>
                    )}
                    {isTime && (
                        <>
                            {min < 10 && (
                                <CountUp end={0} duration={DURATION} />
                            )}
                            <CountUp end={min} duration={DURATION} />:
                            {sec < 10 && (
                                <CountUp end={0} duration={DURATION} />
                            )}
                            <CountUp end={sec} duration={DURATION} />
                        </>
                    )}
                </span>
            </div>
        </div>
    );
};

export default ViewCard;
