import React from "react";

import { ToggleGroup, ToggleGroupItem } from "../../ui/toggle-group.tsx";
import { ResizableTextarea } from "../../ui/resizable-textarea.tsx";
import FormButton from "../FormButton.tsx";

import useStyles from "../../../Hooks/useStyles.ts";

import { useFormEditorContext } from "../../../Contexts/FormEditorContext.jsx";

import { CheckIcon, XIcon } from "lucide-react";

import Color from "color";

export default function Legal() {
    const { getStyles } = useStyles();
    const { editorField, setEditorField, theme, setFields, device } =
        useFormEditorContext();

    const updateField = (fieldId, key, subkey, value) => {
        setFields((prevFields) => {
            const updatedFields = prevFields.map((field) => {
                if (field.id === fieldId) {
                    if (subkey) {
                        return {
                            ...field,
                            [key]: {
                                ...field[key],
                                [subkey]: value,
                            },
                        };
                    } else {
                        return {
                            ...field,
                            [key]: value,
                        };
                    }
                }
                return field;
            });
            return updatedFields;
        });

        setEditorField((prev) => {
            if (subkey) {
                return {
                    ...prev,
                    [key]: {
                        ...prev[key],
                        [subkey]: value,
                    },
                };
            } else {
                return {
                    ...prev,
                    [key]: value,
                };
            }
        });
    };

    return (
        <div className="flex flex-col gap-0 w-full max-w-[606px]">
            <ResizableTextarea
                placeholder="Add question title"
                value={editorField?.title || ""}
                onChange={(value) =>
                    updateField(editorField?.id, "title", null, value)
                }
                style={{
                    color: theme?.text_color,
                    fontSize: getStyles("h2", theme?.text_size, device)
                        .fontSize,
                    fontWeight: getStyles("h2", theme?.text_size, device)
                        .fontWeight,
                    fontFamily: `${theme?.font_family?.family}, ${theme?.font_family?.category}`,
                }}
            />
            <ResizableTextarea
                placeholder="Add question description (Optional)"
                value={editorField?.properties?.description || ""}
                onChange={(value) =>
                    updateField(
                        editorField?.id,
                        "properties",
                        "description",
                        value,
                    )
                }
                style={{
                    color: theme?.text_color,
                    fontSize: getStyles("h4", theme?.text_size, device)
                        .fontSize,
                    fontWeight: getStyles("h4", theme?.text_size, device)
                        .fontWeight,
                    fontFamily: `${theme?.font_family?.family}, ${theme?.font_family?.category}`,
                }}
                className="mt-2"
            />

            <ToggleGroup
                variant="outline"
                type="single"
                className="flex flex-row gap-2 justify-start items-start p-0 mt-6"
            >
                <ToggleGroupItem
                    value="AGREE"
                    aria-label={`Toggle Agree`}
                    className="p-0 rounded-lg border-none hover:bg-inherit"
                >
                    <ToggleButton type="AGREE" />
                </ToggleGroupItem>
                <ToggleGroupItem
                    value="DISAGREE"
                    aria-label={`Toggle Disagree`}
                    className="p-0 rounded-lg border-none hover:bg-inherit"
                >
                    <ToggleButton type="DISAGREE" />
                </ToggleGroupItem>
            </ToggleGroup>
            <FormButton className="mt-3" />
        </div>
    );
}

type ToggleButtonProps = {
    type: "AGREE" | "DISAGREE";
};

function ToggleButton({ type }: ToggleButtonProps) {
    const { theme } = useFormEditorContext();
    const text = type === "AGREE" ? "Agree" : "Disagree";
    const Icon = type === "AGREE" ? CheckIcon : XIcon;
    const color = new Color(theme?.answer_text_color);
    return (
        <div
            className={`flex flex-row items-center  border border-[#DCDFE4] rounded-lg p-2 max-w-full min-h-[40px] w-[150px] h-fit`}
            style={{
                borderRadius: theme?.rounded_corners || 4,
                borderColor: color.hex() + "50",
                borderWidth: "1px",
                backgroundColor: color.hex() + "10", // Changing the opacity of the color
            }}
        >
            <div
                className="flex items-center justify-center bg-[#FFFFFF] rounded-lg text-center w-6 h-6"
                style={{
                    borderRadius: Number(theme?.rounded_corners || 4) - 2,
                    borderColor: color.hex() + "50",
                    borderWidth: "1px",
                    color: theme?.answer_text_color,
                    backgroundColor: "#FFFFFF",
                }}
            >
                <Icon
                    style={{
                        color: theme?.answer_text_color,
                        fontWeight: theme?.is_bold ? "bold" : "normal",
                    }}
                />
            </div>
            <p
                style={{
                    color: theme?.answer_text_color,
                    fontWeight: theme?.is_bold ? "bold" : "normal",
                    fontFamily: `${theme?.font_family?.family}, ${theme?.font_family?.category}`,
                }}
                className="px-3"
            >
                {text}
            </p>
        </div>
    );
}
