import React, { useEffect, useState } from "react";

import {
    ColumnDef,
    ColumnFiltersState,
    flexRender,
    getCoreRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    PaginationState,
    SortingState,
    useReactTable,
} from "@tanstack/react-table";

import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "../ui/table.tsx";
import { DataTableToolbar } from "./DataTableToolbar.tsx";
import SkeletonTable from "./Skeleton-Table.tsx";

import { DEFAULT_PAGINATION } from "../../Api/usePagination.ts";

import { useFormInsightsContext } from "../../Contexts/FormInsightsContext.tsx";

import { useInView } from "react-intersection-observer";

import { cn } from "../../lib/utils.js";

type Pagination = {
    pageIndex: number;
    pageSize: number;
};

interface DataTableProps<TData, TValue> {
    columns: ColumnDef<TData, TValue>[];
    data: TData[];
    isLoaded?: boolean;
    total: number;
    onPaginationChange: (pagination: PaginationState) => void;
    onSortingChange: (sorting: SortingState) => void;
    onScrollEnd: () => void;
    onRowClick: (id: string) => void;
}

export function DataTable<TData, TValue>({
    columns,
    data,
    isLoaded,
    total,
    onRowClick,
    onScrollEnd,
    onSortingChange,
    onPaginationChange,
}: DataTableProps<TData, TValue>) {
    const { selection, setSelectedFields, activeRow, setActiveRow } =
        useFormInsightsContext();
    const [sorting, setSorting] = useState<SortingState>([]);
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 0, //initial page index
        pageSize: DEFAULT_PAGINATION.size as number, //default page size
    });
    const [rowSelection, setRowSelection] = React.useState({});
    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        onSortingChange: setSorting,
        getSortedRowModel: getSortedRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        onColumnFiltersChange: setColumnFilters,
        onRowSelectionChange: setRowSelection,
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        state: {
            sorting,
            columnFilters,
            pagination,
            rowSelection,
        },
        rowCount: total,
        manualPagination: true,
        autoResetPageIndex: false, //turn off auto reset of pageIndex
        onPaginationChange: setPagination, //update the pagination state when internal APIs mutate the pagination state
    });

    useEffect(() => {
        const selectedRows = table
            .getFilteredSelectedRowModel()
            .rows.map((row) => ({
                // @ts-ignore
                id: row.original.id,
                // @ts-ignore
                type: row.original?.type,
                // @ts-ignore
                title: row.original?.title,
            }));
        setSelectedFields(selectedRows);
    }, [rowSelection]);

    useEffect(() => {
        if (selection === "all") {
            table.toggleAllRowsSelected();
        } else {
            table.setRowSelection({});
        }
    }, [selection]);

    const { ref } = useInView({
        triggerOnce: false,
        onChange: (inView) => {
            if (inView) {
                onScrollEnd();
            }
        },
    });

    return (
        <div className="relative mt-4 ">
            {/*  <DataTableToolbar
                table={table}
                className="bg-white top-3 left-3 right-3"
            /> */}
            {isLoaded ? (
                <div>
                    <div className="rounded-md border h-[calc(100vh-140px)] overflow-auto  top-14 left-3 right-3 bottom-0">
                        <Table className="h-fit w-fit">
                            <TableHeader>
                                {table.getHeaderGroups().map((headerGroup) => (
                                    <TableRow key={headerGroup.id}>
                                        {headerGroup.headers.map((header) => {
                                            return (
                                                <TableHead
                                                    key={header.id}
                                                    className="sticky top-0 text-xs bg-white border-r first:border-none last:border-r"
                                                >
                                                    {header.isPlaceholder
                                                        ? null
                                                        : flexRender(
                                                              header.column
                                                                  .columnDef
                                                                  .header,
                                                              header.getContext(),
                                                          )}
                                                </TableHead>
                                            );
                                        })}
                                    </TableRow>
                                ))}
                            </TableHeader>
                            <TableBody>
                                {table.getRowModel().rows?.length ? (
                                    table.getRowModel().rows.map((row) => (
                                        <TableRow
                                            key={row.id}
                                            data-state={
                                                row.getIsSelected() &&
                                                "selected"
                                            }
                                            className={cn(
                                                "h-[40px]",
                                                // @ts-ignore
                                                activeRow === row.original.id &&
                                                    "bg-purple-50",
                                            )}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                // @ts-ignore
                                                setActiveRow(row.original.id);
                                            }}
                                        >
                                            {row
                                                .getVisibleCells()
                                                .map((cell) => (
                                                    <TableCell
                                                        key={cell.id}
                                                        className="text-xs border-r first:border-none last:border-r"
                                                    >
                                                        {flexRender(
                                                            cell.column
                                                                .columnDef.cell,
                                                            cell.getContext(),
                                                        )}
                                                    </TableCell>
                                                ))}
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell
                                            colSpan={columns.length}
                                            className="h-24 text-center"
                                        >
                                            No results.
                                        </TableCell>
                                    </TableRow>
                                )}
                                {table.getRowModel().rows?.length > 0 && (
                                    <div
                                        ref={ref}
                                        style={{
                                            height: "20px",
                                            width: "100%",
                                        }}
                                    />
                                )}
                            </TableBody>
                        </Table>
                    </div>
                </div>
            ) : (
                <SkeletonTable className="bottom-0 top-14 left-3 right-3" />
            )}
        </div>
    );
}
