import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { useFormEditorContext } from "../../Contexts/FormEditorContext";
import { Separator } from "../../Components/ui/separator.tsx";
import { Input } from "../../Components/ui/input.tsx";
import { Label } from "../../Components/ui/label.tsx";
import { Switch } from "../../Components/ui/switch.tsx";
import { Button } from "../../Components/ui/button.tsx";
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "../ui/dialog.tsx";
import { useMutation } from "@tanstack/react-query";
import { useApi } from "../../Api/useApi.ts";
import { Settings } from "lucide-react";

// Zod schema for form validation
const formSettingsSchema = z.object({
    name: z.string().min(1, { message: "Form name cannot be empty" }),
    pauseForm: z.boolean(),
    showProgressBar: z.boolean(),
});

const FormSettingsSider = () => {
    const { setFormSettings, setName, name, formSettings, form } =
        useFormEditorContext();
    const api = useApi();
    const [isError, setIsError] = useState(false);

    const [openSettingDialog, setOpenSettingDialog] = useState(false);

    // Default values from existing form settings
    const defaultValues = {
        name: name,
        pauseForm: formSettings?.accept_responses,
        showProgressBar: formSettings?.show_progress_bar,
    };
    // React Hook Form setup with Zod validation
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: zodResolver(formSettingsSchema),
        defaultValues,
        mode: "onChange",
        values: defaultValues, // Default values from existing form settings
    });

    const updateFormSettingsMutation = useMutation({
        mutationFn: ({ id, workspace_id, title, body }) =>
            api.forms.formSettingsUpdate(id, workspace_id, title, body),
        onSuccess: (data) => {
            console.log("Form settings updated successfully", data);
            setOpenSettingDialog(false);
        },

        onError: (error) => {
            console.error("Error details:", error);
            setIsError(true);
        },
    });

    const onSubmit = (data) => {
        setName(data.name);
        setFormSettings((prev) => {
            return {
                ...prev,
                accept_responses: data.pauseForm,
                show_progress_bar: data.showProgressBar,
            };
        });

        updateFormSettingsMutation.mutate({
            id: form?.id,
            workspace_id: form?.workspace_id,
            body: {
                title: data.name,
                settings: {
                    accept_responses: data.pauseForm,
                    show_progress_bar: data.showProgressBar,
                },
            },
        });
    };

    const handleSettingsPopupOpen = (e) => {
        e.preventDefault();
        setOpenSettingDialog(true);
    };

    return (
        <Dialog
            open={openSettingDialog}
            onOpenChange={() => setOpenSettingDialog(false)}
        >
            <Button
                variant={"icon"}
                className=" bg-[#F1F2F4] rounded-md h-9 px-2.5 py-2.5 "
                onClick={handleSettingsPopupOpen}
            >
                <Settings size={16} color="#191B33" />
            </Button>

            <DialogContent className="sm:w-[480px] p-3 top-[50%]">
                <DialogHeader className="space-y-1 ">
                    <DialogTitle className="flex items-center gap-1 ">
                        <Settings size={13} />

                        <span className=" text-[14px]"> Form Settings</span>
                    </DialogTitle>
                    <DialogDescription className=" text-[12px]">
                        Customize the form's name, submissions, etc...
                    </DialogDescription>
                </DialogHeader>

                <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
                    <div className="flex flex-col gap-4 mb-4">
                        {/* Form Name Input */}
                        <div className="flex-col">
                            <Separator className="absolute left-0 w-full" />
                            <div className="flex flex-col mb-4 gap-1 mt-[16px]">
                                <Label>Form Name</Label>
                                <Label className="text-xs font-normal text-[#44546F]">
                                    This name is for your internal use only
                                </Label>
                                <Controller
                                    name="name"
                                    control={control}
                                    render={({ field }) => (
                                        <Input
                                            {...field}
                                            className="mt-1 focus:border-[#A1A1AA] border-[#E4E4E7] focus-visible:ring-offset-0 h-9 focus-visible:outline-none focus-visible:ring-0"
                                            placeholder="Form Name"
                                            autoFocus
                                        />
                                    )}
                                />
                                {errors.name && (
                                    <p className="mt-1 text-xs text-red-500">
                                        {errors.name.message}
                                    </p>
                                )}
                            </div>
                            <Separator className="absolute left-0 w-full" />

                            {/* Pause Form Switch */}
                            <div className="flex items-center justify-between mb-4">
                                <div className="flex flex-col gap-1 mt-[16px]">
                                    <Label>Accept Responses</Label>
                                    <Label className="text-xs font-normal text-[#44546F]">
                                        When turned off, people will not be able
                                        to submit your form
                                    </Label>
                                </div>
                                <Controller
                                    name="pauseForm"
                                    control={control}
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <Switch
                                            checked={value}
                                            onCheckedChange={onChange}
                                        />
                                    )}
                                />
                            </div>
                            <Separator className="absolute left-0 w-full" />

                            {/* Progress Bar Visibility Switch */}
                            <div className="flex items-center justify-between mb-4">
                                <div className="flex flex-col gap-1 mt-[16px]">
                                    <Label>Progress Bar Visibility</Label>
                                    <Label className="text-xs font-normal text-[#44546F]">
                                        Show or hide the form progress bar
                                    </Label>
                                </div>
                                <Controller
                                    name="showProgressBar"
                                    control={control}
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <Switch
                                            checked={value}
                                            onCheckedChange={onChange}
                                        />
                                    )}
                                />
                            </div>
                            <Separator className="absolute left-0 w-full" />
                        </div>
                    </div>

                    {/* Dialog Footer */}
                    <DialogFooter className="flex gap-3">
                        <DialogClose
                            variant="ghost"
                            className="text-[#F44336] text-xs font-normal hover:text-[#F44336]"
                        >
                            Discard
                        </DialogClose>

                        <Button
                            className={
                                " text-white hover:text-white hover:bg-[#191B33] bg-[#191B33] rounded-[8px] h-9 w-[102px] font-normal text-xs"
                            }
                            type="submit"
                        >
                            Save changes
                        </Button>
                    </DialogFooter>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default FormSettingsSider;
