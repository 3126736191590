import "./App.css";
import { FormView } from "./Views/FormView.tsx";
import { ConfigProvider, theme } from "antd";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ROUTES } from "./Constants/routes.js";
import { Provider } from "react-redux";
import { store, persistor } from "./Redux/store.jsx";
import { FormEditorProvider } from "./Contexts/FormEditorContext.jsx";
import AnimationLayout from "./Layouts/RouterAnimationLayout.jsx";
import { LoginView } from "./Views/Public/LoginView.jsx";
import { PersistGate } from "redux-persist/integration/react";
import { FormProvider } from "./Contexts/FormContext.jsx";
import DashboardLayout from "./Layouts/DashboardLayout.jsx";
import EditorLayout from "./Layouts/EditorLayout.jsx";
import RootLayout from "./Layouts/RootLayout.jsx";
import FormResultsLayout from "./Layouts/FormResultsLayout.tsx";
import FormResponsesView from "./Views/FormResponsesView";
import FormAnalyticsView from "./Views/FormAnalyticsView.tsx";
import SSOCallback from "./Layouts/sso-callback.tsx";
import { FormInsightsProvider } from "./Contexts/FormInsightsContext.tsx";
import FormAnalyticsLayout from "./Views/FormAnalyticsLayout.tsx";
// import NewEditorLayout from "./Layouts/NewEditorLayout.tsx";

const router = createBrowserRouter([
    {
        path: ROUTES.ROOT,
        element: <RootLayout />,
        children: [
            {
                path: ROUTES.DASHBOARD,
                element: (
                    <FormProvider>
                        <FormEditorProvider>
                            <DashboardLayout />
                        </FormEditorProvider>
                    </FormProvider>
                ),
            },
            {
                path: ROUTES.FORM_EDITOR,
                element: (
                    <FormProvider getDataFromLocal={true}>
                        <FormEditorProvider>
                            <EditorLayout />
                        </FormEditorProvider>
                    </FormProvider>
                ),
            },
            {
                path: ROUTES.LOGIN,
                element: (
                    <AnimationLayout>
                        <LoginView />
                    </AnimationLayout>
                ),
            },
            {
                path: ROUTES.SIGNUP,
                element: (
                    <AnimationLayout>
                        <LoginView isLogin={false} />
                    </AnimationLayout>
                ),
            },
            {
                path: ROUTES.SIGNUP_FALLBACK,
                element: (
                    <AnimationLayout>
                        <SSOCallback />
                    </AnimationLayout>
                ),
            },
        ],
    },
    {
        path: ROUTES.FORMS,
        element: (
            <FormProvider>
                <FormEditorProvider>
                    <FormView />
                </FormEditorProvider>
            </FormProvider>
        ),
    },
    {
        path: ROUTES.FORM_RESPONSES,
        element: (
            <FormInsightsProvider>
                <FormResultsLayout>
                    <FormResponsesView />
                </FormResultsLayout>
            </FormInsightsProvider>
        ),
    },
    {
        path: ROUTES.FORM_ANALYTICS,
        element: (
            <FormInsightsProvider>
                <FormAnalyticsLayout>
                    <FormAnalyticsView />
                </FormAnalyticsLayout>
            </FormInsightsProvider>
        ),
    },
]);

function App() {
    return (
        <div className="App">
            <Provider store={store}>
                <PersistGate persistor={persistor}>
                    <ConfigProvider
                        theme={{
                            algorithm: [theme.defaultAlgorithm],
                            token: {
                                colorBorder: "transparent",
                                // borderRadius: 20,
                            },
                            components: {
                                Typography: { titleMarginBottom: "0" },
                                Layout: {
                                    headerBg: "#f0f0f0",
                                },
                                Button: {
                                    boxShadow:
                                        "rgba(0, 0, 0, 0.1) 0px 3px 12px 0px",
                                },
                            },
                        }}
                    >
                        <RouterProvider router={router} />
                    </ConfigProvider>
                </PersistGate>
            </Provider>
        </div>
    );
}

export default App;
