"use client";
import React from "react";

import { Pie, PieChart, PieLabel, ResponsiveContainer } from "recharts";
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from "../ui/card.tsx";
import {
    ChartConfig,
    ChartContainer,
    ChartTooltip,
    ChartTooltipContent,
} from "../ui/chart.tsx";
import { NoDataError } from "../../Views/FormAnalyticsView.tsx";
import { ErrorBoundary } from "react-error-boundary";

const colours = ["#60A8FB", "#2563EB", "#BEDCFE", "#90C7FE", "#3B86F7"];

const chartConfig = {
    visitors: {
        label: "Visitors",
    },
    chrome: {
        label: "Chrome",
        color: "hsl(var(--chart-1))",
    },
    safari: {
        label: "Safari",
        color: "hsl(var(--chart-2))",
    },
    firefox: {
        label: "Firefox",
        color: "hsl(var(--chart-3))",
    },
    edge: {
        label: "Edge",
        color: "hsl(var(--chart-4))",
    },
    other: {
        label: "Other",
        color: "hsl(var(--chart-5))",
    },
} satisfies ChartConfig;

function BrowserOsDevice({
    devices,
    os,
    browser,
    devicesErrors,
    osErrors,
    browserErrors,
}) {
    const formatedDeviceData = [
        ...(devices?.data?.chart_data?.mobile
            ? [
                  {
                      device: "Mobile",
                      visitors: devices.data.chart_data.mobile,
                      fill: "#BEDCFE",
                  },
              ]
            : []),
        ...(devices?.data?.chart_data?.desktop
            ? [
                  {
                      device: "Desktop",
                      visitors: devices.data.chart_data.desktop,
                      fill: "#90C7FE",
                  },
              ]
            : []),
        ...(devices?.data?.chart_data?.other
            ? [
                  {
                      device: "Other",
                      visitors: devices.data.chart_data.other,
                      fill: "#2563EB",
                  },
              ]
            : []),
    ];

    const formatedOsData =
        os?.data?.chart_data?.map((item: any, index: number) => {
            return {
                os: item.platform,
                visitors: item.count,
                fill: colours[index],
            };
        }) || [];

    const formatedBrowserData =
        browser?.data?.chart_data?.map((item: any, index: number) => {
            return {
                browser: item.browser,
                visitors: item.count,
                fill: colours[index],
            };
        }) || [];

    return (
        <section className="grid grid-cols-3 gap-3 h-[426px] ">
            <Card className="flex flex-col">
                <CardHeader className="space-y-[6px]">
                    <CardTitle className="text-base font-semibold leading-4 text-[#191B33] ">
                        Devices
                    </CardTitle>
                    <CardDescription className=" text-sm font-normal text-[#626F86]">
                        {" "}
                        Devices distribution for form submissions
                    </CardDescription>
                </CardHeader>
                <ErrorBoundary fallback={<NoDataError />}>
                    {devicesErrors === "success" ? (
                        <CardContent className="flex-1 p-0">
                            <ResponsiveContainer width="100%" height="100%">
                                <ChartContainer
                                    config={chartConfig}
                                    className="mx-auto aspect-square max-h-[250px] pb-0 [&_.recharts-pie-label-text]:fill-foreground [&_.recharts-pie-label-text]:text-xs"
                                >
                                    <PieChart
                                        width={300}
                                        height={250}
                                        margin={{
                                            top: 16,
                                            right: 32,
                                            bottom: 16,
                                            left: 32,
                                        }}
                                    >
                                        <ChartTooltip
                                            content={
                                                <ChartTooltipContent
                                                    hideLabel
                                                    labelKey="device"
                                                />
                                            }
                                        />
                                        <Pie
                                            data={formatedDeviceData}
                                            dataKey="visitors"
                                            label={({ payload }) =>
                                                payload.device
                                            } // 👈 this is the key part
                                            nameKey="device"
                                        />
                                    </PieChart>
                                </ChartContainer>
                            </ResponsiveContainer>
                        </CardContent>
                    ) : (
                        <NoDataError height={250} />
                    )}
                </ErrorBoundary>
            </Card>

            <Card className="flex flex-col">
                <CardHeader className="space-y-[6px]">
                    <CardTitle className="text-base font-semibold leading-4 text-[#191B33] ">
                        Operating Systems
                    </CardTitle>
                    <CardDescription className=" text-sm font-normal text-[#626F86]">
                        {" "}
                        Operating System distribution for form submissions
                    </CardDescription>
                </CardHeader>
                <ErrorBoundary fallback={<NoDataError />}>
                    {osErrors === "success" ? (
                        <CardContent className="flex-1 pb-0">
                            <ResponsiveContainer width="100%" height="100%">
                                <ChartContainer
                                    config={chartConfig}
                                    className="mx-auto aspect-square max-h-[250px] pb-0 [&_.recharts-pie-label-text]:fill-foreground"
                                >
                                    <PieChart
                                        width={300}
                                        height={250}
                                        margin={{
                                            top: 16,
                                            right: 32,
                                            bottom: 16,
                                            left: 32,
                                        }}
                                    >
                                        <ChartTooltip
                                            content={
                                                <ChartTooltipContent
                                                    hideLabel
                                                />
                                            }
                                        />
                                        <Pie
                                            data={formatedOsData}
                                            dataKey="visitors"
                                            label={({ payload }) => payload.os} // 👈 this is the key part
                                            nameKey="os"
                                        />
                                    </PieChart>
                                </ChartContainer>
                            </ResponsiveContainer>
                        </CardContent>
                    ) : (
                        <NoDataError height={250} />
                    )}
                </ErrorBoundary>
            </Card>

            <Card className="flex flex-col">
                <CardHeader className="space-y-[6px]">
                    <CardTitle className="text-base font-semibold leading-4 text-[#191B33] ">
                        Browsers
                    </CardTitle>
                    <CardDescription className=" text-sm font-normal text-[#626F86]">
                        Browsers distribution for form submissions
                    </CardDescription>
                </CardHeader>
                <ErrorBoundary fallback={<NoDataError />}>
                    {browserErrors === "success" ? (
                        <CardContent className="flex-1 pb-0">
                            <ResponsiveContainer width="100%" height="100%">
                                <ChartContainer
                                    config={chartConfig}
                                    className="mx-auto aspect-square max-h-[250px] pb-0 [&_.recharts-pie-label-text]:fill-foreground"
                                >
                                    <PieChart
                                        width={300}
                                        height={250}
                                        margin={{
                                            top: 16,
                                            right: 32,
                                            bottom: 16,
                                            left: 32,
                                        }}
                                    >
                                        <ChartTooltip
                                            content={
                                                <ChartTooltipContent
                                                    hideLabel
                                                />
                                            }
                                        />
                                        <Pie
                                            data={formatedBrowserData}
                                            dataKey="visitors"
                                            label={({ payload }) =>
                                                payload.browser
                                            } // 👈 this is the key part
                                            nameKey="browser"
                                        />
                                    </PieChart>
                                </ChartContainer>
                            </ResponsiveContainer>
                        </CardContent>
                    ) : (
                        <NoDataError height={250} />
                    )}
                </ErrorBoundary>
            </Card>
        </section>
    );
}

export default BrowserOsDevice;
