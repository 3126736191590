import React from "react";

import {
    Dialog,
    DialogContent,
    DialogTitle,
    DialogFooter,
    DialogHeader,
} from "./ui/dialog.tsx";
import { Separator } from "./ui/separator.tsx";
import { Button } from "./ui/button.tsx";

import { useFormInsightsContext } from "../Contexts/FormInsightsContext.tsx";

import { TrashIcon } from "lucide-react";

type DeleteConfirmationModalProps = {
    open: boolean;
    loading: boolean;
    onDelete: () => void;
    onCancel: () => void;
};

function DeleteResponseConfirmationModal({
    open,
    loading,
    onDelete,
    onCancel,
}: DeleteConfirmationModalProps) {
    const { selectedFields } = useFormInsightsContext();
    return (
        <Dialog open={open} onOpenChange={onCancel}>
            <DialogContent className="top-[50%] p-0">
                <DialogHeader className="px-3 pt-3">
                    <DialogTitle className="flex flex-row items-center gap-2 text-sm">
                        <TrashIcon className="w-3 h-3" />
                        Delete Response
                    </DialogTitle>
                </DialogHeader>
                <Separator className="my-3" />
                <p className="text-[#191B33] text-sm px-3">
                    Are you sure you want to delete {selectedFields.length}{" "}
                    selected responses? This action cannot be undone.
                </p>
                <Separator className="my-3" />
                <DialogFooter className="px-3 pb-3">
                    <Button
                        variant="outline"
                        className="h-[36px] text-sm"
                        onClick={onCancel}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="destructive"
                        className="bg-[#F44336] h-[36px] text-sm"
                        disabled={loading}
                        onClick={onDelete}
                    >
                        Delete Response
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
}

export default DeleteResponseConfirmationModal;
