//Add routes in snake case (dash seperated)
export const ROUTES = {
    ROOT: "/",
    FORM_INITIALIZE: "/form-initialize",
    FORM_EDITOR: "/workspaces/:workspaceId/form-editor/:id",
    NEW_FORM_EDITOR: "/new-form-editor/:id",
    AI_FORM_EDITOR: "/ai-form-editor/:id",
    FORMS: "/forms/:urlId", //For public to view forms
    FORM_RESPONSES: "/forms/:urlId/results/responses",
    FORM_ANALYTICS: "/workspaces/:workspaceId/forms/:urlId/results/analytics",
    FORM_VIEW: "/form-view/:formId",
    LOGIN: "/login",
    DASHBOARD: "/dashboard",
    TEST_VIEW: "/test",
    SIGNUP: "/register",
    SIGNUP_FALLBACK: "/sign-up/sso-callback",
};
