import React, { useMemo, useRef, useState } from "react";

import { Input } from "../../ui/input.tsx";
import { Popover, PopoverContent, PopoverTrigger } from "../../ui/popover.tsx";
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
} from "../../ui/command.tsx";
import { ResizableTextarea } from "../../ui/resizable-textarea.tsx";
import FormButton from "../FormButton.tsx";

import useStyles from "../../../Hooks/useStyles.ts";

import { useFormEditorContext } from "../../../Contexts/FormEditorContext.jsx";

import { Countries } from "../../../Constants/countries.ts";

import { ChevronDownIcon } from "lucide-react";

type Country = {
    code: string;
    name: string;
    flag: string;
    dialCode: string;
};

export default function PhoneNumber() {
    const { getStyles } = useStyles();
    const { editorField, setEditorField, theme, setFields, device } =
        useFormEditorContext();
    const [value, setValue] = useState("");
    const inputRef = useRef<HTMLInputElement>(null);
    const [country, setCountry] = useState<Country>();

    const updateField = (fieldId, key, subkey, value) => {
        setFields((prevFields) => {
            const updatedFields = prevFields.map((field) => {
                if (field.id === fieldId) {
                    if (subkey) {
                        return {
                            ...field,
                            [key]: {
                                ...field[key],
                                [subkey]: value,
                            },
                        };
                    } else {
                        return {
                            ...field,
                            [key]: value,
                        };
                    }
                }
                return field;
            });
            return updatedFields;
        });

        setEditorField((prev) => {
            if (subkey) {
                return {
                    ...prev,
                    [key]: {
                        ...prev[key],
                        [subkey]: value,
                    },
                };
            } else {
                return {
                    ...prev,
                    [key]: value,
                };
            }
        });
    };

    const handleOnChange = (e) => {
        const value = e.target.value;
        // Only allow numbers but also allowing to clear the input
        if (value === "" || /^[0-9]*$/.test(value)) setValue(value);
    };

    const handleOnCountrySelected = (country: Country) => {
        setCountry(country);
        inputRef?.current?.focus();
    };

    return (
        <div className="flex flex-col gap-0 w-full max-w-[606px]">
            <ResizableTextarea
                placeholder="Add question title"
                value={editorField?.title || ""}
                onChange={(value) =>
                    updateField(editorField?.id, "title", null, value)
                }
                style={{
                    color: theme?.text_color,
                    fontSize: getStyles("h2", theme?.text_size, device)
                        .fontSize,
                    fontWeight: getStyles("h2", theme?.text_size, device)
                        .fontWeight,
                    fontFamily: `${theme?.font_family?.family}, ${theme?.font_family?.category}`,
                }}
            />
            <ResizableTextarea
                placeholder="Add question description (Optional)"
                value={editorField?.properties?.description || ""}
                onChange={(value) =>
                    updateField(
                        editorField?.id,
                        "properties",
                        "description",
                        value,
                    )
                }
                style={{
                    color: theme?.text_color,
                    fontSize: getStyles("h4", theme?.text_size, device)
                        .fontSize,
                    fontWeight: getStyles("h4", theme?.text_size, device)
                        .fontWeight,
                    fontFamily: `${theme?.font_family?.family}, ${theme?.font_family?.category}`,
                }}
                className="mt-2"
            />
            <div className="mt-4 h-[40px] flex flex-row items-center gap-2 border-t-0 rounded-none border-b border-[#B3B9C4]">
                <PhoneNumberCountryDropdown
                    onSelected={handleOnCountrySelected}
                />
                <p
                    style={{
                        color: theme?.answer_text_color,
                    }}
                >
                    {country?.dialCode}
                </p>
                <Input
                    ref={inputRef}
                    style={{
                        color: theme?.answer_text_color,
                        fontSize: getStyles("h4", theme?.text_size, device)
                            .fontSize,
                        fontWeight: getStyles("h4", theme?.text_size, device)
                            .fontWeight,
                        fontFamily: `${theme?.font_family?.family}, ${theme?.font_family?.category}`,
                    }}
                    value={value}
                    onChange={handleOnChange}
                    className="p-0 h-[40px] border-none text-left bg-transparent focus-visible:ring-0 focus-visible:ring-offset-0 outline-none overflow-hidden focus:border-b-2"
                />
            </div>
            <FormButton className="mt-3" />
        </div>
    );
}

type PhoneNumberCountryDropdownProps = {
    onSelected: (country: Country) => void;
};

function PhoneNumberCountryDropdown({
    onSelected,
}: PhoneNumberCountryDropdownProps) {
    const [open, setOpen] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState<{
        name: string;
        dialCode: string;
        flag: string;
        code: string;
    }>();

    const items = useMemo(() => {
        return Countries.map((country) => ({
            code: country.code,
            label: country.name,
            value: country.name,
            flag: country.image,
            dialCode: country.dial_code,
        }));
    }, [Countries]);

    return (
        <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger>
                <div
                    className="flex flex-row gap-2 items-center"
                    onFocus={(e) => {
                        e.preventDefault();
                        setOpen(true);
                    }}
                    onClick={(e) => {
                        e.preventDefault();
                        setOpen(true);
                    }}
                >
                    <img src={selectedCountry?.flag} width={32} />
                    <ChevronDownIcon className="w-4 h-4" />
                </div>
            </PopoverTrigger>

            <PopoverContent asChild>
                <Command>
                    <CommandInput placeholder="Search a country..." />
                    <CommandList>
                        <CommandEmpty>No results found.</CommandEmpty>
                        {items.length > 0 ? (
                            <CommandGroup>
                                {items.map((option) => (
                                    <CommandItem
                                        asChild
                                        key={option.value}
                                        value={option.label}
                                        onMouseDown={(e) => e.preventDefault()}
                                        onSelect={(value) => {
                                            setSelectedCountry({
                                                name: option.label,
                                                dialCode: option.dialCode || "",
                                                flag: option.flag,
                                                code: option.code,
                                            });
                                            onSelected({
                                                name: option.label,
                                                dialCode: option.dialCode || "",
                                                flag: option.flag,
                                                code: option.code,
                                            });
                                            setOpen(false);
                                        }}
                                    >
                                        <div className="flex flex-row gap-2 items-center">
                                            <img
                                                src={option.flag}
                                                width={32}
                                                height={32}
                                            />
                                            <p>
                                                {option.label}{" "}
                                                {option.dialCode &&
                                                    `(${option.dialCode})`}
                                            </p>
                                        </div>
                                    </CommandItem>
                                ))}
                            </CommandGroup>
                        ) : null}
                    </CommandList>
                </Command>
            </PopoverContent>
        </Popover>
    );
}
