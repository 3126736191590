import React, { useMemo } from "react";
import { ResponsiveFunnel } from "@nivo/funnel";
import clsx from "clsx";
import { Badge } from "../ui/badge.tsx";
import data from "./data.json";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "../ui/tooltip.tsx";
import { NoDataError } from "../../Views/FormAnalyticsView.tsx";

const minPartWidth = 200; // Minimum width for each part in pixels
type QuestionCardProps = {
    title: string;
    count: number;
    isLast?: boolean;
    isFirst?: boolean;
};

type QuestionType = {
    question_title: string;
    question_answer_count: number;
    question_answer_percentage?: number;
    question_number?: string;
};

const rightIcon = (
    <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect x="0.5" y="1" width="23" height="23" rx="11.5" fill="white" />
        <rect x="0.5" y="1" width="23" height="23" rx="11.5" stroke="#F1F2F4" />
        <path
            d="M10 16.5L14 12.5L10 8.5"
            stroke="#191B33"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
);

type FunnelCustomLayerProps<Datum> = {
    parts: {
        x: number;
        y: number;
        width: number;
        height: number;
        color: string;
        data: Datum;
    }[];
};
const CustomLabel = ({
    parts,
}: FunnelCustomLayerProps<{ id: string; value: number; label: string }>) => {
    return (
        <>
            {parts.map((part) => (
                <foreignObject
                    key={part.data.id}
                    x={part.x - 60} // Center the label
                    y={part.y - 10} // Center the label
                    width={part.width} // Fixed width for badge
                    height={30} // Fixed height for badge
                    style={{ overflow: "visible", pointerEvents: "none" }}
                >
                    <Badge className="bg-white text-[#191B33] font-medium text-base ">
                        {part.data.label}
                    </Badge>
                </foreignObject>
            ))}
        </>
    );
};

const CustomFunnel = ({ funnelData, funnelError }) => {
    //reformatting data for funnel
    const formattedDataForFunnel = useMemo(() => {
        //if no individual question stat, return empty array
        if (!funnelData?.data?.chart_data?.individual_question_stat) return [];
        const coreData =
            funnelData?.data?.chart_data?.individual_question_stat?.map(
                (question: QuestionType) => ({
                    id: `${question?.question_number}`,
                    value: question?.question_answer_count,
                    label: `${Math.floor(
                        question?.question_answer_percentage || 0,
                    )}%`,
                }),
            ) || [];

        // Add custom items to the start and end
        return [
            {
                id: "Views",
                value: funnelData?.data?.chart_data?.views_count,
                label: "Views",
            },
            ...coreData,
            {
                id: "Completed",
                value: funnelData?.data?.chart_data?.completed_count,
                label: "Completed",
            },
        ];
    }, [funnelData]);

    const totalParts = formattedDataForFunnel?.length;
    const totalWidth = totalParts * minPartWidth;

    const formattedIndividualQuestionStat = useMemo(() => {
        //if no individual question stat, return empty array
        if (!funnelData?.data?.chart_data?.individual_question_stat) return [];
        const individualQuestionData =
            funnelData?.data?.chart_data?.individual_question_stat?.map(
                (question: QuestionType) => ({
                    question_title: question?.question_title,
                    question_answer_count: question?.question_answer_count,
                    question_answer_percentage:
                        question?.question_answer_percentage,
                    question_number: question?.question_number,
                }),
            );
        return [
            {
                question_title: "Views",
                question_answer_count:
                    funnelData?.data?.chart_data?.views_count,
            },
            ...individualQuestionData,
            {
                question_title: "Completed",
                question_answer_count:
                    funnelData?.data?.chart_data?.completed_count,
            },
        ];
    }, [funnelData]);

    return (
        <section className="flex flex-col rounded-[8px] shadow-md border bg-white border-[#F1F2F4] w-full ">
            {/* new question card mapping */}
            {funnelError === "success" && (
                <div className=" w-[calc(100vw-2.5%)] flex flex-col gap-3 pb-3 no-scrollbar overflow-x-auto overflow-hidden">
                    <div className="flex">
                        {formattedIndividualQuestionStat?.map(
                            (question: QuestionType, index: number) => (
                                <TooltipProvider>
                                    <Tooltip>
                                        <TooltipTrigger>
                                            <QuestionCard
                                                title={
                                                    index === 0
                                                        ? "Views"
                                                        : index ===
                                                          formattedIndividualQuestionStat?.length -
                                                              1
                                                        ? "Completed"
                                                        : `${question?.question_title}`
                                                }
                                                count={
                                                    question?.question_answer_count
                                                }
                                                isFirst={index === 0}
                                                isLast={
                                                    index ===
                                                    formattedIndividualQuestionStat?.length -
                                                        1
                                                }
                                            />
                                        </TooltipTrigger>
                                        <TooltipContent className="font-medium max-w-[200px] text-black bg-white">
                                            <p>{question?.question_title} </p>
                                        </TooltipContent>
                                    </Tooltip>
                                </TooltipProvider>
                            ),
                        )}
                    </div>
                    <div
                        className="overflow-x-auto w-full h-[320px] no-scrollbar"
                        style={{
                            width: `${totalWidth}px`,
                            scrollbarWidth: "none",
                            msOverflowStyle: "none",
                        }}
                    >
                        <ResponsiveFunnel
                            data={formattedDataForFunnel || data}
                            direction="horizontal"
                            valueFormat=">-.2s"
                            colors={[
                                "#96BDFF",
                                "#DAAFFF",
                                "#DAAFFF",
                                "#DAAFFF",
                                "#DAAFFF",
                                "#83E3D4",
                            ]}
                            borderWidth={20}
                            currentPartSizeExtension={3}
                            currentBorderWidth={40}
                            shapeBlending={1}
                            motionConfig="wobbly"
                            layers={["parts", CustomLabel, "annotations"]} // Exclude 'separators'
                            tooltip={({ part }) => (
                                <div className="bg-white rounded-[8px] py-1.5 px-2 w-fit h-fit text-xs flex text-primary items-center gap-1">
                                    <p className="font-medium">
                                        {part?.data?.label}
                                    </p>
                                    <p className="font-normal">
                                        {part?.data?.value}
                                    </p>
                                </div>
                            )}
                        />
                    </div>
                </div>
            )}
            {funnelError === "error" && (
                <div className="flex justify-center items-center w-full min-h-[400px]">
                    <NoDataError />
                </div>
            )}
        </section>
    );
};

export default CustomFunnel;

const QuestionCard = ({ title, count, isLast, isFirst }: QuestionCardProps) => {
    return (
        <div
            className={clsx(
                " border relative border-[#F1F2F4] h-[105px] py-5 px-4 flex min-w-[200px] gap-1.5 flex-col  ",
                isFirst && "rounded-tl-[8px]",
                isLast && "rounded-tr-[8px]",
            )}
        >
            <div className="flex gap-1 items-center">
                <div
                    className={clsx(
                        "rounded-full size-[10px] ",
                        isFirst
                            ? "bg-[#96BCFB]"
                            : isLast
                            ? "bg-[#96BCFB]"
                            : "bg-[#C18FED]",
                    )}
                ></div>
                <h3 className="text-[#44546F] max-w-[150px] truncate text-sm font-normal">
                    {title}
                </h3>
            </div>
            <p className="text-[#191B33] text-3xl font-semibold">{count}</p>
            <div
                className={clsx(
                    "absolute z-10 -right-3 top-[40%] ",
                    isLast && "hidden",
                )}
            >
                {rightIcon}
            </div>
        </div>
    );
};
