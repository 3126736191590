import React, { useState } from "react";
import { useFormEditorContext } from "../../Contexts/FormEditorContext";
import { Button } from "../ui/button.tsx";
import { Input } from "../ui/input.tsx";
import { Popover, PopoverTrigger, PopoverContent } from "../ui/popover.tsx";
import { Link2, Share2, Save } from "lucide-react";
const EndScreenSettings = () => {
    const { endScreen, setEndScreen } = useFormEditorContext();
    const [redirectUrl, setRedirectUrl] = useState(endScreen?.redirectUrl);

    /*  const updateEndScreen = (key, value) => {
        setEndScreen({
            ...endScreen,
            [key]: value,
        });
    }; */

    const handleRedirectUrl = (e) => {
        console.log(e.target.value);
        setRedirectUrl(e.target.value);
    };

    const onSaveRedirectUrl = () => {
        setEndScreen({
            ...endScreen,
            redirectUrl: redirectUrl,
        });
    };

    return (
        <div
            className={`flex hidden absolute right-4 top-7 flex-col gap-2 items-start text-sm`}
        >
            <Popover>
                <PopoverTrigger>
                    <div className=" flex justify-center gap-2 items-center bg-[#F1F2F4] data-[state=open]:bg-[#191B33] px-3 py-2.5 rounded-[8px]">
                        <p className=" text-xs font-normal text-[#191B33]">
                            Add Redirect Link
                        </p>
                        <Link2
                            size={16}
                            className=" text-[#191B33] "
                            strokeWidth={1.5}
                        />
                    </div>
                </PopoverTrigger>
                <PopoverContent
                    align="end"
                    className=" relative flex justify-start w-[17.5rem] p-3   bg-[#191B33] mt-1.5 rounded-xl"
                >
                    <div className="absolute -top-1.5 right-10 ">
                        <svg
                            width="18"
                            height="12"
                            viewBox="0 0 18 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M7.06241 0.781893C8.18972 -0.194391 9.86301 -0.194389 10.9903 0.781895L16.8001 5.81327C18.8998 7.63167 17.6138 11.0811 14.8361 11.0811H3.21663C0.438977 11.0811 -0.847034 7.63167 1.25268 5.81327L7.06241 0.781893Z"
                                fill="#191B33"
                            />
                        </svg>
                    </div>
                    <div className="flex flex-col gap-4 justify-start w-full">
                        <div className="flex flex-col gap-1 justify-center">
                            <div className="flex gap-2 justify-start items-center">
                                <Share2
                                    size={12}
                                    className="text-white"
                                    strokeWidth={2}
                                />
                                <p className="text-sm font-medium text-white">
                                    Redirect Link
                                </p>
                            </div>
                            <p className="text-xs font-normal text-white">
                                Add end screen redirection link
                            </p>
                        </div>
                        <div className="flex flex-col gap-1 w-full">
                            <p className="text-xs font-normal text-white">
                                Redirect site URL
                            </p>
                            <div className="flex gap-1 justify-center items-center w-full">
                                <Input
                                    value={redirectUrl}
                                    onChange={(e) => {
                                        handleRedirectUrl(e);
                                    }}
                                    placeholder="https://"
                                    className="w-full bg-[#172B4D] border-[#44546F] h-9 text-[#8590A2] text-xs rounded-[8px] focus:border-[#44546F] focus-visible:ring-offset-0 focus-visible:outline-none focus-visible:ring-0"
                                />
                                <Button
                                    variant={"outline"}
                                    onClick={onSaveRedirectUrl}
                                    className="hover:bg-[#172B4D] bg-[#172B4D] border-[#44546F] h-9   text-[#8590A2] text-xs rounded-[6px]"
                                    size={"sm"}
                                >
                                    <Save
                                        size={12}
                                        className="text-white"
                                        strokeWidth={2}
                                    />
                                </Button>
                            </div>
                        </div>
                    </div>
                </PopoverContent>
            </Popover>
        </div>
    );
};

export default EndScreenSettings;
