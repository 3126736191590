import React from "react";
import useStyles from "../../../Hooks/useStyles.ts";
import { useFormEditorContext } from "../../../Contexts/FormEditorContext";
import { cn } from "../../../lib/utils.js";
import { Input } from "../../ui/input.tsx";
import FormButton from "../FormButton.tsx";

export default function RedirectUrl() {
    const { device, theme, fields, setFields, setEditorField, editorField } =
        useFormEditorContext();
    const { getStyles } = useStyles();

    const getDeviceStyle = () => {
        if (device === "mobile") {
            return "h-[36px]";
        }
        return "h-[40px]";
    };

    const deviceStyle = getDeviceStyle();

    const updateField = (fieldId, key, subkey: string | null = null, value) => {
        setFields((prevFields) => {
            const updatedFields = prevFields.map((field) => {
                if (field.id === fieldId) {
                    if (subkey) {
                        return {
                            ...field,
                            [key]: {
                                ...field[key],
                                [subkey]: value,
                            },
                        };
                    } else {
                        return {
                            ...field,
                            [key]: value,
                        };
                    }
                }
                return field;
            });
            return updatedFields;
        });

        setEditorField((prev) => {
            if (subkey) {
                return {
                    ...prev,
                    [key]: {
                        ...prev[key],
                        [subkey]: value,
                    },
                };
            } else {
                return {
                    ...prev,
                    [key]: value,
                };
            }
        });

        //setChanged(true);
    };
    return (
        <div className="flex flex-col gap-0 w-full max-w-[606px]">
            <h2
                className="text-left"
                style={{
                    color: theme?.text_color,
                    fontSize: getStyles("h2", theme?.text_size, device)
                        .fontSize,
                    fontWeight: getStyles("h2", theme?.text_size, device)
                        .fontWeight,
                    fontFamily: `${theme?.font_family?.family}, ${theme?.font_family?.category}`,
                }}
            >
                Redirect to URL
            </h2>
            <h4
                style={{
                    color: theme?.text_color,
                    fontSize: getStyles("h4", theme?.text_size, device)
                        .fontSize,
                    fontWeight: getStyles("h4", theme?.text_size, device)
                        .fontWeight,
                    fontFamily: `${theme?.font_family?.family}, ${theme?.font_family?.category}`,
                }}
                className="mt-2 text-left"
            >
                Send people to this web page when they complete your Buildform
            </h4>
            <Input
                value={editorField?.properties?.redirection_url || ""}
                onChange={(e) =>
                    updateField(
                        editorField?.id,
                        "properties",
                        "redirection_url",
                        e.target.value,
                    )
                }
                placeholder="Enter a URL"
                style={{
                    color: theme?.answer_text_color,
                    fontSize: getStyles("h2", theme?.text_size, device)
                        .fontSize,
                    fontWeight: getStyles("h2", theme?.text_size, device)
                        .fontWeight,
                    fontFamily: `${theme?.font_family?.family}, ${theme?.font_family?.category}`,
                }}
                className={cn(
                    `p-0 mt-4 border-x-0 border-t-0 rounded-none border-b border-[#B3B9C4] text-left bg-transparent focus-visible:ring-0 focus-visible:ring-offset-0 outline-none overflow-hidden`,
                    deviceStyle,
                )}
            />
            <p
                style={{
                    color: theme?.text_color,
                }}
                className="mt-4 text-xs text-left"
            >
                Your respondents will be redirected to your URL. They won't see
                this screen.
            </p>
            <FormButton className="invisible mt-3" />
        </div>
    );
}
