import React, { useState } from "react";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover.tsx";
import { Button } from "../ui/button.tsx";
import clsx from "clsx";
import { cn } from "../../lib/utils.js";
import { format } from "date-fns";
import { Tabs, TabsList, TabsTrigger } from "../ui/tabs.tsx";
import { useFormInsightsContext } from "../../Contexts/FormInsightsContext.tsx";
import {
    QUESTION_TYPES,
    QUESTION_OBJECTS,
} from "../../Constants/questionTypes.js";
import { useQueryClient } from "@tanstack/react-query";
import {
    ChartColumnBig,
    ListEnd,
    Users,
    Download,
    Filter,
    Settings2,
    CalendarDays,
    X,
    SearchIcon,
    RefreshCcw as RefreshCcwIcon,
    Trash,
    LoaderCircle,
} from "lucide-react";
import { Calendar } from "../ui/calendar.tsx";
import { Checkbox } from "../ui/checkbox.tsx";
import { useSearchParams } from "react-router-dom";
import { Input } from "../ui/input.tsx";
import { MultiSelect } from "../ui/multi-select.tsx";
import { useMutation } from "@tanstack/react-query";
import { useApi } from "../../Api/useApi.ts";
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
} from "../ui/select.tsx";
import { useParams } from "react-router-dom";

type AnalyticsTabType = {
    title: string;
    value: string;
    icon: JSX.Element;
};

const analyticsTabTypes: AnalyticsTabType[] = [
    {
        title: "Form Overview",
        value: "overview",
        icon: <ChartColumnBig className="w-4" />,
    },
    {
        title: "Question Insights",
        value: "insights",
        icon: <ListEnd className="w-4" />,
    },
    {
        title: "Contacts",
        value: "contacts",
        icon: <Users className="w-4" />,
    },
];

const Settings = () => {
    const {
        activeTab,
        setActiveTab,
        analyticsTab,
        setAnalyticsTab,
        fields,
        setFields,
        loading,
        setLoading,
        form,
        selectedFields,
    } = useFormInsightsContext();
    const api = useApi();
    const { urlId, workspaceId } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const queryClient = useQueryClient();

    const query = searchParams.get("query") ?? "";
    const from = searchParams.get("from");
    const to = searchParams.get("to");

    const [date, setDate] = useState({
        from: from ? new Date(from) : undefined,
        to: to ? new Date(to) : undefined,
    });

    const { mutate: exportResponsesMutation, isPending: isExporting } =
        useMutation({
            mutationFn: ({
                workspaceId,
                formId,
                responseIds,
            }: {
                workspaceId: string;
                formId?: string;
                responseIds?: string[];
            }) => api.forms.exportResponses(workspaceId, formId, responseIds),
            onSuccess: (data) => {
                const exportFile = data?.data?.file_url;
                if (exportFile) {
                    // Create an anchor element
                    const anchor = document.createElement("a");

                    // Set the href to the file URL
                    anchor.href = exportFile;

                    // Set the download attribute without a filename to keep the original file name
                    anchor.setAttribute("download", "");

                    // Append the anchor to the body (optional, for some browsers)
                    document.body.appendChild(anchor);

                    // Trigger a click event to start the download
                    anchor.click();

                    // Remove the anchor from the DOM
                    document.body.removeChild(anchor);
                    //  messageApi.success("Responses exported successfully");
                }
                //  queryClient.invalidateQueries({ queryKey: ["responses"] });
            },
            onError: (error: any) => {
                if (error.response) {
                    //  messageApi.error(` ${error.response.data.detail}`);
                } else {
                    // messageApi.error(
                    //     "Could not export responses: An unexpected error occurred",
                    //  );
                }
                console.error("Error details:", error);
            },
        });

    const handleDateChange = (selectedDate) => {
        if (!selectedDate) return;
        setDate(selectedDate);
        setSearchParams({
            from: selectedDate.from
                ? format(selectedDate.from, "yyyy-MM-dd")
                : "",
            to: selectedDate.to ? format(selectedDate.to, "yyyy-MM-dd") : "",
        });
    };

    const handleOnTabChange = (tab: any) => {
        localStorage.setItem("activeTab", tab);
        setActiveTab(tab);
    };

    const handleOnAnalyticsTabChange = (tab: any) => {
        setAnalyticsTab(tab);
    };

    const handleOnFieldValueChange = (id: string, value: boolean) => {
        setFields(
            fields.map((field) =>
                field.id === id ? { ...field, checked: value } : field,
            ),
        );
    };

    const handleOnRefresh = async () => {
        setLoading(true);
        await queryClient.invalidateQueries({ queryKey: ["formResponses"] });
        setLoading(false);
    };

    const handleOnExportAll = () => {
        if (!urlId) return;
        const selectedFieldsIds: string[] = selectedFields?.map(
            (field) => field.id,
        );
        if (selectedFieldsIds.length > 0) {
            exportResponsesMutation({
                workspaceId: workspaceId as string,
                responseIds: selectedFieldsIds,
            });
        } else {
            exportResponsesMutation({
                workspaceId: workspaceId as string,
                formId: urlId,
                responseIds: selectedFieldsIds,
            });
        }
    };

    return (
        <div
            className={clsx(
                "relative grid w-full h-[3.75rem]  py-2.5 ",
                activeTab === "analytics" ? "grid-cols-3" : "grid-cols-2",
            )}
        >
            <Tabs
                defaultValue="submissions"
                className="w-[184px]"
                value={activeTab}
                onValueChange={handleOnTabChange}
            >
                <TabsList className="w-full h-9 rounded-[8px] bg-[#F7F8F9]">
                    <TabsTrigger
                        value="analytics"
                        className="w-full rounded-[6px] text-xs data-[state=active]:text-[#191B33] text-[#44546F] data-[state=active]:font-semibold font-medium"
                    >
                        Analytics
                    </TabsTrigger>
                    <TabsTrigger
                        value="submissions"
                        className="w-full text-xs rounded-[6px] data-[state=active]:text-[#191B33] text-[#44546F] data-[state=active]:font-semibold font-medium "
                    >
                        Submissions
                    </TabsTrigger>
                </TabsList>
            </Tabs>

            {activeTab === "analytics" && (
                <Tabs
                    defaultValue="overview"
                    className="w-min"
                    value={analyticsTab}
                    onValueChange={handleOnAnalyticsTabChange}
                >
                    <TabsList className={clsx("w-full h-9 bg-transparent")}>
                        {analyticsTabTypes?.map((tab) => (
                            <TabsTrigger
                                key={tab.value}
                                value={tab.value}
                                className={cn(
                                    "flex items-center rounded-[8px] w-full gap-1 text-xs data-[state=active]:bg-[#F7F8F9] data-[state=active]:text-foreground data-[state=active]:shadow-sm p-1 pr-2 ",
                                )}
                                disabled={
                                    tab.value === "contacts" ||
                                    tab.value === "insights"
                                }
                            >
                                <span
                                    className={clsx(
                                        "rounded-[6px] w-7 h-7 items-center flex justify-center border border-[#F1F2F4] ",
                                        analyticsTab === tab.value
                                            ? "text-[#2563EB] bg-white"
                                            : "text-[#758195]",
                                    )}
                                >
                                    {tab.icon}
                                </span>
                                <span
                                    className={clsx(
                                        "text-xs font-normal",
                                        analyticsTab === tab.value
                                            ? "text-[#191B33]"
                                            : "text-[#758195]",
                                    )}
                                >
                                    {tab.title}
                                </span>
                            </TabsTrigger>
                        ))}
                    </TabsList>
                </Tabs>
            )}

            <div className="flex gap-2 justify-end items-center self-end">
                {activeTab === "submissions" && (
                    <>
                        <div className="relative group">
                            <Button
                                className={clsx(
                                    " p-0 absolute hover:bg-transparent top-0.5 left-1 bg-transparent justify-center flex rounded-[8px] font-bold text-[#191B33] group-hover:border-none  items-center size-9 ",
                                    query.length > 0
                                        ? "border-none"
                                        : " border group-hover:border-white",
                                )}
                            >
                                <SearchIcon
                                    className={clsx(
                                        "size-4 hover:border-none border-none text-[#191B33] group-hover:mr-2",
                                        query.length > 0 && "mr-2",
                                    )}
                                />
                            </Button>
                            <Input
                                type="text"
                                placeholder="Search submissions..."
                                value={query}
                                onChange={(event) => {
                                    setSearchParams({
                                        query: event.target.value,
                                    });
                                }}
                                className={clsx(
                                    "w-0 py-1 pl-8 pr-2 overflow-hidden text-xs text-[#191B33] transition-all duration-300 ease-in-out border border-transparent border-none rounded-md group-hover:w-48 focus:border-trasparent focus-visible:ring-offset-0 focus-visible:outline-none focus-visible:ring-0",
                                    query.length > 0 && "w-48 ",
                                )}
                            />
                        </div>
                        <Popover>
                            <PopoverTrigger asChild>
                                <Button
                                    variant="outline"
                                    className="p-0 rounded-[8px]  text-sm size-9"
                                >
                                    <Settings2 className="size-4" />
                                </Button>
                            </PopoverTrigger>
                            <PopoverContent className=" w-[240px] rounded-[8px] bg-primary p-3  ">
                                <div className="flex flex-col gap-2 rounded-[8px] ">
                                    <div className="flex items-center gap-[2px] ">
                                        <Settings2
                                            size={16}
                                            className="text-white"
                                        />
                                        <span className="text-sm font-medium text-white">
                                            Toggle columns
                                        </span>
                                    </div>
                                    <div className="flex flex-col gap-2 max-h-[185px] overflow-y-auto no-scrollbar">
                                        {fields?.length > 0 && (
                                            <div className="flex flex-col gap-1 mt-3 text-white">
                                                {fields
                                                    ?.filter(
                                                        (field) =>
                                                            field.type !==
                                                            "section",
                                                    )
                                                    ?.map((field) => (
                                                        <FieldSelectSettings
                                                            key={field?.id}
                                                            title={
                                                                field?.type ===
                                                                QUESTION_TYPES.AI_STATEMENT
                                                                    ? "AI Statement"
                                                                    : field.title
                                                            }
                                                            type={field?.type}
                                                            value={
                                                                field?.checked
                                                            }
                                                            onValueChange={(
                                                                value,
                                                            ) =>
                                                                handleOnFieldValueChange(
                                                                    field?.id,
                                                                    value,
                                                                )
                                                            }
                                                        />
                                                    ))}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </PopoverContent>
                        </Popover>
                        <Button
                            variant="outline"
                            size="icon"
                            disabled={loading}
                            onClick={handleOnRefresh}
                            className="p-0 rounded-[8px] text-sm  size-9"
                        >
                            <RefreshCcwIcon className="size-4" />
                        </Button>
                    </>
                )}
                {activeTab !== "analytics" && (
                    <Button
                        variant="outline"
                        className="p-0 rounded-[8px] text-sm  size-9"
                        onClick={handleOnExportAll}
                        disabled={isExporting}
                    >
                        {isExporting ? (
                            <LoaderCircle className="animate-spin size-4" />
                        ) : (
                            <Download className="size-4" />
                        )}
                    </Button>
                )}
                <Popover>
                    <PopoverTrigger asChild>
                        <Button
                            variant="outline"
                            className="p-0 rounded-[8px] text-sm size-9"
                            disabled
                        >
                            <Filter className="size-4" />
                        </Button>
                    </PopoverTrigger>
                    <PopoverContent className="w-[360px] gap-4 flex flex-col bg-primary max-h-[321px] overflow-y-auto no-scrollbar rounded-[8px]  p-3">
                        <FilterByfieldChoices />
                    </PopoverContent>
                </Popover>

                <div
                    className={cn(
                        "flex border border-[#DCDFE4] pl-3 py-2.5 rounded-[8px] items-center min-w-[231px] h-9 justify-between",
                    )}
                >
                    <Popover>
                        <PopoverTrigger asChild>
                            <Button
                                id="date"
                                variant="ghost"
                                className={cn(
                                    "h-9 justify-start hover:bg-transparent text-left items-center text-xs p-0 font-normal",
                                    !date.from &&
                                        "text-muted-foreground w-full hover:bg-transparent",
                                )}
                            >
                                <CalendarDays className="w-4 mr-2 text-[#626F86]" />
                                {date.from ? (
                                    date.to ? (
                                        <>
                                            {format(date.from, "LLL dd, y")} -{" "}
                                            {format(date.to, "LLL dd, y")}
                                        </>
                                    ) : (
                                        format(date.from, "LLL dd, y")
                                    )
                                ) : (
                                    <span>Pick a date</span>
                                )}
                            </Button>
                        </PopoverTrigger>
                        <PopoverContent className="w-auto p-0 mr-3.5">
                            <Calendar
                                initialFocus
                                mode="range"
                                defaultMonth={date.from}
                                selected={date}
                                onSelect={handleDateChange}
                                numberOfMonths={2}
                                fromDate={form?.created_at}
                                toDate={new Date()}
                            />
                        </PopoverContent>
                    </Popover>
                    {date.from && (
                        <button
                            onClick={() => {
                                setDate({ from: undefined, to: undefined });
                                setSearchParams({ from: "", to: "" });
                            }}
                        >
                            <X className="w-4 h-4 mr-1.5 text-[#44546F]" />
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Settings;

type FieldSelectSettingsProps = {
    title: string;
    type: string;
    value: boolean;
    onValueChange: (value: boolean) => void;
};

function FieldSelectSettings({
    title,
    type,
    value,
    onValueChange,
}: FieldSelectSettingsProps) {
    return (
        <div className="flex flex-row items-center justify-start py-[16px]  h-[32px]">
            <Checkbox
                id={type}
                checked={value}
                onCheckedChange={onValueChange}
                className="w-[36px] h-[20px]"
            />

            <p className="text-xs truncate max-w-[180px]">{title}</p>
        </div>
    );
}

const FilterByfieldChoices = () => {
    const { fields, setFields } = useFormInsightsContext();

    const multicheckedOnchange = (id: string) => {
        setFields(
            fields.map((field) =>
                field.id === id
                    ? { ...field, multiChecked: !field.multiChecked }
                    : field,
            ),
        );
    };

    const selectedChoicesOnChange = (id: string, value: any) => {
        setFields(
            fields.map((field) =>
                field.id === id ? { ...field, selectedChoices: value } : field,
            ),
        );
    };

    const multiCheckedFalseFields = fields?.filter(
        (field) =>
            field?.multiChecked === false &&
            (field?.type === "multiple_choice" ||
                field?.type === "dropdown" ||
                field?.type === "country" ||
                field?.type === "status" ||
                field?.type === "legal"),
    );

    const multiCheckedTrueFields = fields?.filter(
        (field) =>
            field?.multiChecked === true &&
            (field?.type === "multiple_choice" ||
                field?.type === "dropdown" ||
                field?.type === "country" ||
                field?.type === "status" ||
                field?.type === "legal"),
    );

    return (
        <>
            {/* <AdvancedFilter /> */}
            <div className="flex gap-1 items-center">
                <Filter className="text-white" size={14} />
                <span className="text-sm font-medium text-white">
                    Filter Responses
                </span>
            </div>

            <div className="flex flex-col gap-1">
                <p className="text-xs text-white">
                    Show me all responses where...
                </p>
                <div className="flex flex-col gap-1 w-full">
                    {multiCheckedTrueFields &&
                        multiCheckedTrueFields?.map((field) => (
                            <div className="flex flex-col gap-1 w-full">
                                <div className="flex gap-1 w-full item-center">
                                    <Input
                                        readOnly
                                        className=" max-w-[296px] h-9 bg-[#172B4D] focus:border-[#44546F] text-xs border-[#44546F]  text-white focus:ring-transparent focus:ring-0 focus:ring-offset-0  focus-visible:ring-offset-0 focus-visible:outline-none focus-visible:ring-0"
                                        value={field?.title}
                                        autoFocus={false}
                                    />
                                    <Button
                                        onClick={() => {
                                            multicheckedOnchange(field.id);
                                        }}
                                        className="p-0 size-9 bg-[#172B4D] hover:bg-[#172B4D] focus:border-[#44546F] border text-xs border-[#44546F]"
                                    >
                                        <Trash className="text-white size-3" />
                                    </Button>
                                </div>
                                <div className="flex gap-1 w-full">
                                    <MultiSelect
                                        options={field?.choices}
                                        placeholder="Select one or more options..."
                                        defaultValue={field?.selectedChoices}
                                        variant="inverted"
                                        animation={2}
                                        maxCount={3}
                                        onValueChange={(value) => {
                                            selectedChoicesOnChange(
                                                field.id,
                                                value,
                                            );
                                        }}
                                    />
                                </div>
                            </div>
                        ))}
                </div>
                {multiCheckedFalseFields?.length > 0 && (
                    <Select
                        onValueChange={(field) => {
                            multicheckedOnchange(field);
                        }}
                    >
                        <SelectTrigger className="w-[336px] bg-[#172B4D] focus:border-[#44546F] focus:ring-transparent focus:ring-0 border-[#44546F] focus:ring-offset-0 h-9 text-[#8590A2] text-xs">
                            <p className="text-xs">
                                Select question or data...
                            </p>
                        </SelectTrigger>
                        <SelectContent className="w-[336px] border-[#44546F] bg-[#172B4D] hover:bg-[#172B4D] text-xs px-0 text-white p-0">
                            <SelectGroup className="px-0">
                                {multiCheckedFalseFields?.map((field) => (
                                    <>
                                        <SelectItem
                                            key={field?.id}
                                            value={field?.id}
                                            className="hover:bg-[#44546F] max-w-[320px] truncate  h-8 px-2 hover:cursor-pointer focus:text-white focus:bg-transparent hover:text-white"
                                        >
                                            <SelectLabel className="flex flex-row gap-2 items-center px-0 text-xs font-normal text-white">
                                                {
                                                    QUESTION_OBJECTS?.[
                                                        field?.type
                                                    ]?.icon
                                                }
                                                {field?.title}
                                            </SelectLabel>
                                        </SelectItem>
                                        {/*  <Separator className="  bg-[#44546F]" />
                                            {multiCheckedFalseFields
                                                ?.filter(
                                                    (f) => f.type === "status",
                                                )
                                                ?.map((field) => (
                                                    <SelectItem
                                                        key={field?.id}
                                                        value={field?.id}
                                                        className="hover:bg-[#44546F] max-w-[320px] truncate  h-8 px-2 hover:cursor-pointer focus:text-white focus:bg-transparent hover:text-white"
                                                    >
                                                        <SelectLabel className="flex flex-row gap-2 items-center px-0 text-xs font-normal text-white">
                                                            <ListCheck className="w-4 h-4 text-white" />
                                                            {field?.title}
                                                        </SelectLabel>
                                                    </SelectItem>
                                                ))} */}
                                    </>
                                ))}
                            </SelectGroup>
                        </SelectContent>
                    </Select>
                )}
            </div>
        </>
    );
};
