import React, { useState } from "react";
import {
    ColumnDef,
    SortingState,
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";

import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "../ui/table.tsx"; // Import ShadCN table components
import { ChevronsUpDown } from "lucide-react";

import { Button } from "../ui/button.tsx"; // Import button for sorting

// Sample dataset
const sampleData = [
    {
        id: "1",
        question: "What is your age?",
        avgTime: "2s",
        views: 150,
        dropOff: "10%",
    },
    {
        id: "2",
        question: "How did you hear about us?",
        avgTime: "5s",
        views: 230,
        dropOff: "15%",
    },
    {
        id: "3",
        question: "Would you recommend us?",
        avgTime: "3s",
        views: 180,
        dropOff: "5%",
    },
    {
        id: "4",
        question: "What is your favorite feature?",
        avgTime: "4s",
        views: 210,
        dropOff: "12%",
    },
    {
        id: "5",
        question: "Any additional feedback?",
        avgTime: "6s",
        views: 95,
        dropOff: "20%",
    },
];

// Define columns
const columns: ColumnDef<(typeof sampleData)[number]>[] = [
    {
        accessorKey: "question",
        header: ({ column }) => (
            <div className="flex items-center gap-2 ">
                <span>Questions</span>
                <Button
                    variant="ghost"
                    className=" hover:bg-transparent"
                    onClick={() =>
                        column.toggleSorting(column.getIsSorted() === "asc")
                    }
                >
                    <ChevronsUpDown size={16} />
                </Button>
            </div>
        ),
        cell: ({ row }) => <span>{row.getValue("question")}</span>,
    },
    {
        accessorKey: "avgTime",
        header: ({ column }) => (
            <div className="flex items-center gap-2 ">
                <span> Average Time</span>
                <Button
                    variant="ghost"
                    className=" hover:bg-transparent"
                    onClick={() =>
                        column.toggleSorting(column.getIsSorted() === "asc")
                    }
                >
                    <ChevronsUpDown size={16} />
                </Button>
            </div>
        ),
        cell: ({ row }) => <span>{row.getValue("avgTime")}</span>,
    },
    {
        accessorKey: "views",
        header: ({ column }) => (
            <div className="flex items-center gap-2 ">
                <span>Views</span>
                <Button
                    variant="ghost"
                    className=" hover:bg-transparent"
                    onClick={() =>
                        column.toggleSorting(column.getIsSorted() === "asc")
                    }
                >
                    <ChevronsUpDown size={16} />
                </Button>
            </div>
        ),
        cell: ({ row }) => <span>{row.getValue("views")}</span>,
    },
    {
        accessorKey: "dropOff",
        header: ({ column }) => (
            <div className="flex items-center gap-2 ">
                <span>Drop Off</span>
                <Button
                    variant="ghost"
                    className=" hover:bg-transparent"
                    onClick={() =>
                        column.toggleSorting(column.getIsSorted() === "asc")
                    }
                >
                    <ChevronsUpDown size={16} />
                </Button>
            </div>
        ),
        cell: ({ row }) => <span>{row.getValue("dropOff")}</span>,
    },
];

export function QuestionInsightDataTable() {
    const [sorting, setSorting] = useState<SortingState>([]);

    const table = useReactTable({
        data: sampleData,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        onSortingChange: setSorting,
        state: {
            sorting,
        },
    });

    return (
        <div className="w-full h-full overflow-auto border rounded-md ">
            <Table className="w-full h-fit">
                <TableHeader>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <TableRow key={headerGroup.id}>
                            {headerGroup.headers.map((header) => (
                                <TableHead
                                    key={header.id}
                                    className="sticky top-0 text-sm font-medium text-left"
                                >
                                    {header.isPlaceholder
                                        ? null
                                        : flexRender(
                                              header.column.columnDef.header,
                                              header.getContext(),
                                          )}
                                </TableHead>
                            ))}
                        </TableRow>
                    ))}
                </TableHeader>
                <TableBody>
                    {table.getRowModel().rows.length ? (
                        table.getRowModel().rows.map((row) => (
                            <TableRow key={row.id}>
                                {row.getVisibleCells().map((cell) => (
                                    <TableCell key={cell.id}>
                                        {flexRender(
                                            cell.column.columnDef.cell,
                                            cell.getContext(),
                                        )}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))
                    ) : (
                        <TableRow>
                            <TableCell
                                colSpan={columns.length}
                                className="py-4 text-center"
                            >
                                No data available.
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </div>
    );
}

export default QuestionInsightDataTable;
