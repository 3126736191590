import React from "react";
import {
    CartesianGrid,
    LabelList,
    Line,
    LineChart,
    XAxis,
    YAxis,
} from "recharts";
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from "../ui/card.tsx";
import {
    ChartConfig,
    ChartContainer,
    ChartTooltip,
    ChartTooltipContent,
} from "../ui/chart.tsx";
import { ResponsiveContainer } from "recharts";
import { NoDataError } from "../../Views/FormAnalyticsView.tsx";

const chartConfig = {
    desktop: {
        label: "Desktop",
        color: "hsl(var(--chart-1))",
    },
    mobile: {
        label: "Mobile",
        color: "hsl(var(--chart-2))",
    },
} satisfies ChartConfig;

type TrendItem = {
    x_axis: string;
    y_axis: number;
};
const Trends = ({ trends, trendsErrors }) => {
    const formattedTrendsData =
        trends?.data?.chart_data?.map((item: TrendItem) => ({
            period: item?.x_axis,
            submissions: item?.y_axis,
        })) || [];

    const hasValidData =
        Array.isArray(formattedTrendsData) && formattedTrendsData.length > 0;

    return (
        <Card className="h-[332px]">
            <CardHeader className="flex flex-row justify-between items-center">
                <div className="flex flex-col gap-2">
                    <CardTitle>Trends</CardTitle>

                    <CardDescription>
                        {trends?.data?.chart_description}
                    </CardDescription>
                </div>
            </CardHeader>
            <CardContent className="h-[218px] overflow-x-auto mx-auto">
                {trendsErrors === "success" && hasValidData ? (
                    <ResponsiveContainer width="100%" height="100%">
                        <ChartContainer config={chartConfig}>
                            <LineChart
                                accessibilityLayer
                                data={formattedTrendsData}
                                margin={{
                                    top: 20,
                                    left: 12,
                                    right: 12,
                                }}
                            >
                                <CartesianGrid vertical={true} />
                                <YAxis
                                    dataKey="submissions"
                                    type="number"
                                    tickLine={true}
                                    tickMargin={10}
                                    axisLine={false}
                                />
                                <XAxis
                                    dataKey="period"
                                    tickLine={false}
                                    axisLine={false}
                                    tickMargin={1}
                                    tick={{ fontSize: 12 }}
                                    tickFormatter={(value) =>
                                        String(value).slice(0, 10)
                                    }
                                />
                                <ChartTooltip
                                    cursor={true}
                                    content={
                                        <ChartTooltipContent indicator="line" />
                                    }
                                />
                                <Line
                                    dataKey="submissions"
                                    type="monotone"
                                    stroke="#2563EB"
                                    strokeWidth={2}
                                    dot={{
                                        fill: "#2563EB",
                                    }}
                                    activeDot={{
                                        r: 6,
                                    }}
                                >
                                    <LabelList
                                        position="top"
                                        offset={12}
                                        className="fill-foreground"
                                        fontSize={12}
                                    />
                                </Line>
                            </LineChart>
                        </ChartContainer>
                    </ResponsiveContainer>
                ) : (
                    <NoDataError />
                )}
            </CardContent>
        </Card>
    );
};

export default Trends;
