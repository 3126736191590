import React, { useRef, useImperativeHandle } from "react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { ConfigProvider, Input } from "antd";
import { useFormContext } from "../../../Contexts/FormContext";
import { getInputStyle } from "../../../Constants/styles";
import { CountrySelect } from "./CountrySelect";
import { useCookies } from "react-cookie";
import { getCountryCallingCode } from "../../../Utils/countries";
import KeyboardEventHandler from "react-keyboard-event-handler";
import { NEXT_QUESTION_KEYS } from "../../../Constants/keys";
import tinycolor from "tinycolor2";

const MyInput = React.forwardRef((props, ref) => {
    const inputRef = useRef(null);

    useImperativeHandle(
        ref,
        () => {
            return inputRef.current.input;
        },
        [],
    );
    return (
        <>
            <Input {...props} ref={inputRef} variant="borderless" />
        </>
    );
});

export const PhoneNumberInput = ({ value, onChange, ...props }) => {
    const [cookies, setCookie] = useCookies(["phone_country"]);
    const { styles, next } = useFormContext();

    const country = cookies["phone_country"];
    const _setCountry = (value) => {
        setCookie("phone_country", value);
    };

    const setCountry = (country) => {
        onChange("+" + getCountryCallingCode(country));
        _setCountry(country);
    };

    const _onChange = (newValue) => {
        if (
            country &&
            !newValue?.startsWith("+" + getCountryCallingCode(country))
        ) {
            setCountry(null);
        }
        onChange(newValue);
    };

    const colors = styles;

    return (
        <KeyboardEventHandler
            handleKeys={NEXT_QUESTION_KEYS}
            onKeyEvent={(key, e) => {
                next();
            }}
            style={{ width: "100%" }}
        >
            <ConfigProvider
                theme={{
                    components: {
                        Input: {
                            colorText: value
                                ? styles.secondary_color
                                : tinycolor(styles.secondary_color)
                                      .setAlpha(0.5)
                                      .toRgbString(),
                        },
                        Select: {
                            singleItemHeightLG: 30,
                        },
                    },
                }}
            >
                <PhoneInput
                    value={value || "+"}
                    onChange={_onChange}
                    placeholder="+"
                    className="ant-input"
                    defaultCountry={country}
                    international={true}
                    limitMaxLength={true}
                    style={{
                        width: "100%",
                        gap: "0px",
                        ...getInputStyle(colors, true),
                    }}
                    inputComponent={MyInput}
                    focusInputOnCountrySelection
                    countrySelectComponent={({ onChange, value, ...props }) => (
                        <CountrySelect
                            {...props}
                            country={country}
                            setCountry={setCountry}
                        />
                    )}
                    onCountryChange={(country) => {
                        _setCountry(country);
                    }}
                    {...props}
                />
            </ConfigProvider>
        </KeyboardEventHandler>
    );
};
