import React, { useState } from "react";
import {
    ColumnDef,
    SortingState,
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";

import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "../ui/table.tsx"; // Import ShadCN table components

import { Button } from "../ui/button.tsx"; // Button for sorting
import { ChevronsUpDown } from "lucide-react";
import { Mail, Phone, CalendarCheck, User } from "lucide-react"; // Lucide Icons

// Function to generate initials from name
const getInitials = (name: string) => {
    const parts = name.trim().split(" ");
    return parts.length > 1
        ? `${parts[0][0]}${parts[1][0]}`.toUpperCase()
        : `${parts[0][0]}${parts[0][1] || ""}`.toUpperCase();
};

// Mock Data
const contactsData = [
    {
        id: "1",
        name: "Alice Bob",
        email: "alice@example.com",
        phone: "+123456789",
        dateRecorded: "2024-02-18",
    },
    {
        id: "2",
        name: "Charlie Doe",
        email: "charlie@example.com",
        phone: "+987654321",
        dateRecorded: "2024-02-17",
    },
    {
        id: "3",
        name: "Bob Smith",
        email: "bob@example.com",
        phone: "+1122334455",
        dateRecorded: "2024-02-16",
    },
    {
        id: "4",
        name: "Alice",
        email: "alice2@example.com",
        phone: "+9988776655",
        dateRecorded: "2024-02-15",
    },
    {
        id: "5",
        name: "Eve",
        email: "eve@example.com",
        phone: "+5544332211",
        dateRecorded: "2024-02-14",
    },
];

// Table Columns
const columns: ColumnDef<(typeof contactsData)[number]>[] = [
    {
        accessorKey: "name",
        header: ({ column }) => (
            <div className="flex items-center gap-2 ">
                <User className="w-4 h-4 mr-2" /> Name
                {/*  <Button
                    variant="ghost"
                    onClick={() =>
                        column.toggleSorting(column.getIsSorted() === "asc")
                    }
                >
                    <ChevronsUpDown size={16} />
                </Button> */}
            </div>
        ),
        cell: ({ row }) => (
            <div className="flex items-center space-x-3">
                {/* Initials Box */}
                <div className="flex items-center justify-center w-[26px] h-[26px] font-normal text-[#2563EB] text-xs bg-[#2563EB1A] rounded-[4.73px]">
                    {getInitials(row.getValue("name"))}
                </div>
                <span>{row.getValue("name")}</span>
            </div>
        ),
    },
    {
        accessorKey: "email",
        header: ({ column }) => (
            <div className="flex items-center gap-2 ">
                <Mail className="w-4 h-4 mr-2" /> Email
                {/*  <Button
                    variant="ghost"
                    onClick={() =>
                        column.toggleSorting(column.getIsSorted() === "asc")
                    }
                >
                    <ChevronsUpDown size={16} />
                </Button> */}
            </div>
        ),
        cell: ({ row }) => <span>{row.getValue("email")}</span>,
    },
    {
        accessorKey: "phone",
        header: ({ column }) => (
            <div className="flex items-center gap-2 ">
                <Phone className="w-4 h-4 mr-2" /> Phone
                {/* <Button
                    variant="ghost"
                    onClick={() =>
                        column.toggleSorting(column.getIsSorted() === "asc")
                    }
                >
                    <ChevronsUpDown size={16} />
                </Button> */}
            </div>
        ),
        cell: ({ row }) => <span>{row.getValue("phone")}</span>,
    },
    {
        accessorKey: "dateRecorded",
        header: ({ column }) => (
            <div className="flex items-center gap-2 ">
                <CalendarCheck className="w-4 h-4 mr-2" /> Date Recorded
                {/* <Button
                    variant="ghost"
                    onClick={() =>
                        column.toggleSorting(column.getIsSorted() === "asc")
                    }
                >
                    <ChevronsUpDown size={16} />
                </Button> */}
            </div>
        ),
        cell: ({ row }) => <span>{row.getValue("dateRecorded")}</span>,
    },
];

export default function ContactsDataTable() {
    const [sorting, setSorting] = useState<SortingState>([]);

    const table = useReactTable({
        data: contactsData,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        onSortingChange: setSorting,
        state: { sorting },
    });

    return (
        <div className="w-full border rounded-md shadow-sm">
            <Table>
                <TableHeader>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <TableRow key={headerGroup.id}>
                            {headerGroup.headers.map((header) => (
                                <TableHead
                                    key={header.id}
                                    className="sticky top-0 text-sm font-medium text-left"
                                >
                                    {header.isPlaceholder
                                        ? null
                                        : flexRender(
                                              header.column.columnDef.header,
                                              header.getContext(),
                                          )}
                                </TableHead>
                            ))}
                        </TableRow>
                    ))}
                </TableHeader>
                <TableBody>
                    {table.getRowModel().rows.length ? (
                        table.getRowModel().rows.map((row) => (
                            <TableRow key={row.id}>
                                {row.getVisibleCells().map((cell) => (
                                    <TableCell key={cell.id}>
                                        {flexRender(
                                            cell.column.columnDef.cell,
                                            cell.getContext(),
                                        )}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))
                    ) : (
                        <TableRow>
                            <TableCell
                                colSpan={columns.length}
                                className="py-4 text-center"
                            >
                                No contacts available.
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </div>
    );
}
