import React, { useState, useMemo } from "react";
import {
    ComposableMap,
    Geographies,
    Geography,
    Marker,
} from "react-simple-maps";
import data from "./features.json";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "../ui/tooltip.tsx";
import { Countries } from "../../Constants/countries.ts";
import { NoDataError } from "../../Views/FormAnalyticsView.tsx";

const WorldMap = ({ geoLocationData, geoLocationErrors }) => {
    const [activeTooltip, setActiveTooltip] = useState<string | null>(null); // State to track the active tooltip
    const getCountryFlagByCountryName = (countryName: string): string => {
        const country = Countries.find(
            (country) => country.name === countryName,
        );
        return country?.emoji || "";
    };

    const maxSubmissionsCountry = useMemo(() => {
        let max = 0;
        let country = "";
        geoLocationData?.data?.chart_data?.forEach((location) => {
            if (location.total_submissions > max) {
                max = location.total_submissions;
                country = location.country;
            }
        });
        return country;
    }, [geoLocationData]);

    return (
        <section className="flex justify-center items-center w-full">
            <div className=" border w-full p-6  border-[#E5E7EB] flex flex-col rounded-[8px] shadow-md">
                <div className="flex flex-col">
                    <h1 className=" font-semibold text-base text-[#191B33]">
                        Submissions Demographics
                    </h1>
                    <p className="text-[#626F86] text-sm">
                        Total form submissions trend
                    </p>
                </div>
                <div className="flex justify-center items-center min-h-[400px] w-full">
                    {geoLocationErrors === "success" ? (
                        <ComposableMap
                            projection="geoEqualEarth"
                            projectionConfig={{
                                scale: 250, // Controls size of the map
                                center: [10, 10], // Adjust centering
                            }}
                            style={{
                                width: "100%",
                                maxWidth: "800px",
                                height: "400px",
                            }} // Set fixed height
                        >
                            <Geographies geography={data}>
                                {({ geographies }) =>
                                    geographies.map((geo) => (
                                        <Geography
                                            fill="#DCDFE4"
                                            stroke="#FFFFFF"
                                            key={geo.rsmKey}
                                            geography={geo}
                                            style={{
                                                default: {
                                                    fill: "#DCDFE4",
                                                    outline: "none",
                                                },
                                                hover: {
                                                    fill: "#DCDFE4",
                                                    outline: "none",
                                                },
                                                pressed: {
                                                    fill: "#DCDFE4",
                                                    outline: "none",
                                                },
                                            }}
                                        />
                                    ))
                                }
                            </Geographies>

                            {geoLocationData?.data?.chart_data?.map(
                                (location: any, index: number) => (
                                    <TooltipProvider>
                                        <Marker
                                            coordinates={location?.coordinates}
                                            key={index}
                                        >
                                            <Tooltip
                                                open={
                                                    activeTooltip
                                                        ? activeTooltip ===
                                                          location?.country
                                                        : maxSubmissionsCountry ===
                                                          location?.country
                                                }
                                            >
                                                <TooltipTrigger asChild>
                                                    <svg
                                                        width="41"
                                                        height="41"
                                                        viewBox="0 0 41 41"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="cursor-pointer"
                                                        onMouseEnter={() =>
                                                            setActiveTooltip(
                                                                location?.country,
                                                            )
                                                        } // Set the active tooltip when hovered
                                                        onMouseLeave={() =>
                                                            setActiveTooltip(
                                                                null,
                                                            )
                                                        } // Reset the active tooltip when not hovered
                                                    >
                                                        <circle
                                                            opacity="0.16"
                                                            cx="20.4612"
                                                            cy="20.3984"
                                                            r="20"
                                                            fill={
                                                                maxSubmissionsCountry ===
                                                                location?.country
                                                                    ? "#4CAF50"
                                                                    : "#2563EB"
                                                            }
                                                        />
                                                        <circle
                                                            opacity="0.32"
                                                            cx="20.4612"
                                                            cy="20.3984"
                                                            r="12"
                                                            fill={
                                                                maxSubmissionsCountry ===
                                                                location?.country
                                                                    ? "#4CAF50"
                                                                    : "#2563EB"
                                                            }
                                                        />
                                                        <g filter="url(#filter0_d_4556_9439)">
                                                            <rect
                                                                x="16.4612"
                                                                y="16.3984"
                                                                width="8"
                                                                height="8"
                                                                rx="4"
                                                                fill={
                                                                    maxSubmissionsCountry ===
                                                                    location?.country
                                                                        ? "#4CAF50"
                                                                        : "#2563EB"
                                                                }
                                                            />
                                                            <rect
                                                                x="15.9612"
                                                                y="15.8984"
                                                                width="9"
                                                                height="9"
                                                                rx="4.5"
                                                                stroke="white"
                                                            />
                                                        </g>
                                                        <defs>
                                                            <filter
                                                                id="filter0_d_4556_9439"
                                                                x="13.4612"
                                                                y="14.3984"
                                                                width="14"
                                                                height="14"
                                                                filterUnits="userSpaceOnUse"
                                                                colorInterpolationFilters="sRGB"
                                                            >
                                                                <feFlood
                                                                    floodOpacity="0"
                                                                    result="BackgroundImageFix"
                                                                />
                                                                <feColorMatrix
                                                                    in="SourceAlpha"
                                                                    type="matrix"
                                                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                                    result="hardAlpha"
                                                                />
                                                                <feOffset dy="1" />
                                                                <feGaussianBlur stdDeviation="1" />
                                                                <feColorMatrix
                                                                    type="matrix"
                                                                    values="0 0 0 0 0.0901961 0 0 0 0 0.0901961 0 0 0 0 0.0901961 0 0 0 0.05 0"
                                                                />
                                                                <feBlend
                                                                    mode="normal"
                                                                    in2="BackgroundImageFix"
                                                                    result="effect1_dropShadow_4556_9439"
                                                                />
                                                                <feBlend
                                                                    mode="normal"
                                                                    in="SourceGraphic"
                                                                    in2="effect1_dropShadow_4556_9439"
                                                                    result="shape"
                                                                />
                                                            </filter>
                                                        </defs>
                                                    </svg>
                                                </TooltipTrigger>
                                                <TooltipContent className="p-2 bg-white rounded-md shadow-md">
                                                    <p className="mb-1 text-sm font-bold text-primary">
                                                        {getCountryFlagByCountryName(
                                                            location?.country,
                                                        )}{" "}
                                                        {location?.country}
                                                    </p>
                                                    <p className="text-xs text-[#475569]">
                                                        Total Submissions :{" "}
                                                        {
                                                            location?.total_submissions
                                                        }{" "}
                                                    </p>
                                                    {location?.total_sumbissions
                                                        ?.to_locations && (
                                                        <p className="text-xs text-[#475569]">
                                                            Top Locations:{" "}
                                                            {
                                                                location
                                                                    ?.total_sumbissions
                                                                    ?.to_locations
                                                            }{" "}
                                                        </p>
                                                    )}
                                                </TooltipContent>
                                            </Tooltip>
                                        </Marker>
                                    </TooltipProvider>
                                ),
                            )}
                        </ComposableMap>
                    ) : (
                        <NoDataError />
                    )}
                </div>
            </div>
        </section>
    );
};

export default WorldMap;
