import { Select, Flex, Typography } from "antd";
import React, { useRef, useState, useEffect } from "react";
import { Input } from "antd";
import {
    answerCheckboxTextSize,
    getInputStyle,
    getSelectOptionBackgroundColor,
    getSelectOptionBorderColor,
} from "../../../Constants/styles";
import { useFormContext } from "../../../Contexts/FormContext";
import { CountryEmoji } from "../../../Utils/countries";
import { getCountryCallingCode, getCountries } from "react-phone-number-input";
import { useCurrentCountry } from "../../../Api/useCurrentCountry";
import { motion } from "framer-motion";
import { CaretDownFilled } from "@ant-design/icons";

const countryCodes = getCountries().reduce(
    (o, key) => Object.assign(o, { [key]: `+${getCountryCallingCode(key)}` }),
    {},
);

export const CountrySelect = React.forwardRef(
    ({ country, setCountry, ...props }, ref) => {
        const { userCountryCode: countryCode } = useCurrentCountry();
        const [focusInput, setFocusInput] = useState(false);
        const { styles } = useFormContext();

        useEffect(() => {
            if (countryCode && countryCode != "XX") {
                setCountry(countryCode);
            }
        }, [countryCode]);

        const selectRef = useRef(null);
        const [inputValue, setValue] = useState("");

        const f_options = props.options.filter((o) => o.value);

        const options = !inputValue
            ? f_options
            : f_options.filter(
                  (o) =>
                      o?.label
                          ?.toUpperCase()
                          .indexOf(inputValue?.toUpperCase()) !== -1,
              );

        return (
            <>
                <style>
                    {`.ant-select-selector {
            padding-left: 0px !important;
          }`}
                </style>
                <Select
                    suffixIcon={<></>}
                    popupMatchSelectWidth={false}
                    onChange={(value) => {
                        setCountry(value);
                    }}
                    onDropdownVisibleChange={(open) => {
                        setFocusInput(open);
                    }}
                    value={country || "rc-index-key-0"}
                    ref={selectRef}
                    variant="borderless"
                    options={options}
                    optionRender={Option}
                    style={{ width: "auto" }}
                    labelRender={(value) => {
                        return (
                            <Flex
                                style={{
                                    alignItems: "center",
                                }}
                                gap={7}
                            >
                                <Flex
                                    style={{
                                        alignItems: "center",
                                    }}
                                    gap={7}
                                >
                                    <div
                                        style={{
                                            fontSize: 35,
                                            marginTop: "5px",
                                        }}
                                    >
                                        {<CountryEmoji code={value.key} />}
                                    </div>
                                    <CaretDownFilled
                                        style={{
                                            color: styles.secondary_color,
                                            marginTop: "5px",
                                        }}
                                    />
                                </Flex>
                            </Flex>
                        );
                    }}
                    dropdownRender={(menu) => {
                        console.log("dropdown render", menu, { focusInput });
                        return (
                            <DropDown
                                value={inputValue}
                                setValue={setValue}
                                focus={focusInput}
                            >
                                {menu}
                            </DropDown>
                        );
                    }}
                />
            </>
        );
    },
);

const Option = (option) => {
    const { styles } = useFormContext();
    const color = styles.secondary_color;

    return (
        <motion.div key={option.key} layoutScroll>
            <Flex
                style={{
                    border: `1px solid #fff`,
                    borderWidth: 1,
                    borderColor: getSelectOptionBorderColor(color),
                    borderRadius: 5,
                    padding: "2px 10px 2px 10px",
                    alignItems: "center",
                    backgroundColor: getSelectOptionBackgroundColor(color),
                    justifyContent: "space-between",
                }}
                gap={"large"}
            >
                <Flex style={{ alignItems: "center" }} gap={"middle"}>
                    <div style={{ fontSize: 30 }}>
                        {<CountryEmoji code={option.key} />}
                    </div>
                    <Typography.Text
                        style={{
                            textAlign: "justify",
                            color: color,
                            fontSize: answerCheckboxTextSize,
                        }}
                    >
                        {option.label}
                    </Typography.Text>
                </Flex>
                <Typography.Text
                    style={{
                        textAlign: "justify",
                        color: color,
                        fontSize: answerCheckboxTextSize,
                    }}
                >
                    {countryCodes[option.key]}
                </Typography.Text>
            </Flex>
        </motion.div>
    );
};

export const DropDown = ({ children, value, setValue, focus }) => {
    const inputRef = useRef(null);
    useEffect(() => {
        if (focus) {
            setTimeout(() => {
                inputRef.current.focus();
            }, 500);
        } else {
            setF(false);
        }
    }, [focus]);
    const { styles } = useFormContext();
    console.log({ focus });
    return (
        <Flex vertical>
            <div style={{ paddingInline: "15px" }}>
                <Input
                    ref={inputRef}
                    style={{ ...getInputStyle(styles, true) }}
                    size="large"
                    value={value}
                    onChange={(e) => {
                        setValue(e.target.value);
                    }}
                    onFocus={() => {
                        console.log("focus");
                    }}
                    onBlur={() => {
                        console.log("blur");
                    }}
                    placeholder="Search country"
                />
            </div>
            {children}
        </Flex>
    );
};
