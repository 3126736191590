import React, { useState, useCallback } from "react";

import { Link } from "react-router-dom";

import { Button } from "./ui/button.tsx";
import { Dialog, DialogContent } from "./ui/dialog.tsx";
import { Card, CardContent, CardFooter } from "./ui/card.tsx";
import { Badge } from "./ui/badge.tsx";
import { Label } from "./ui/label.tsx";
import { Switch } from "./ui/switch.tsx";

import { CheckIcon, CircleArrowRightIcon, ZapIcon } from "lucide-react";

import { cn } from "../lib/utils.js";

import { loadStripe } from "@stripe/stripe-js";
import {
    EmbeddedCheckoutProvider,
    EmbeddedCheckout,
} from "@stripe/react-stripe-js";

import clsx from "clsx";

const API_VERSION: any = process.env.REACT_APP_API_VERSION;
let BACKEND_URL: string = process.env.REACT_APP_SERVICE_URL + API_VERSION;
const STRIPE_PUBLIC_KEY: string = process.env.REACT_APP_STRIPE_PUBLIC_KEY;

import { useAuth } from "@clerk/clerk-react";

const template = "custom_jwt_template";

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

type FixedPrice = string;

interface VariablePrice {
    monthly: string;
    annually: string;
}

interface Plan {
    name: string;
    price: FixedPrice | VariablePrice;
    description: string;
    capacity: string[];
    features: string[];
    isStarter: boolean;
    isRecommended: boolean;
    buttonText: string;
    buttonLink: string;
}

const plans: Plan[] = [
    {
        name: "Starter",
        price: "$0",
        description:
            "For individuals and freelancers that need a scalable database.",
        capacity: ["Up to 5 users, 1 admin", "1 workspace"],
        features: [
            "Unlimited Forms",
            "Unlimited Submissions",
            "Partial Submissions & Tracking",
            "File Uploads - Supports multiple file upload*",
            "AI Form Creation",
            //"AI Form Improvement",
            "Basic Analytics & Insights",
            //"Custom ‘Thank You’ Page",
            "Embed Images & Videos",
            //"Custom Branding",
            //"Multi-user Access",
            //"Webhooks",
            "Google Sheets Integration",
            "Zapier Integration",
            "Calendly integration",
        ],
        isStarter: true,
        isRecommended: false,
        buttonText: "Get started",
        buttonLink: "#",
    },
    {
        name: "Pro",
        price: { monthly: "$49", annually: "$39" },
        description:
            "For larger teams that need more advanced controls and features.",
        capacity: ["Up to 500 users, 10 admins", "Unlimited workspaces"],
        features: [
            "Everything in Starter",
            "Larger File Uploads (100MB+)",
            "Advanced Analytics",
            "Redirect to URL",
            //"Custom Domains",
            "Advanced Branding (Remove Buildform.ai Branding)",
            //"Data Retention & Security Controls",
            "Premium Integrations (GA4, Meta Pixel, CRM tools, etc.)",
            "API Access",
            "Priority Support",
            //"Calendly integration",
        ],
        isStarter: false,
        isRecommended: true,
        buttonText: "Subscribe",
        buttonLink: "#",
    },
];

type PricingModalProps = {
    isOpen: boolean;
    onClose: () => void;
};

const isVariablePrice = (
    price: FixedPrice | VariablePrice,
): price is VariablePrice => {
    return (price as VariablePrice).monthly !== undefined;
};

export function PricingModal({ isOpen, onClose }: PricingModalProps) {
    const [billingFrequency, setBillingFrequency] = useState<
        "monthly" | "annually"
    >("monthly");

    const [showEmbedded, setShowEmbedded] = useState<boolean>(false);

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent
                className={clsx(
                    showEmbedded ? "max-w-[1040px]" : "max-w-[814px]",
                    "gap-0 top-[50%] z-[9999] px-2 py-2  w-full  rounded-[16px] border-none outline-none ",
                )}
                onInteractOutside={(e) => {
                    e.preventDefault();
                    onClose();
                    if (showEmbedded) {
                        setShowEmbedded(false);
                    }
                }}
            >
                {showEmbedded ? (
                    <PaymentSection
                        planType="pro"
                        planSubscriptionType={billingFrequency}
                    />
                ) : (
                    <>
                        <section
                            aria-labelledby="pricing-title"
                            className="mx-auto text-center animate-slide-up-fade w-fit"
                            style={{
                                animationDuration: "600ms",
                                animationFillMode: "backwards",
                            }}
                        >
                            <h1 className="mt-2 inline-block text-primary bg-clip-text font-bold tracking-tighter text-[36px]">
                                Our plans scale with you
                            </h1>
                            <p className="mt-2 max-w-2xl text-sm text-gray-700">
                                Plans that empower you and your team to ship
                                without friction. Our flexible pricing models
                                ensure that efficiency doesn&rsquo;t come at the
                                cost of your budget.
                            </p>
                        </section>
                        <section
                            id="pricing-overview"
                            className="mx-auto mt-6 animate-slide-up-fade w-fit"
                            aria-labelledby="pricing-overview"
                            style={{
                                animationDuration: "600ms",
                                animationDelay: "200ms",
                                animationFillMode: "backwards",
                            }}
                        >
                            <div className="flex relative gap-2 justify-center items-center mx-auto mt-2.5 w-fit">
                                <Label
                                    htmlFor="switch"
                                    className="text-base font-medium text-gray-400 sm:text-sm"
                                >
                                    Monthly
                                </Label>
                                <Switch
                                    id="switch"
                                    checked={billingFrequency === "annually"}
                                    className="data-[state=checked]:bg-[#CF9FFF]"
                                    onCheckedChange={() =>
                                        setBillingFrequency(
                                            billingFrequency === "monthly"
                                                ? "annually"
                                                : "monthly",
                                        )
                                    }
                                />
                                <Label
                                    htmlFor="switch"
                                    className="text-base font-medium sm:text-sm"
                                >
                                    Yearly
                                </Label>
                                <div className="absolute -top-8 -right-16">
                                    <Badge className="text-xs text-[#8B22C3] bg-[#FAF0FF] px-2 hover:bg-[#FAF0FF]">
                                        20% OFF
                                    </Badge>
                                    <img
                                        src="/spiral-arrow.svg"
                                        alt="Spiral Arrow"
                                        className="w-[24px] ml-3"
                                    />
                                </div>
                            </div>
                            <div className="grid grid-cols-1 gap-x-4 gap-y-6 mt-6 lg:grid-cols-2">
                                {plans.map((plan, planIdx) => (
                                    <Card
                                        key={planIdx}
                                        className={cn(
                                            "border-gray-200 max-w-[367px] p-6 flex flex-col justify-between rounded-[12px] shadow-none",
                                            plan.isRecommended &&
                                                "border-[#CF9FFF]",
                                        )}
                                    >
                                        <CardContent className="p-0">
                                            <div className="">
                                                <div className="flex flex-row justify-between items-center">
                                                    <h2 className="font-semibold text-gray-900 text-md">
                                                        {plan.name}
                                                    </h2>
                                                    {plan.isRecommended && (
                                                        <Badge className="text-xs text-[#8B22C3] bg-[#FAF0FF] hover:bg-[#FAF0FF]">
                                                            <ZapIcon
                                                                fill="#8B22C3"
                                                                className="w-3 h-3 text-[#8B22C3] mr-[2px]"
                                                            />
                                                            Most Popular
                                                        </Badge>
                                                    )}
                                                </div>
                                                <div className="mt-1 flex items-center gap-x-[6px]">
                                                    <span className="text-[38px] font-bold tabular-nums text-gray-900">
                                                        {isVariablePrice(
                                                            plan.price,
                                                        )
                                                            ? billingFrequency ===
                                                              "monthly"
                                                                ? plan.price
                                                                      .monthly
                                                                : plan.price
                                                                      .annually
                                                            : plan.price}
                                                    </span>
                                                    <div className="text-xs text-gray-700">
                                                        per user/monthly
                                                    </div>
                                                </div>
                                                <div className="flex flex-col justify-between mt-3">
                                                    <p className="text-sm leading-6 text-gray-600">
                                                        {plan.description}
                                                    </p>
                                                </div>
                                                <ul
                                                    role="list"
                                                    className="mt-3 text-sm text-gray-700 gap-y-[14px]"
                                                >
                                                    {plan.features.map(
                                                        (feature) => (
                                                            <li
                                                                key={feature}
                                                                className="flex items-center gap-x-[6px] py-1.5"
                                                            >
                                                                <div className="bg-[#5DC38E] w-4 h-4 flex items-center justify-center rounded-full">
                                                                    <CheckIcon
                                                                        className="text-white size-3 shrink-0"
                                                                        aria-hidden="true"
                                                                    />
                                                                </div>
                                                                <span>
                                                                    {feature}
                                                                </span>
                                                            </li>
                                                        ),
                                                    )}
                                                </ul>
                                            </div>
                                        </CardContent>
                                        <CardFooter className="p-0 mt-5 w-full">
                                            {plan.isStarter ? (
                                                <Button
                                                    variant="outline"
                                                    asChild
                                                    className="w-full h-12 group"
                                                >
                                                    <Link to={plan.buttonLink}>
                                                        {plan.buttonText}
                                                        <CircleArrowRightIcon className="ml-2 w-5 h-5" />
                                                    </Link>
                                                </Button>
                                            ) : (
                                                <Button
                                                    asChild
                                                    className="w-full h-12 group"
                                                    onClick={() => {
                                                        setShowEmbedded(true);
                                                    }}
                                                >
                                                    <Link to={plan.buttonLink}>
                                                        {plan.buttonText}
                                                        <CircleArrowRightIcon className="ml-2 w-5 h-5" />
                                                    </Link>
                                                </Button>
                                            )}
                                        </CardFooter>
                                    </Card>
                                ))}
                            </div>
                        </section>
                    </>
                )}
            </DialogContent>
        </Dialog>
    );
}

const PaymentSection = ({
    planType,
    planSubscriptionType,
}: {
    planType: string;
    planSubscriptionType: string;
}) => {
    const { getToken } = useAuth();
    const fetchClientSecret = useCallback(async () => {
        // Retrieve the token
        const token = await getToken({ template });

        // Create a Checkout Session
        return fetch(
            `${BACKEND_URL}/payment/create-checkout-session?plan_type=${planType}&plan_subscription_type=${planSubscriptionType}&return_url=${window.location.href}`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            },
        )
            .then((res) => res.json())
            .then((data) => data.client_secret);
    }, [getToken]);

    const options = { fetchClientSecret };

    return (
        <div id="checkout">
            <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
                <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
        </div>
    );
};
