"use client";
import React from "react";
import {
    Bar,
    BarChart,
    CartesianGrid,
    XAxis,
    AreaChart,
    Area,
    YAxis,
    LabelList,
} from "recharts";
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from "../ui/card.tsx";
import {
    ChartConfig,
    ChartContainer,
    ChartTooltip,
    ChartTooltipContent,
} from "../ui/chart.tsx";
import { ResponsiveContainer } from "recharts";
import { NoDataError } from "../../Views/FormAnalyticsView.tsx";
import { ErrorBoundary } from "react-error-boundary";

const chartConfig = {
    desktop: {
        label: "Desktop",
        color: "hsl(var(--chart-1))",
    },
} satisfies ChartConfig;

const AverageTime = ({
    submissionAverage,
    timetoComplete,
    submissionAverageErrors,
    timetoCompleteErrors,
}) => {
    const formattedSubmissionAverageData =
        submissionAverage?.data?.chart_data?.map((item: any) => ({
            period: item.x_axis,
            submissions: item.y_axis,
        }));

    const formattedTimeToCompleteData = timetoComplete?.data?.chart_data?.map(
        (item: any) => ({
            period: item.x_axis,
            submissions: item.y_axis,
        }),
    );

    return (
        <section className="grid grid-cols-4 gap-3 max-h-[316px]">
            <div className="col-span-1">
                <Card className=" h-[316px]">
                    <CardHeader>
                        <CardTitle>Submission Average</CardTitle>
                        <CardDescription>
                            {submissionAverage?.data?.chart_description}
                        </CardDescription>
                    </CardHeader>
                    <CardContent className=" h-[182px]">
                        <ErrorBoundary fallback={<NoDataError />}>
                            {submissionAverageErrors === "success" ? (
                                <ResponsiveContainer width="100%" height="100%">
                                    <ChartContainer config={chartConfig}>
                                        <BarChart
                                            accessibilityLayer
                                            data={
                                                formattedSubmissionAverageData
                                            }
                                        >
                                            <CartesianGrid vertical={false} />
                                            <XAxis
                                                dataKey="period"
                                                tickLine={false}
                                                tickMargin={10}
                                                axisLine={false}
                                                tickFormatter={(value) =>
                                                    value.slice(0, 3)
                                                }
                                            />
                                            <ChartTooltip
                                                cursor={false}
                                                content={
                                                    <ChartTooltipContent
                                                        hideLabel
                                                    />
                                                }
                                            />
                                            <Bar
                                                dataKey="submissions"
                                                fill="#CF9FFF80"
                                                radius={8}
                                            />
                                        </BarChart>
                                    </ChartContainer>
                                </ResponsiveContainer>
                            ) : (
                                <NoDataError height={200} />
                            )}
                        </ErrorBoundary>
                    </CardContent>
                </Card>
            </div>
            <div className="overflow-hidden col-span-3 w-full h-full">
                <Card className="h-[316px]">
                    <CardHeader>
                        <CardTitle className=" text-[#191B33] ">
                            Time to Complete
                        </CardTitle>
                        <CardDescription>
                            {timetoComplete?.data?.chart_description}
                        </CardDescription>
                    </CardHeader>
                    <ErrorBoundary fallback={<NoDataError height={200} />}>
                        {timetoCompleteErrors === "success" ? (
                            <CardContent className="h-[200px] ">
                                <ResponsiveContainer width="100%" height="100%">
                                    <ChartContainer config={chartConfig}>
                                        <AreaChart
                                            accessibilityLayer
                                            data={formattedTimeToCompleteData}
                                            margin={{
                                                left: 12,
                                                right: 12,
                                            }}
                                        >
                                            <CartesianGrid vertical={false} />
                                            <YAxis
                                                dataKey="submissions"
                                                type="number"
                                                tickLine={true}
                                                tickMargin={10}
                                                axisLine={false}
                                            />
                                            <XAxis
                                                dataKey="period"
                                                tickLine={false}
                                                axisLine={false}
                                                tickMargin={8}
                                                tickFormatter={(value) =>
                                                    value.slice(0, 3)
                                                }
                                            />
                                            <ChartTooltip
                                                cursor={false}
                                                content={
                                                    <ChartTooltipContent
                                                        indicator="dot"
                                                        hideLabel
                                                    />
                                                }
                                            />

                                            <Area
                                                dataKey="submissions"
                                                type="linear"
                                                stackId="1"
                                                fill="#96BDFF"
                                                fillOpacity={0.2}
                                                stroke="#2563EB"
                                                dot={{ r: 4, fill: "#2563EB" }} // Show dots at data points
                                            >
                                                <LabelList
                                                    dataKey="submissions"
                                                    position="top"
                                                    className="text-xs fill-black"
                                                />
                                            </Area>
                                        </AreaChart>
                                    </ChartContainer>
                                </ResponsiveContainer>
                            </CardContent>
                        ) : (
                            <NoDataError height={200} />
                        )}
                    </ErrorBoundary>
                </Card>
            </div>
        </section>
    );
};

export default AverageTime;
