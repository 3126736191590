"use strict";

import React from "react";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { useFormEditorContext } from "../../Contexts/FormEditorContext";
import WelcomeScreenSettings from "../MyEditor/WelcomeScreenSettings.jsx";
import { FormHeaderFormat } from "../../Components/Form/FormHeader";
import { Button } from "../ui/button.tsx";
import { FIELD_TYPE_SETTINGS } from "../../Constants/questionTypes.js";
import { FieldSettingEditor } from "../FormEditor/Fields/FieldSetting";
import QuestionScreen from "../FormEditor/Questions/QuestionScreen.tsx";
import WelcomeScreen from "../FormEditor/WelcomeScreen.tsx";
import EndScreen from "../FormEditor/EndScreen.tsx";
import EndScreenSettings from "./EndScreenSettings.jsx";
import { ChevronDownIcon, ChevronLeftIcon, ChevronUpIcon } from "lucide-react";
import useStyles from "../../Hooks/useStyles.ts";
import { XIcon } from "lucide-react";
import { useUser } from "@clerk/clerk-react";

const questionAnimation = {
    initial: {
        opacity: 0,
        y: 400,
        x: 0, // Ensure horizontal position stays centered
        scale: 0.95, // Slight scale down for a smoother entry
    },
    animate: {
        opacity: 1,
        y: 0,
        x: 0,
        scale: 1,
    },
    exit: {
        opacity: 0,
        y: 40,
        x: 0,
        scale: 0.95,
    },
    transition: {
        type: "tween", // Use tween for more precise control
        duration: 0.5,
        ease: "easeOut",
    },
};

const ContentMainSection = () => {
    const { selectedField, fields, theme, device, formSettings } =
        useFormEditorContext();
    const index = fields?.findIndex((field) => field?.id === selectedField?.id);
    const showProgressBar = formSettings?.show_progress_bar;
    const [isAnimating, setIsAnimating] = React.useState(false);

    React.useEffect(() => {
        setIsAnimating(true);
    }, [device]);

    React.useEffect(() => {
        setIsAnimating(false);
    }, []);

    return (
        <div
            className="overflow-hidden relative w-full h-full"
            style={
                device === "mobile"
                    ? {
                          backgroundImage: `url('/dot-pattern.png')`,
                          backgroundSize: "cover",
                      }
                    : {}
            }
        >
            <div className="absolute top-0 right-0 z-20 justify-items-end w-full">
                {selectedField === "welcome-screen" ? (
                    <WelcomeScreenSettings />
                ) : selectedField === "end-screen" ? (
                    <EndScreenSettings />
                ) : (
                    <QuestionSettings />
                )}
            </div>
            <div
                className={clsx(
                    "absolute top-1 left-0 right-0 z-10 w-full px-[3.5px]",
                    device === "mobile" ? "hidden" : "",
                )}
            >
                {selectedField !== "welcome-screen" &&
                    selectedField !== "end-screen" &&
                    selectedField && (
                        <FormHeaderFormat
                            showPercentage={selectedField !== "welcome-screen"}
                            percentage={((index + 1) / fields.length) * 100}
                            strokeColor={
                                theme?.button_color || "rgb(208, 252, 3)"
                            }
                            showProgressBar={showProgressBar}
                        />
                    )}
            </div>

            <div
                className={clsx(
                    "w-full h-full flex items-center justify-center",
                    device === "mobile" ? "absolute inset-0" : "relative",
                )}
            >
                <motion.div
                    className={clsx(
                        "flex flex-col items-center justify-center overflow-hidden",
                        device === "mobile"
                            ? "rounded-[12px] border border-gray-100 drop-shadow-md"
                            : "",
                    )}
                    initial={false}
                    layout
                    style={{
                        backgroundColor: theme?.background_color,
                        width: device === "mobile" ? "362px" : "100%",
                        height: device === "mobile" ? "672px" : "100%",
                        transformOrigin: "center center",
                    }}
                    transition={{
                        layout: {
                            type: "tween",
                            stiffness: 100,
                            damping: 20,
                        },
                    }}
                    onLayoutAnimationComplete={() => setIsAnimating(false)}
                    onLayoutAnimationStart={() => setIsAnimating(true)}
                >
                    {/* Content remains the same */}
                    <div
                        className={clsx(
                            "absolute top-1 left-0 right-0 z-10 w-full px-[3.5px]",
                            isAnimating ? " opacity-0" : "",
                        )}
                    >
                        {selectedField !== "welcome-screen" &&
                            selectedField !== "end-screen" &&
                            selectedField && (
                                <FormHeaderFormat
                                    showPercentage={
                                        selectedField !== "welcome-screen"
                                    }
                                    percentage={
                                        ((index + 1) / fields.length) * 100
                                    }
                                    strokeColor={
                                        theme?.button_color ||
                                        "rgb(208, 252, 3)"
                                    }
                                    showProgressBar={showProgressBar}
                                />
                            )}
                    </div>

                    <div
                        className={clsx(
                            "flex overflow-hidden absolute top-0 right-0 bottom-0 left-0 flex-grow justify-center items-center w-full",
                            isAnimating ? "opacity-0" : "",
                        )}
                    >
                        <AnimatePresence mode="wait">
                            <div className="flex relative justify-center items-center w-full h-full">
                                {selectedField === "welcome-screen" ? (
                                    <motion.div
                                        key="welcome-screen"
                                        className="flex absolute justify-center items-center w-full h-full"
                                        {...questionAnimation}
                                    >
                                        <WelcomeScreen />
                                    </motion.div>
                                ) : selectedField === "end-screen" ? (
                                    <motion.div
                                        key="end-screen"
                                        className="absolute w-full flex justify-center items-center bottom-[45%]"
                                        {...questionAnimation}
                                    >
                                        <EndScreen />
                                    </motion.div>
                                ) : (
                                    <motion.div
                                        key={selectedField?.id}
                                        className="flex absolute justify-center items-center w-full h-full"
                                        {...questionAnimation}
                                    >
                                        <QuestionScreen />
                                    </motion.div>
                                )}
                            </div>
                        </AnimatePresence>
                    </div>

                    <div
                        className={clsx(
                            "absolute right-0 left-0 bottom-1 z-30",
                            isAnimating ? "opacity-0" : "",
                        )}
                    >
                        {selectedField !== "end-screen" && selectedField && (
                            <EditorFooter index={index} />
                        )}
                    </div>
                </motion.div>
            </div>
        </div>
    );
};

export default ContentMainSection;

const EditorFooter = ({ index }) => {
    const {
        fields,
        setSelectedField,
        theme,
        device,
        selectedField,
        welcomeScreen,
        setShowPricingModal,
    } = useFormEditorContext();
    const { user } = useUser();
    const isLastField = index === fields?.length - 1;
    const { getStyles } = useStyles();

    const nextField = fields[index + 1];
    const previousField = fields[index - 1];

    const handleFieldChange = (field) => {
        setSelectedField(field);
    };

    const nextButtonPress = () => {
        handleFieldChange(isLastField ? "end-screen" : nextField);
    };

    const previous = () => {
        handleFieldChange(index === 0 ? "welcome-screen" : previousField);
    };

    const onStartButtonPress = () => {
        if (fields?.length === 0) {
            setSelectedField("end-screen");
        } else {
            handleFieldChange(nextField);
        }
    };

    const isProCustomer = user?.publicMetadata?.pro_subscriber;

    const handleRemoveBranding = () => {
        if (isProCustomer) {
            console.log("");
        } else {
            setShowPricingModal(true);
        }
    };

    if (device === "mobile" && selectedField === "welcome-screen") {
        return (
            <div className=" p-4 rounded-t-[12px] absolute flex items-center justify-center w-full -bottom-2.5 bg-[#0000000c]">
                <Button
                    className="w-full"
                    variant={"ghost"}
                    style={{
                        backgroundColor: theme?.button_color,
                        color: theme?.button_text_color,
                        borderRadius: theme?.rounded_corners,
                        fontFamily: `${theme?.font_family?.family}, ${theme?.font_family?.category}`,
                        fontWeight: theme?.is_bold ? "600" : "400",
                        fontSize: getStyles("button", theme?.button_size)
                            .fontSize,
                        height: getStyles("button", theme?.button_size).height,
                    }}
                    onClick={onStartButtonPress}
                >
                    {welcomeScreen?.button_text || "Start"}
                </Button>
            </div>
        );
    }
    if (
        device === "mobile" &&
        selectedField !== "welcome-screen" &&
        selectedField !== "end-screen"
    ) {
        return (
            <div className=" p-4 rounded-t-[12px] absolute gap-3 flex flex-col items-center justify-center w-full -bottom-2.5 bg-[#0000000c]">
                <div className="flex gap-1 items-center w-full">
                    <Button
                        variant={"ghost"}
                        className="p-0 w-10 size-10"
                        style={{
                            backgroundColor: theme?.button_color,
                            color: theme?.button_text_color,
                            height: getStyles("button", theme?.button_size)
                                .height,
                            borderRadius: theme?.rounded_corners,
                        }}
                        onClick={previous}
                    >
                        <ChevronLeftIcon className="size-4" />
                    </Button>
                    <Button
                        className=" w-[285px]"
                        variant={"default"}
                        style={{
                            backgroundColor: theme?.button_color,
                            color: theme?.button_text_color,
                            borderRadius: theme?.rounded_corners,
                            fontFamily: `${theme?.font_family?.family}, ${theme?.font_family?.category}`,
                            fontWeight: theme?.is_bold ? "700" : "400",
                            fontSize: getStyles("button", theme?.button_size)
                                .fontSize,
                            height: getStyles("button", theme?.button_size)
                                .height,
                        }}
                        onClick={nextButtonPress}
                    >
                        {selectedField?.properties?.button_text || "Next"}
                    </Button>
                </div>
                <div className="flex gap-1 items-center text-xs">
                    <p className="text-gray-600">Powered by </p>
                    <p className=" font-semibold underline text-[#191B33]">
                        {" "}
                        Buildform
                    </p>
                </div>
            </div>
        );
    }

    return (
        <div
            className="relative py-4 w-full"
            style={{
                display: device === "mobile" ? "none" : "block",
            }}
        >
            {!isProCustomer && (
                <div
                    className={clsx(
                        "flex flex-row absolute bottom-1.5 left-[17px]  min-h-10 min-w-10 items-center gap-[6px] rounded-[8px]  backdrop-blur-[12px] border ",
                        "border-dashed border-[#CF9FFF]",
                    )}
                >
                    <div
                        className="flex flex-row gap-2 p-[6px] rounded-[8px] items-center"
                        style={{
                            backgroundColor: "rgba(0,0,0,0.03",
                        }}
                    >
                        <div className="h-8 w-8 rounded-[6px] bg-white p-2">
                            <img
                                src="/logo.svg"
                                alt="logo"
                                width={16}
                                height={16}
                            />
                        </div>
                        <div className="flex flex-col">
                            <p className="text-[10px] text-start text-gray-600">
                                Built with
                            </p>
                            <p className="-mt-1 text-[14px] font-extrabold text-[#191B33]">
                                Buildform
                            </p>
                        </div>
                    </div>

                    <Button
                        variant="ghost"
                        size={"sm"}
                        className="p-0 bg-transparent hover:bg-transparent size-9 hover:opacity-8"
                        onClick={handleRemoveBranding}
                    >
                        <XIcon className="size-4" />
                    </Button>
                </div>
            )}
            {isProCustomer &&
                !theme?.show_buildform_branding &&
                theme?.branding_logo_link && (
                    <div className="flex flex-row absolute bottom-1.5 left-[17px]  min-h-10 min-w-10 items-center gap-[6px] rounded-[8px]  backdrop-blur-[12px] border ">
                        <div
                            className="flex flex-row gap-2 p-[6px] rounded-[8px] items-center"
                            style={{
                                backgroundColor: "rgba(0,0,0,0.03",
                            }}
                        >
                            <div className="h-8 w-8 rounded-[6px] bg-white p-2">
                                <img
                                    src={theme?.branding_logo_link}
                                    alt="logo"
                                    width={16}
                                    height={16}
                                />
                            </div>
                        </div>
                    </div>
                )}
            <div className="flex absolute bottom-2 right-4 gap-1">
                <Button
                    icon="left"
                    variant="ghost"
                    size={"sm"}
                    className="p-0 size-9 hover:opacity-8"
                    onClick={previous}
                    style={{
                        backgroundColor: theme?.button_color,
                        color: theme?.button_text_color,
                    }}
                >
                    <ChevronUpIcon className="size-4" />
                </Button>
                <Button
                    icon="right"
                    variant="ghost"
                    size={"sm"}
                    className="p-0 size-9 hover:opacity-8"
                    onClick={nextButtonPress}
                    style={{
                        backgroundColor: theme?.button_color,
                        color: theme?.button_text_color,
                    }}
                >
                    <ChevronDownIcon className="size-4" />
                </Button>
            </div>
        </div>
    );
};

const QuestionSettings = () => {
    const { selectedField, setEditorField, editorField } =
        useFormEditorContext();
    const fieldTypeSettings = FIELD_TYPE_SETTINGS[selectedField?.type];

    const renderSettings = (settings, parentKey = "") => {
        if (!settings) return null;

        return Object.keys(settings).map((key) => {
            const value = settings[key];
            const fullKey = parentKey ? `${parentKey}.${key}` : key;

            if (typeof value === "object" && !Array.isArray(value)) {
                return (
                    <div key={fullKey}>{renderSettings(value, fullKey)}</div>
                );
            }

            return (
                <FieldSettingEditor
                    key={fullKey}
                    type={value}
                    label={fullKey}
                    setEditorField={setEditorField}
                    editorField={editorField}
                />
            );
        });
    };

    return (
        <AnimatePresence>
            {selectedField && (
                <motion.div
                    key="question-settings"
                    className="flex absolute right-4 top-7 gap-1"
                    //{...questionAnimation}
                >
                    {renderSettings(fieldTypeSettings)}
                </motion.div>
            )}
        </AnimatePresence>
    );
};
