import React, { useState, useEffect } from "react";
import { message } from "antd";
import ContentTab from "../Components/MyEditor/ContentTab";
import { useParams } from "react-router-dom";
import { useFormEditorContext } from "../Contexts/FormEditorContext.jsx";
import { QuestionProvider } from "../Contexts/QuestionContext.jsx";
import FormSettingsModal from "../Components/FormEditor/FormSettingsModal.tsx";
import FormSettingsSider from "../Components/MyEditor/FormSettingsSider.jsx";
import ShareTab from "../Components/MyEditor/ShareTab.jsx";
import { convertBackToOldFormat } from "../Components/MyEditor/FormObjectConfig.jsx";
import DesignTab from "../Components/MyEditor/DesignTab.jsx";
import CommonLayout from "./CommonLayout.jsx";
import ContentMainSection from "../Components/MyEditor/ContentMainSection.jsx";
import {
    Tabs,
    TabsList,
    TabsTrigger,
    TabsContent,
} from "../Components/ui/tabs.tsx";
import { ScrollArea } from "../Components/ui/scroll-area.tsx";
import { useQuery } from "@tanstack/react-query";
import { useApi } from "../Api/useApi.ts";
import DefalutLoading from "../Components/DashboardComponents/DefaultLoading.jsx";
import { PricingModal } from "../Components/PricingModal.tsx";

const EditorLayout = () => {
    const api = useApi();
    const [selectedTab, setSelectedTab] = useState("editor");
    const [messageApi, contextHolder] = message.useMessage();
    const {
        form,
        setForm,
        selectedField,
        fields,
        setFormStatus,
        showPricingModal,
        setShowPricingModal,
    } = useFormEditorContext();
    /* Get form from the server  */
    const { id } = useParams();
    const { workspaceId } = useParams();
    const {
        data: formData,
        isFetching,
        error,
    } = useQuery({
        queryKey: ["form", id],
        queryFn: () => api.revisions.getOneRevision(id, workspaceId),

        staleTime: Infinity,
        enabled: !!id, // Only run query if id exists
        retry: 2,
    });

    // Move data transformation to useEffect
    useEffect(() => {
        if (formData?.status === 200 && formData?.data) {
            try {
                const convertedData = convertBackToOldFormat(
                    formData?.data?.form,
                    formData?.data?.theme,
                );
                setForm(convertedData);
                const formStatus = sessionStorage.getItem("formStatus");
                setFormStatus(formStatus);
            } catch (err) {
                console.error("Error converting form data:", err);
            }
        }
    }, [formData]);

    if (isFetching || !form) {
        return <DefalutLoading loadingToken={isFetching} />;
    }
    const index = fields.findIndex((field) => field?.id === selectedField?.id);

    const editorSidebar = (
        <>
            <div className="relative w-[320px] h-full flex flex-col px-[15px] overflow-y-hidden no-scrollbar">
                <Tabs defaultValue="editor" className="w-full">
                    <TabsList className="grid grid-cols-3  bg-[#F7F8F9] h-9 p-0 px-1 ">
                        <TabsTrigger
                            value="editor"
                            className=" h-7 rounded-md  hover:text-black text-xs data-[state=active]:font-semibold"
                            onClick={() => {
                                setSelectedTab("editor");
                            }}
                        >
                            Content
                        </TabsTrigger>
                        <TabsTrigger
                            value="design"
                            className=" h-7 rounded-md  hover:text-black text-xs data-[state=active]:font-semibold"
                            onClick={() => {
                                setSelectedTab("design");
                            }}
                        >
                            Design
                        </TabsTrigger>
                        <TabsTrigger
                            value="share"
                            onClick={() => {
                                setSelectedTab("share");
                            }}
                            className=" h-7 rounded-md  hover:text-black text-xs data-[state=active]:font-semibold"
                        >
                            Share
                        </TabsTrigger>
                    </TabsList>

                    <ScrollArea className="overflow-y-auto w-full">
                        <TabsContent value="editor">
                            <ContentTab />
                        </TabsContent>
                        <TabsContent value="design">
                            <DesignTab />
                        </TabsContent>
                        <TabsContent value="share">
                            <ShareTab />
                        </TabsContent>
                    </ScrollArea>
                </Tabs>

                <div className="flex justify-start mt-auto mb-4 w-full">
                    <FormSettingsModal />
                </div>
            </div>
        </>
    );

    return (
        <>
            <PricingModal
                isOpen={showPricingModal}
                onClose={() => setShowPricingModal(false)}
            />
            <QuestionProvider question={index ? index : -1}>
                {contextHolder}
                <CommonLayout editor={true} sidebar={editorSidebar}>
                    {/* main section */}
                    <div
                        className={`flex overflow-x-hidden overflow-y-hidden relative justify-center h-full border transition duration-200 rounded-[12px] border-zinc-200"
            }`}
                    >
                        <div className="w-full h-full">
                            {(selectedTab === "editor" ||
                                selectedTab === "design" ||
                                selectedTab === "share") && (
                                <ContentMainSection />
                            )}
                        </div>
                    </div>
                </CommonLayout>
            </QuestionProvider>
        </>
    );
};

export default EditorLayout;
